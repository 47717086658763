<div class="payment-loaded" *ngIf="loaded">

<div class="new-payment-form" *ngIf="view === 'New'">

  <div ngxStripeCardGroup
    [elementsOptions]="elementsOptions"
  >

    <div class="row">

      <div class="col-sm-12">

        <div class="form-group">
          <label>Card Number</label>
          <ngx-stripe-card-number (change)="onChange($event, 'card-number')" containerClass="form-control" [options]="cardOptions"></ngx-stripe-card-number>
        </div>

      </div>

    </div>

    <div class="row">

      <div class="col-sm-6">

        <div class="form-group">
          <label>Expiry Date</label>
          <ngx-stripe-card-expiry (change)="onChange($event, 'card-expiry')" containerClass="form-control" [options]="cardOptions"></ngx-stripe-card-expiry>
        </div>

      </div>

      <div class="col-sm-6">

        <div class="form-group">
          <label>CVV Number</label>
          <ngx-stripe-card-cvc (change)="onChange($event, 'card-cvc')" containerClass="form-control" [options]="cardOptions"></ngx-stripe-card-cvc>
        </div>

      </div>

    </div>

    <div class="row">

      <div class="col-12">

        <div class="info-box mb-3">

          <i class="fa fa-info-circle"></i>

          <p>For gift card purchases, we don't store card details. They are used for a one time purchase using bank grade encryption.</p>

        </div>

      </div>

    </div>

  </div>

</div>

  <div class="row">

    <div class="col-12">
      <button class="btn btn-primary btn-block" *ngIf="!checkValid()" (click)="tryPayment()">Pay By Card {{cart.totalOrder() | currency: 'GBP'}}</button>
      <button class="btn btn-dark btn-block" disabled *ngIf="checkValid()" (click)="tryPayment()">Complete the required fields</button>
    </div>

  </div>


</div>
