<section class="section-padding contact-top bg-light-theme">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-sm-6">
                <div class="contact-info-box mb-md-40">
                    <i class="fas fa-map-marker-alt"></i>
                    <h6 class="text-theme fw-600">Postal Headquarters, La Rue Grellier, La Rue Des Pres Trading Estate, St Saviour, Jersey JE2 7QS</h6>
                </div>
            </div>
            <div class="col-lg-6 col-sm-6">
                <div class="contact-info-box">
                    <i class="fas fa-envelope"></i>
                    <h6 class="text-theme fw-600"><a href="mailto:info@fetch.je" class="text-theme">info@fetch.je</a><br> online support</h6>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section-padding contact-form">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-header-left title">
                    <h3 class="text-light-black header-title">Leave us a message</h3>
                </div>
                <form (submit)="sendEmail(contactForm.value)" [formGroup]="contactForm">
                    <div class="row clearfix">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" formControlName="name" class="form-control form-control-submit" placeholder="Name" required>
                            </div>
                            <div class="form-group">
                                <input type="email" formControlName="email" class="form-control form-control-submit" placeholder="Email" required>
                            </div>
                            <div class="form-group">
                                <input type="text" formControlName="phone" class="form-control form-control-submit" placeholder="Phone No." required>
                            </div>
                            <div class="form-group">
                                <input type="text" formControlName="subject" class="form-control form-control-submit" placeholder="Subject" required>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <textarea formControlName="cntnt" class="form-control form-control-submit" placeholder="Message" required></textarea>
                            </div>
                        </div>
                        <div class="col-12">
                            <button type="submit" class="btn-second btn-submit">Submit</button>
                        </div>
                      <div class="col-12">

                        <h4 *ngIf="message">{{message}}</h4>

                      </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</section>
