<div class="inner-wrapper">
    <div class="container-fluid no-padding">
      <div class="row no-gutters overflow-auto">
        <div class="col-md-6">
          <div class="main-banner dogBg">
          </div>
        </div>
        <div class="col-md-6">
          <div class="section-2 user-page main-padding">
            <div class="login-sec">
              <div class="login-box">
                <form [formGroup]="loginForm" (submit)="submitForm()">
                  <h4 class="text-light-black fw-600">Sign in to fetch</h4>
                  <div class="row">
                    <div class="col-12">
                      <p class="text-light-black">Have a merchant account? <a href="https://merchant.fetch.je">Click here</a>
                      </p>
                      <div class="form-group">
                        <label class="text-light-white fs-14">Email</label>
                        <input formControlName="email" type="email" class="form-control form-control-submit" placeholder="Email Address" required>
                      </div>
                      <div class="form-group">
                        <label class="text-light-white fs-14">Password</label>
                        <input formControlName="password" type="password" #passwordField class="form-control form-control-submit" value="password" placeholder="Password" required>
                        <div (click)="togglePasswordView()" class="fa fa-fw fa-eye field-icon toggle-password"></div>
                      </div>
                      <div class="form-group checkbox-reset">
                        <label class="custom-checkbox mb-0">
                          <input type="checkbox" name="#"> <span class="checkmark"></span> Keep me signed in</label> <a routerLink="/forgot-password">Forgot Your Password?</a>
                      </div>
                      <div class="form-group" *ngIf="error">

                        <label class="text-danger">Error: {{error}}</label>

                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn-second btn-submit full-width">
                         Sign in</button>
                      </div>
                      <!--<div class="form-group text-center"> <span>or</span>
                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn-second btn-facebook full-width" (click)="loginWithFacebook()">
                          <img src="assets/img/facebook-logo.svg" alt="btn logo">Continue with Facebook</button>
                      </div>-->
                      <div class="form-group text-center mb-0"> <a (click)="goToRegister()">Create your account</a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
