import { Component, Input, OnInit } from '@angular/core';
import { CartVendor } from '../../models/CartVendor';
import { Cart } from '../../models/Cart';
import { CartService } from '../../services/Cart.service';
import { SearchService } from '../../services/Search.service';
import { ProductOption } from '../../models/ProductOption';
import { OpeningTime } from '../../models/OpeningTime';
import * as moment from 'moment';
import { Product } from 'src/app/models/Product';
import { CartVendorItem } from 'src/app/models/CartVendorItem';
import { DiscountCode } from 'src/app/models/DiscountCode';
import { Router } from '@angular/router';
import { NewSearchService } from 'src/app/services/NewSearch.service';

@Component({
  selector: 'basket-vendor',
  templateUrl: './basket-vendor.component.html',
  styleUrls: ['./basket-vendor.component.scss']
})
export class BasketVendorComponent implements OnInit {

  @Input() basketVendor: CartVendor;
  loading = false;
  showDiscountInput = false;
  discountCode = '';
  discountCodeError = '';
  showModal = false;
  showGiftModal = false;
  showNoteInput = false;
  note = '';

  constructor(public cartService: CartService, public search: NewSearchService, public router: Router) { }

  ngOnInit(): void {
    this.note = this.basketVendor.note;
    if (this.note) {
      this.showNoteInput = true;
    }
  }

  productImage(product: Product) {
    if (product.main_image) {
      return product.main_image[0].url;
    }
    else {
      return 'assets/img/placeholder.png';
    }
  }

  productOptions(options: ProductOption[]) {
    let optionString = '';
    let index = 0;
    if (options) {
      for (const option of options) {
        if (option.type) {
          for (const optionValue of option.options) {
            if (optionValue.selected) {
              if (index) {
                optionString += ', ' + optionValue.title;
                index++;
              } else {
                optionString += optionValue.title;
                index++;
              }
            }
          }
        }
      }
    }
    return optionString;
  }

  minSpend(item) {
    if (item.fulfillment_method == 'Collection') {
      return 0;
    }
    else if (this.cartService.calculateVendorTotal(this.basketVendor) >= this.basketVendor.vendor.minimum_spend) {
      return 0;
    }
    else {
      return this.basketVendor.vendor.minimum_spend - this.cartService.calculateVendorTotal(this.basketVendor);
    }
  }

  availableQuantity(basketVendorItem) {
    if (basketVendorItem.product.track_inventory) {
      if (basketVendorItem.product.product_options_meta == null || basketVendorItem.product.product_options_meta.length == 0) {
        if (basketVendorItem.product.stock > 25) {
          return Array.from({ length: 25 }, (_, i) => i + 1)
        }
        else {
          return Array.from({ length: basketVendorItem.product.stock }, (_, i) => i + 1);
        }
      }
      else {
        // determine based on selected option stock
        var selected = false;
        var min = 1;
        var max = 0;
        basketVendorItem.options.forEach(optionGroup => {
          optionGroup.options.forEach(option => {
            if (option.selected) {
              selected = true;
              if (option.stock > max) {
                max = option.stock;
              }
              if (basketVendorItem.quantity > option.stock) {
                basketVendorItem.quantity = option.stock;
              }
            }
            if (min == 1) {
              min = option.stock;
            }
            else if (min > option.stock) {
              min = option.stock;
            }
          });
        });
        //basketVendorItem.quantity = 1
        if (selected) {
          return Array.from({ length: max }, (_, i) => i + 1)
        }
        else {
          return Array.from({ length: min }, (_, i) => i + 1)
        }
      }
    }
    else {
      return Array.from({ length: 25 }, (_, i) => i + 1)
    }
  }

  removeItem(item: CartVendorItem) {
    const itemIndex = this.basketVendor.items.indexOf(item);
    this.basketVendor.items.splice(itemIndex, 1);
    if (this.basketVendor.items.length == 0) {
      const vendorIndex = this.cartService.cart.vendors.indexOf(this.basketVendor);
      this.cartService.cart.vendors.splice(vendorIndex, 1);
    }
    this.cartService.removeFromCart(item).then((res: boolean) => {
      // moved out splice for snappier interface
    }).catch((err) => {
      // do something
    });
  }

  updateNote() {
    this.basketVendor.note = this.note;
    this.cartService.updateVendorNote(this.basketVendor);
  }

  addDiscount() {
    if (this.discountCode) {
      this.discountCodeError = '';
      this.cartService.submitDiscountCode(this.basketVendor, this.discountCode).then((res: DiscountCode) => {
        this.basketVendor.discount_id = res.id;
        this.basketVendor.discount = res;
        this.discountCode = '';
        this.showDiscountInput = false;
      }).catch((err) => {
        this.discountCode = '';
        this.discountCodeError = err.error.Error;
      });
    }
  }

  removeDiscount() {
    if (this.basketVendor.discount_id) {
      this.cartService.removeDiscountCode(this.basketVendor).then((res: boolean) => {
        this.basketVendor.discount_id = null;
        this.basketVendor.discount = null;
        this.showDiscountInput = false;
      }).catch((err) => {
      });

    }
  }

  vendorDiscount() {
    if (this.basketVendor.discount.discount_type == 'Delivery Only') {
      return 'FREE DELIVERY';
    }
    else {
      return '- £' + Number(Math.round(parseFloat(this.cartService.calculateVendorDiscount(this.basketVendor) + 'e' + 2)) + 'e-' + 2).toFixed(2);
    }
  }

  itemChanged(basketItem: CartVendorItem) {
    this.cartService.updateCartItem(basketItem).then((res: boolean) => {
    }).catch((err) => {
      // do something
    });
  }

  goToVendor() {
    const slug = this.search.convertToSlug(this.basketVendor.vendor.trading_name);
    this.router.navigate(['stores', slug, this.basketVendor.vendor.id]);
  }

}