import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './Auth.service';
import { Cart } from '../models/Cart';
import { Vendor } from '../models/Vendor';
import { ApiService } from './Api.service';
import { NewCartCriteria } from '../models/NewCartCriteria';
import { OpeningTime } from '../models/OpeningTime';
import { TimeExclusion } from '../models/TimeExclusion';
import * as moment from 'moment';
import { Product } from '../models/Product';
import { CartVendor } from '../models/CartVendor';
import { CartVendorItem } from '../models/CartVendorItem';
import { CustomerAddress } from '../models/CustomerAddress';
import { ProductOption } from '../models/ProductOption';
import { CartVoucher } from '../models/CartVoucher';

// @ts-ignore
@Injectable({
  providedIn: 'root',
})

// @ts-ignore
export class CartService {

  apikey: string;
  httpOptions: any;
  uploadOptions: any;

  loaded = false;
  cartId;
  cart: Cart;


  hasCart = false;
  vendorPreferences;
  vendor: Vendor;

  constructor(
    public router: Router,
    public auth: AuthService,
    public api: ApiService,
    public http: HttpClient
  ) {
    this.auth.tokenObservable().subscribe(data => {
      this.apikey = data;

      this.httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + this.apikey,
        }),
      };

      this.uploadOptions = {
        headers: new HttpHeaders({
          Authorization: 'Bearer ' + this.apikey,
        }),
      };

    });

    this.load();

  }

  load(): any {
    const checkCart = sessionStorage.getItem('fetch-cart');
    if (checkCart) {
      this.cartId = checkCart;
      this.getCart().then((res) => {
        this.cart = Object.assign(new Cart(), res.Cart);
        this.hasCart = true;
        this.loaded = true
      }).catch((err) => {
        this.hasCart = false;
        this.loaded = true
        sessionStorage.removeItem('fetch-cart');
      });
    } else {
      this.loaded = true
      this.hasCart = false;
    }
  }

  getCart(): any {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiURL + 'api/public/cart/' + this.cartId, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  addToCart(item, vendor: Vendor, newCartCriteria: NewCartCriteria): any {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'api/public/cart', {
          cartId: this.cartId,
          criteria: newCartCriteria,
          item: item,
          vendor: vendor
        }, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  updateCartItem(basketVendorItem: CartVendorItem): any {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'api/public/cart/' + this.cartId + '/' + basketVendorItem.id, {
          cartId: this.cartId,
          item: basketVendorItem,
        }, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  submitDiscountCode(basketVendor: CartVendor, code: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'api/public/cart/' + this.cartId + '/discount', {
          cartId: this.cartId,
          vendorId: basketVendor.vendor_id,
          code: code
        }, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  removeDiscountCode(basketVendor: CartVendor) {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'api/public/cart/' + this.cartId + '/remove-discount', {
          cartId: this.cartId,
          vendorId: basketVendor.vendor_id,
        }, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  removeFromCart(basketVendorItem: CartVendorItem) {
    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiURL + 'api/public/cart/' + this.cartId + '/' + basketVendorItem.id + '/remove', this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  submitVoucherCode(code: string) {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'api/public/cart/' + this.cartId + '/voucher', {
          cartId: this.cartId,
          code: code
        }, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  updateCartAddress(address: CustomerAddress): any {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'api/public/cart/' + this.cartId + '/address', {
          cartId: this.cartId,
          addressId: address.id,
        }, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  updateGiftItemAddress(basketVendorItemId, parish, addressId): any {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'api/public/cart/' + this.cartId + '/' + basketVendorItemId + '/address', {
          cartId: this.cartId,
          parish: parish,
          addressId: addressId
        }, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  updateVendorNote(basketVendor: CartVendor): any {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'api/public/cart/' + this.cartId + '/vendor-note', {
          cartId: this.cartId,
          vendorId: basketVendor.id,
          note: basketVendor.note
        }, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  round(num: number, fractionDigits: number): number {
    return Number(num.toFixed(fractionDigits));
}

  calculateItemOptionsCost(basketVendorItem: CartVendorItem) {
    let total = 0.0;
    if (basketVendorItem.options && basketVendorItem.options != null && basketVendorItem.options.length > 0) {
      for (let optionGroup of basketVendorItem.options) {
        for (let option of optionGroup.options) {
          if (option.selected) {
            total += option.additional_cost;
          }
        }
      }
    }
    return Number(total);
  }

  calculateItemCost(basketVendorItem: CartVendorItem) {
    let total = 0.0;
    if (basketVendorItem.product.discount_price) {
      total += basketVendorItem.quantity * (Number(basketVendorItem.product.discount_price) + this.calculateItemOptionsCost(basketVendorItem)); 
    } else {
      total += basketVendorItem.quantity * (Number(basketVendorItem.product.base_price) + this.calculateItemOptionsCost(basketVendorItem));
    }
    return Number(total);
  }

  calculateItemDelivery(basketVendor: CartVendor, basketVendorItem: CartVendorItem) {
    let total = 0.0;
    if (basketVendorItem.fulfillment_method == 'Delivery') {
      total = basketVendor.vendor.delivery_charge;
      for (const chargeItem of basketVendor.vendor.delivery_rates) {  // overwrite for a given location
        if (basketVendorItem.delivery_location === chargeItem.area) {
          total = chargeItem.delivery_charge;
        }
      }

      // does the vendor use jp delivery?
      if (basketVendor.vendor.is_jp_delivery_customer) {
        total = Number(basketVendor.vendor.jp_delivery_cost);
      }

      // check for free delivery
      if (basketVendor.vendor.free_delivery_on) {
        if (this.calculateVendorTotal(basketVendor) >= basketVendor.vendor.free_delivery_threshold) {
          total = 0.0;
        }
      }

      // if another item is going to same address and not a gift then don't charge
      let charged = false;
      if (basketVendorItem.fulfillment_method == 'Delivery') {
        for (let item of basketVendor.items) {
          //console.log(item.id + ': item delivery location: ' + item.delivery_location + ' and date: ' + item.scheduled_date)
          if (item.fulfillment_method == 'Delivery') {
            if (item.id == basketVendorItem.id) {
              if (charged && !item.gift) {
                total = 0.0;
              }
              else {
                charged = true;
              }
            }
            else {
              if (item.delivery_location == basketVendorItem.delivery_location && item.scheduled_date == basketVendorItem.scheduled_date) {
                charged = true;
              }
            }
          }
        }
      }
    }
    return Number(total);
  }

  calculateVendorTotal(basketVendor: CartVendor) {
    let total = 0.0;
    for (let basketVendorItem of basketVendor.items) {
      total += this.calculateItemCost(basketVendorItem);
    }
    return Number(Number(total).toFixed(2));
  }

  calculateVendorDelivery(basketVendor: CartVendor) {
    let total = 0.0;
    for (let basketVendorItem of basketVendor.items) {
      total += this.calculateItemDelivery(basketVendor, basketVendorItem);
    }
    return Number(Number(total).toFixed(2));
  }

  calculateVendorDiscount(basketVendor: CartVendor) {
    let total = 0.0;
    if (basketVendor.discount) {
      if (basketVendor.discount.discount_type == 'Fixed') {
        total = basketVendor.discount.discount_amount;
      }
      else if (basketVendor.discount.discount_type == 'Percentage') {
        total = this.calculateVendorTotal(basketVendor) / 100 * basketVendor.discount.discount_amount;
      }
    }
    return Number(Number(total).toFixed(2));
  }

  calculateVoucherDeduction(voucher: CartVoucher) {
    let total = 0.0;
    if (this.cart) {
      let cartTotal = this.calculateBasketTotal();
      let balance = 0.0;
      for (let basketVoucher of this.cart.vouchers) {
        if (voucher.id == basketVoucher.id) {
          if (balance >= cartTotal) {
            return Number(0.0);  // already coverd
          }
          else {
            if (Number(basketVoucher.voucher.remaining_balance) + Number(balance) >= cartTotal) {
              let diff = cartTotal - Number(balance);
              balance += diff;
              return Number(diff);  // remaining balance covered
            }
            else {
              balance += basketVoucher.voucher.remaining_balance;
              return Number(basketVoucher.voucher.remaining_balance); // partially cover
            }
          }
        }
        else {
          if (balance >= cartTotal) {
            // already covered
          }
          else {
            if (Number(basketVoucher.voucher.remaining_balance) + Number(balance) >= cartTotal) {
              let diff = cartTotal - Number(balance);
              balance += Number(diff);
            }
            else {
              balance += Number(basketVoucher.voucher.remaining_balance);
            }
          }
        }
      }
    }

    return total;
  }

  calculateBasketDiscounts() {
    let total = 0.0;
    if (this.cart) {
      for (let basketVendor of this.cart.vendors) {
        total += this.calculateVendorDiscount(basketVendor);
      }
    }
    return Number(Number(total).toFixed(2));
  }

  calculateBasketDelivery() {
    let total = 0.0;
    if (this.cart) {
      for (let basketVendor of this.cart.vendors) {
        total += this.calculateVendorDelivery(basketVendor);
      }
    }
    return Number(Number(total).toFixed(2));
  }

  calculateBasketTotal() {
    let total = 0.0;
    if (this.cart) {
      for (const basketVendor of this.cart.vendors) {
        total += this.calculateVendorTotal(basketVendor);
      }
      total -= this.calculateBasketDiscounts();
      total += this.calculateBasketDelivery();
    }
    return Number(Number(total).toFixed(2));
  }

  calculateBasketTotalAfterVouchers() {
    let total = 0.0;
    total += this.calculateBasketTotal();
    for (let voucher of this.cart.vouchers) {
      total -= this.calculateVoucherDeduction(voucher);
    }
    return Number(total);
  }

  giftItems(addresses: CustomerAddress[]): CartVendorItem[] {
    let items = []
    if (this.cart) {
      for (let basketVendor of this.cart.vendors) {
        for (let basketVendorItem of basketVendor.items) {
          if (basketVendorItem.gift) {
            basketVendorItem.vendor = basketVendor
            if (!basketVendorItem.address_id) {
              basketVendorItem.address = addresses[0];
            }
            else {
              basketVendorItem.address = addresses.find(a => a.id == basketVendorItem.address_id);
            }
            items.push(basketVendorItem);
          }
        }
      }
      return items;
    }
  }

  hasDelivery() {
    for (let basketVendor of this.cart.vendors) {
      for (let basketVendorItem of basketVendor.items) {
        if (basketVendorItem.fulfillment_method == 'Delivery' && !basketVendorItem.gift) {
          return true;
        }
      }
    }
    return false;
  }

  collectionOnly() {
    if (this.cart) {
      for (let basketVendor of this.cart.vendors) {
        for (let basketVendorItem of basketVendor.items) {
          if (basketVendorItem.fulfillment_method == 'Delivery') {
            return false;
          }
        }
      }
    }
    return true;
  }

  itemsInBasket() {
    let total = 0;
    if (this.cart) {
      for (let basketVendor of this.cart.vendors) {
        total += basketVendor.items.length;
      }
    }
    return total;
  }






  // TODO free delivery etc
  calculateDeliveryCharge() {
    let charge = 0.0;
    for (let vendor of this.cart.vendors) {
      for (let item of vendor.items) {
        if (item.fulfillment_method == 'Delivery') {
          let vendorCharge = vendor.vendor.delivery_charge;
          for (const chargeItem of vendor.vendor.delivery_rates) {
            if (item.delivery_location === chargeItem.area) {
              vendorCharge = chargeItem.delivery_charge;
            }
          }
          charge += vendorCharge;
        }
      }
    }
    return charge;
  }

  vendorTotal(vendor) {
    let total = 0.0;
    for (let item of vendor.items) {
      total += parseFloat(item.total_cost);  // = parseFloat(String(item.total_cost));
    }
    return total;
  }

  orderTotal() {
    let total = 0.0;
    for (const vendor of this.cart.vendors) {
      total += this.vendorTotal(vendor)
    }
    return total;
  }

  public calculateCheckoutTotal() {

    // TODO handle this
    // if (this.cart.fulfillment_method === 'Collection') {
    //   return this.totalOrder();
    // }

    if (this.orderTotal() >= this.vendorPreferences.free_delivery_threshold) {
      return this.orderTotal();
    }

    return this.orderTotal() + parseFloat(String(this.vendorPreferences.delivery_charge));

  }

  arrangeItemsForTagManager() {

    const items = [];

    let x = 1;
    for (const vendor of this.cart.vendors) {
      for (const item of vendor.items) {
        items.push({
          id: item.product_id,
          name: item.product.product_name,
          brand: vendor.vendor.trading_name,
          list_position: x,
          quantity: item.quantity,
          price: item.cost_per_item
        });
        x++;
      }
    }

    return items;
  }

  getAvailabilityForProduct(productID): any {

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiURL + 'api/public/availability/' + productID, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  getCartCheckout(): any {

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiURL + 'api/public/cart/' + this.cartId + '/checkout', this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  splitTimeSpecificDate(startTime, endTime, interval, today, blockedTimes: TimeExclusion[], date, product: Product) {

    // OK LETS FIND EVERY OPENING HOUR FOR THIS DAY

    let now = moment();

    if (today) {
      if (product.available_type === 'schedule') {
        if (product.schedule_type === 'minutes') {
          // HANDLE MINUTES
          now = now.add(parseInt(product.schedule_amount, 0), 'm');
        }

        if (product.schedule_type === 'hours') {
          // HANDLE HOURS
          now = now.add(parseInt(product.schedule_amount, 0), 'h');
        }

      }
    }

    let result = [];

    if (today && startTime.isAfter(now)) {
      result = [startTime.toString()];
    }

    let time = startTime.add(interval, 'm');

    const selectedDate = date.toDateString();

    const blockedTimeForSelectedDate = [];

    for (const blockedTime of blockedTimes) {
      const parsedDate = new Date(blockedTime.date).toDateString();
      if (parsedDate === selectedDate) {
        blockedTimeForSelectedDate.push(blockedTime);
      }
    }

    // SORT ALL THE BLOCKED DATES

    while (time.isBetween(startTime, endTime, undefined, [])) {
      if (this.checkIfTimeFallsWithinBlockedHours(blockedTimeForSelectedDate, time.toString(), today, time, now)) {
        result.push(time.toString());
      }
      time = time.add(interval, 'm');
    }


    return result;

  }

  checkIfTimeFallsWithinBlockedHours(blockedHours: TimeExclusion[], dateToCheck, today, todayTime, now): boolean {

    let isAllowed = true;

    if (today && todayTime.isBefore(now)) {
      isAllowed = false;
    }

    const dateToCheckParsed = moment(new Date(dateToCheck).toTimeString(), 'HH:mm:ss');

    for (const blockedHour of blockedHours) {


      const fromDate = moment(blockedHour.from, 'HH:mm:ss');
      const toDate = moment(blockedHour.to, 'HH:mm:ss');
      if (dateToCheckParsed.isAfter(fromDate) && dateToCheckParsed.isBefore(toDate)) {
        isAllowed = false;
      }

    }

    return isAllowed;

  }



  editCartPreferences(cart): any {

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'api/public/cart/' + this.cartId + '/edit', cart, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  changeQuantity(item, quantity) {

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiURL + 'api/public/cart/' + this.cartId, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  removeItemFromCart(item): any {

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiURL + 'api/public/cart/' + this.cartId + '/' + item.id + '/remove', this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  getAvailableSlots(vendor, when): any {

    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'api/public/stores/' + vendor + '/availableSlots', { day: when }, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  emptyCart() {

    return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiURL + 'api/public/cart/' + this.cartId, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  checkCartSameVendor(cart: Cart) {



  }

  willDeliver(exclusions: any, area: string) {

    if (!exclusions.length) {
      return true;
    } else {
      const search = exclusions.findIndex((areaToCheck) => areaToCheck.area === area);
      return search < 0;
    }

  }

  checkIfDateInOpeningHours(openingHours: OpeningTime[], date: Date): boolean {

    const dayOfWeekToCheck = date.getDay();
    const isOpen = openingHours.findIndex(openingHour => openingHour.dayOfWeekNumber === dayOfWeekToCheck);
    return isOpen > -1;

  }

  checkIfDateInBlocked(blockedHours: TimeExclusion[], date: Date): boolean {

    const isOpen = blockedHours.findIndex(timeExclusion => {
      const dateTest = new Date(timeExclusion.date).toDateString();
      const currentDateString = date.toDateString();
      if (dateTest === currentDateString && timeExclusion.all_day) {
        return true;
      }
    });

    return isOpen <= -1;

  }

}
