import { CartVendor } from './CartVendor';
import { CustomerAddress } from './CustomerAddress';
import { Product } from './Product';
import { ProductOption } from './ProductOption';

export class CartVendorItem {

  id: number;
  basket_vendor_id: number;
  product_id: number;
  product: Product;
  quantity: number;
  cost_per_item: number;
  total_cost: number;
  base_cost: number;
  options?: ProductOption[];
  stop_sale_expiry: Date;
  delivery_location: string;
  fulfillment_method: string;
  when: string;
  is_asap: boolean;
  created_at: Date;
  updated_at: Date;
  scheduled_date: Date;
  scheduled_time: Date;
  gift: Boolean;
  gift_note:string;
  address_id?: number;
  
  vendor: CartVendor;
  address: CustomerAddress;
  
  constructor() {
    this.quantity = 1;
  }
  
}