import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, CanActivateChild} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './Auth.service';
import * as moment from 'moment';
import {NewSearchService} from './NewSearch.service';

@Injectable({
  providedIn: 'root',
})

export class MenuCheck implements CanActivateChild {
  constructor(
    private search: NewSearchService,
    private router: Router,
    public route: ActivatedRoute) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {

    return new Promise(((resolve, reject) => {

        if (this.search.facetsLoaded){
          resolve(true);
        }else{

          if (!this.search.facetsLoading){
            this.search.facetsLoading = true;
            this.search.loadFacets().then((data) => {
              this.search.categorySections = data;
              this.search.facetsLoaded = true;
              this.search.facetsLoading = false;
              console.log(this.search.categorySections);
              resolve(true);
            });
          }

        }

    }));

  }

}
