<div class="checkout-body bg-light">


  <div class="tracker-main">

    <div class="container">

      <div class="row mt-md-5">

        <div class="col-sm-12">

          <div class="d-flex mb-5 flex-wrap">
            <div class="icon">
              <img src="assets/img/correct.svg" alt="">
            </div>
            <div class="text">
              <h2 class="mb-3">Voucher Order Confirmed</h2>
              <h6 class="mb-3">Thank you for your purchase.</h6>
              <p>Your voucher(s) are being generated now. If purchasing for a business, you will receive a spreadsheet with the vouchers listed. If buying for yourself or someone else, the recipients will have the vouchers delivered by e-mail. If you think you have not received your email confirmation, please check your spam/junk folder or please contact our friendly customer service team on info@fetch.je.</p>
            </div>
          </div>

        </div>

      </div>

    </div>

  </div>

</div>
