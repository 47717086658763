
    <!-- slider -->
    <!-- about us -->
    <section class="aboutus section-padding">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="history-title mb-md-40">
                        <h2 class="text-light-black"> <span class="text-light-green">Our Story</span></h2>
                      <p>In March of 2020, when Covid-19 was gripping the world, we realised our wonderful Island of Jersey was going to be under threat.</p>
                      <p>We knew we would need to rise to meet these challenges, because this is what our Island has always done. Jersey has a well-established pedigree of adapting in order to not just survive but to flourish. Whether it be the wool trade, cider production, shipbuilding, agriculture, tourism, conferences and events, or financial services and digital innovation, we have always stepped up and protected our economy.</p>
                      <p>We foresaw that our community was going to be significantly impacted by:</p>
                      <p>Few local businesses having appropriate business continuity plans;</p>
                      <p>The Government of Jersey having to, rightly, focus on preserving life and the healthcare system; and</p>
                      <p>Aggressive changes to the core functions of supply and demand.</p>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row align-items-center">
                            <div class="histry-img mb-xs-20">
                                <img src="assets/img/max1-about.jpg" class="img-fluid full-width" alt="Histry">
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="aboutus section-padding">
        <div class="container">
            <div class="row align-items-center">

                <div class="col-lg-6">
                  <img src="assets/img/mockup.png" alt="Our mobile app">
                </div>
              <div class="col-lg-6 mt-5">
                <div class="history-title mb-md-40">
                  <h2 class="text-light-black"> <span class="text-light-green">Enter Fetch.je</span></h2>
                  <p>Focused on supporting local businesses, Fetch was designed as a community project to safeguard livelihoods.&nbsp;It has offered and continues to provide, isolating customers a one-stop-shop to order everything they need safely and securely. What's more, it has provided businesses with an instant online presence, so they could continue trading while their physical premises were and, in some cases, still are closed. In short, it has given the non-finance sectors of Jersey's economy a chance not just to survive but to thrive through the immensely challenging times we have faced over the last year.</p>

                  <p>The impact of COVID-19 on local communities and the economy has been immediate and profound, with social distancing measures leading to a significant drop in business trade - particularly businesses such as restaurants, grocery stores, cafes, and more that relied on face-to-face interaction with customers. Fetch is providing local businesses with an online eCommerce platform where they can showcase their products to allow their customers to order various goods and services.</p>

                </div>
              </div>
            </div>

        </div>
    </section>

    <section class="aboutus section-padding">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="history-title mb-md-40">
                        <h2 class="text-light-black"> <span class="text-light-green">Here To Stay</span></h2>
                      <p>Throughout lock-down, we focused on on-boarding the essential businesses that support the well-being and livelihoods of Jersey residents, such as food suppliers, restaurants, farmers, fishermen, and much-needed household goods &amp; pharmacies. Now that Fetch is part of the Jersey Post Group, we are delighted to say the platform is here to stay, and we are ready to focus on so much more.</p>
                      <p>We are also enabling individual fishermen and farm shops to sell their unique, local produce through our site. It means customers can buy a lobster, lettuce, and a latte in one place.</p>
                      <p>Fetch is a marriage of two advantages: the feel-good factor of supporting local with the convenience of shopping online; in short, it's a win-win situation. We aim to keep local businesses in business.</p>
                      <p>Whether you&rsquo;re looking for local produce, delicious restaurant-quality food without leaving your home or simply wanting to keep on top of your wellbeing, Fetch is an encyclopaedia of incredible choice, presenting the rich, diverse and incredible breath of talent within our local business community.&nbsp;We offer a safe, secure and easy-to-use website exclusively for the benefit of Islanders.</p>
                      <p class="mb-5">Thank you for your generous support.</p>
                      <h3 style="font-style: italic">The Fetch Team.</h3>
                    </div>
                </div>

            </div>
        </div>
    </section>
