
    <!-- slider -->
    <!-- about us -->
    <section class="aboutus section-padding">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="history-title mb-md-40">
                        <h2 class="text-light-black mt-5"> <span class="text-light-green">Selling on Fetch</span></h2>

                     <div class="row">

                       <div class="col-lg-6">

                         <p><em>Interested of becoming a Fetch Merchant? Or just want to find out more? You are in the right place.</em></p>
                         <p><strong>What does it cost? </strong></p>
                         <p>Register for FREE by completing 3 quick easy steps, it will take you less than 5 minutes! <a href="https://merchant.fetch.je/signup">Fetch Merchant Centre</a></p>
                         <p>Fetch.je only takes a commission of <strong>8%</strong> only applicable on orders, no other extra fees! <br /> This also means that even in a period of low sales, you can enjoy free brand exposure on the first online marketplace.</p>
                         <p><strong>What&rsquo;s next?</strong></p>
                         <p>After registering on Fetch.je, you will have directly access to your merchant portal and can start creating your page and upload your products.</p>
                         <p>A member of our friendly team will review your page and get in touch with you to approve it within 1 working day.</p>
                         <p>Once your page is live, you can start taking orders and enjoy all benefits of being a Fetch Merchant and grow your business!</p>

                       </div>

                       <div class="col-lg-6">
                         <div class="row align-items-center">
                           <div class="col-lg-6 col-sm-6 col-md-6">
                             <div class="histry-img mb-xs-20">
                               <img src="assets/img/max1-about.jpg" class="img-fluid full-width" alt="Histry">
                             </div>
                           </div>
                         </div>
                       </div>

                     </div>

                      <p><strong>What are the benefits?</strong></p>
                      <ul>
                        <li class="mb-2"><strong>A flat fee of 8%: </strong></li>
                        <li class="mb-2"><strong>The use of our platform</strong> and the ability to manage your page, products, and your orders.</li>
                        <li class="mb-2"><strong>The help and support of our dedicated sales and marketing team</strong>.</li>
                        <li class="mb-2"><strong>Customer service requests</strong> will be managed and answered by our dedicated Fetch team.</li>
                        <li class="mb-2"><strong>A website and merchant platform that is continually being improved.</strong></li>
                        <li class="mb-2"><strong>Be a part of our marketing campaigns</strong> on our social media platforms, in our newsletters and more!</li>
                        <li class="mb-2"><strong>Coming soon! Two delivery options will soon be available</strong>: Collection from Jersey Post of your 5 or more Fetch parcels and next day delivery.</li>
                        <li class="mb-2"><strong>A Fetch leaflet</strong> will be produced quarterly, featuring all Fetch partners, and will be designed, printed, and delivered to all island homes putting your brand in every home across the island.</li>
                        <li class="mb-2"><strong>And much more to come&hellip;</strong></li>
                      </ul>

                      <h4 class="mt-5">Connect to more local customers today. Sign up to Fetch.je.</h4>

                      <a href="https://merchant.fetch.je/signup" class="btn btn-primary">Register Now</a>

                    </div>
                </div>

            </div>
        </div>
    </section>
