import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../../services/Api.service';

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss']
})
export class InstagramComponent implements OnInit {

  posts: any[];

  constructor(public api: ApiService) { }

  ngOnInit(): void {
      this.api.getInstagram().then((res) => {
        this.posts = res;
      });
  }


}



