import { Component, EventEmitter, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../services/Api.service';
import { ActivatedRoute } from '@angular/router';
import { Vendor } from '../../models/Vendor';
import { ProductCategory } from '../../models/ProductCategory';
import { Product } from '../../models/Product';
import { CartVendor } from '../../models/CartVendor';
import { CartVendorItem } from '../../models/CartVendorItem';
import { ProductOption } from '../../models/ProductOption';
import { CartService } from '../../services/Cart.service';
import { SearchService } from '../../services/Search.service';
import { NewCartCriteria } from '../../models/NewCartCriteria';
import { ScrollToConfigOptions, ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { BehaviorSubject, Subscription } from 'rxjs';
import { distinct, distinctUntilChanged } from 'rxjs/operators';
import { NzAnchorLinkComponent } from 'ng-zorro-antd/anchor';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ConfigureOrderComponent } from '../../Components/Modals/configure-order/configure-order.component';
import { ProductAvailabilityMatrix } from '../../models/ProductAvailabilityMatrix';
import SwiperCore, { Navigation } from 'swiper';
import { SwiperComponent } from 'swiper/angular';
import { ProductOptionValue } from '../../models/ProductOptionValue';
import {Title} from "@angular/platform-browser";

SwiperCore.use([Navigation]);

@Component({
  selector: 'app-single-store',
  templateUrl: './single-store.component.html',
  styleUrls: ['./single-store.component.scss']
})
export class SingleStoreComponent implements OnInit, OnDestroy {

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChild('swiperBottom', { static: false }) swiperBottom?: SwiperComponent;

  configValid = false;
  showOffline = false;
  confirmingCart = false;
  showMobileCart = false;
  loaded = false;
  showMoreButton = false;
  showbioMore = false;
  hidingBio = true;
  hiding = true;
  canAdd = false;
  checkingAvailability = true;

  vendor: Vendor;
  categories: ProductCategory[] = [];

  viewingProduct: Product = null;

  activeCategory;
  activeSubscription;
  routeSubscription;
  itemToAdd: CartVendorItem;
  menuOffset = 180;
  cantAddMessage: string;
  inventoryMessage: string;
  swiping: boolean;

  activeClass: BehaviorSubject<NzAnchorLinkComponent> = new BehaviorSubject<NzAnchorLinkComponent>(null);

  newCartConfig: NewCartCriteria = new NewCartCriteria();

  cartConfigModalClose: Subscription;

  currentProductAvailability: ProductAvailabilityMatrix;

  constructor(
    public api: ApiService,
    private scrollToService: ScrollToService,
    public route: ActivatedRoute,
    public cart: CartService,
    public search: SearchService,
    public modal: NzModalService,
    private titleService: Title
    ) {
  }

  @HostListener('window:resize', ['$event']) onResize(event: Event) {

    const window = event.target as Window;

  }

  ngOnInit(): void {

    this.routeSubscription = this.route.params.subscribe((data) => {
      this.getSingleStore();
    });

    this.activeSubscription = this.activeClass.pipe(distinctUntilChanged()).subscribe((data) => {

      if (data) {
        const parent = data.getLinkTitleElement().parentElement.parentElement;
        const offset = data.getLinkTitleElement().offsetLeft;
        if (offset > 10) {
          parent.scrollTo(offset - (40), 0);
        } else {
          parent.scrollTo(offset, 0);
        }
      }

    });

    if (window.innerWidth < 991) {
      this.menuOffset = 70;
    } else {
      this.menuOffset = 110;
    }

  }

  ngOnDestroy() {
    this.activeSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  decodeURI(data) {
    if (typeof data === 'string') {
      return decodeURIComponent(data);
    } else {
      return data;
    }
  }

  determineProductDeliveryText(product: Product) {

    let deliveryOn;
    let collectionOn;

    deliveryOn = this.vendor.delivery_on && !product.delivery_off;
    collectionOn = this.vendor.collection_on && !product.collection_off;

    if (deliveryOn && collectionOn) {
      return 'Delivery & Collection Available';
    }

    if (deliveryOn && !collectionOn) {
      return 'Delivery Available';
    }

    if (!deliveryOn && collectionOn) {
      return 'Collection Available';
    }

  }

  changeBottomSlide(data) {

    this.swiperBottom.swiperRef.slideTo(data.activeIndex);
    this.swiperBottom.swiperRef.update();
  }

  clickSlide(data) {

    this.swiper.swiperRef.activeIndex = data;
    this.swiper.swiperRef.update();

  }

  getSingleStore() {

    const promiseArray = [
      this.api.apiGet('public/stores/' + this.route.snapshot.params.id),
      this.api.apiGet('public/stores/' + this.route.snapshot.params.id + '/products')
    ];

    Promise.all(promiseArray).then((res) => {
      this.vendor = res[0];
      this.categories = res[1];

      this.showOffline = !this.vendor.is_online;

      if (this.categories.length) {
        this.activeCategory = this.categories[0];
      }

      this.loaded = true;
      this.showMobileCart = false;

      
      if (this.route.snapshot.queryParams.viewProduct) {

        setTimeout(() => {
          const config: ScrollToConfigOptions = {
            target: 'product-' + this.route.snapshot.queryParams.viewProduct,
            duration: 200,
            easing: 'easeOutElastic'
          };

          this.scrollToService.scrollTo(config);

          setTimeout(() => {

            for (const category of this.categories) {
              for (const product of category.active_products) {
                if (product.id.toString() === this.route.snapshot.queryParams.viewProduct) {

                  this.viewProduct(product);
                }
              }
            }

          }, 200);

        }, 200);
      }

    }).catch((err) => {

    });

  }


  onScroll(activeElement: NzAnchorLinkComponent) {
    // console.log(activeElement.elementRef.nativeElement.innerText);
    this.activeClass.next(activeElement);
  }

  getSingleBackgroundStore() {


    if (this.vendor.vendor_image) {
      return encodeURI(this.vendor.vendor_image);
    } else {
      return '/assets/img/placeholder-dark.jpg';
    }


  }

  getProductTitle() {

    if (this.viewingProduct) {
      return this.viewingProduct.product_name;
    } else {
      return '';
    }

  }

  validateSingleOption(option: ProductOption) {

    let isValid = false;

    for (const optionValue of option.options) {
      if (optionValue.selected) {
        if (this.viewingProduct.track_inventory) {
          if (this.viewingProduct.inventory_rule == 2) {
            isValid = true;
          }
          else {
            if (optionValue.stock > 0) {
              isValid = true;
            }
          }
        }
        else {
          isValid = true;
        }

      }
    }

    return isValid;

  }

  validateMultiOption(option: ProductOption) {


    let isValid = false;

    for (const optionValue of option.options) {
      if (optionValue.selected) {
        if (this.viewingProduct.track_inventory) {
          if (this.viewingProduct.inventory_rule == 2) {
            isValid = true;
          }
          else {
            if (optionValue.stock > 0) {
              isValid = true;
            }
            else {
              return false;
            }
          }
        }
        else {
          isValid = true;
        }

      }
    }

    return isValid;

  }

  determineTextClass(viewingProduct: Product) {

    if (viewingProduct.main_image.length) {
      return 'col-md-7';
    } else {
      return 'col-md-12';
    }

  }

  validateProductOptions() {

    if (!this.itemToAdd.options) {
      return true;
    }

    for (const option of this.itemToAdd.options) {

      if (option.type === 'Single') {
        if (!this.validateSingleOption(option)) {
          return false;
        }
      }

      if (option.type === 'Multi') {
        if (!this.validateMultiOption(option)) {
          return false;
        }
      }

    }

    return true;

  }

  getSingleLogoStore() {

    if (this.vendor.vendor_logo) {
      return encodeURI(this.vendor.vendor_logo);
    } else {
      return '/assets/img/placeholder.png';
    }

  }

  checkDeliveryMatches(): boolean {

    if (!this.currentProductAvailability.Delivery.Available) {
      this.cantAddMessage = 'This product isn\'t available for delivery, you can make a separate order if you want delivery of this product.';
      return false;
    }

    // GET CURRENT DATE & ESTABLISH IF ITS TODAY
    const date = new Date(this.itemToAdd.scheduled_date);
    const time = new Date(this.itemToAdd.scheduled_time);
    const today = date.getDate() === new Date().getDate();
    const day = date.getDay();

    if (this.currentProductAvailability.Delivery.BlockDays.includes(day)) {
      this.cantAddMessage = 'This product isn\'t available for delivery on your chosen day, you can make a separate order if you want delivery of this product for another day.';
      return false;
    }


    if (this.currentProductAvailability.StopSale) {
      for (const stopDate of this.currentProductAvailability.StopSale) {
        if (new Date(stopDate).getDate() === date.getDate()) {
          // STOP THE SALE
          this.cantAddMessage = 'This product currently isn\'t available for this date. Please make a separate order for the next available date.';
          return false;
        }
      }
    }

    return true;

  }

  checkCanAdd(): boolean {

    if (!this.canAdd) {
      return true;
    }

    if (this.checkingAvailability) {
      return true;
    }


    return !this.validateProductOptions();

  }

  checkCollectionMatches(): boolean {

    // if (!this.currentProductAvailability.Collection.Available) {
    //   this.cantAddMessage = 'This product isn\'t available for collection, you can make a separate order if you want to collect product.';
    //   return false;
    // }

    // GET CURRENT DATE & ESTABLISH IF ITS TODAY
    const date = new Date(this.itemToAdd.scheduled_date);
    const time = new Date(this.itemToAdd.scheduled_time);
    const today = date.getDate() === new Date().getDate();
    const day = date.getDay();

    // if (this.currentProductAvailability.Collection.BlockDays.includes(day)) {
    //   this.cantAddMessage = 'This product isn\'t available for collection on your chosen day, you can make a separate order if you want to collect on another day.';
    //   return false;
    // }

    // if (this.currentProductAvailability.StopSale) {
    //   for (const stopDate of this.currentProductAvailability.StopSale) {
    //     if (new Date(stopDate).getDate() === date.getDate()) {      // STOP THE SALE
    //       this.cantAddMessage = 'This product currently isn\'t available for this date. Please make a separate order for the next available date.';
    //       return false;
    //     }
    //   }
    // }

    return true;

  }

  checkProductMatchesCart(): boolean {

    if (this.itemToAdd.fulfillment_method === 'Delivery') {
      return this.checkDeliveryMatches();
    } else {
      return this.checkCollectionMatches();
    }

  }

  cancelView() {
    this.viewingProduct = null
  }

  viewProduct(product) {

    const decodedProduct: Product = JSON.parse(JSON.stringify(product));

    this.cantAddMessage = '';
    this.inventoryMessage = '';

    if (this.cart.hasCart) {
      // CHECK IF THE PRODUCT AVAILABILITY CONFLICTS WITH THE CURRENT CART
      // manage inventory
      var stock = product.stock;
      var productAlreadyInCart = false;

      var cartVendor = this.cart.cart.vendors.find(x => x.id == product.vendorID);
      if (cartVendor) {
        for (var i = 0; i < cartVendor.items.length; i++) {
          if (cartVendor.items[i].product_id == decodedProduct.id) {
            productAlreadyInCart = true;
            if (decodedProduct.product_options_meta != null) {
              for (var j = 0; j < decodedProduct.product_options_meta.length; j++) {
                for (var k = 0; k < decodedProduct.product_options_meta[j].options.length; k++) {
                  if (cartVendor.items[i].options[j].options[k].selected) {
                    decodedProduct.product_options_meta[j].options[k].stock -= cartVendor.items[i].quantity;
                  }
                }
              }
            }
            else {
              stock -= cartVendor.items[i].quantity;
            }
          }
        }
      }

      if (product.available_type === 'none') {
        this.cantAddMessage = 'This product is out of stock';
        this.checkingAvailability = false;
        this.canAdd = false;
      }
      else if (product.track_inventory && (product.product_options_meta == null || product.product_options_meta.length == 0)) {  // tracked stock no options
        if (product.inventory_rule == 2) {
          this.inventoryMessage = product.inventory_rule_msg;
          this.checkingAvailability = false;
          this.canAdd = true;
        }
        else {
          if (product.stock <= 0) {
            this.cantAddMessage = product.inventory_rule_msg;
            this.checkingAvailability = false;
            this.canAdd = false;
          }
          else {
            this.checkingAvailability = false;
            this.canAdd = true;
          }
        }
      }
      else if (product.track_inventory) {  // tracked options
        var optionHasStock = false;
        product.product_options_meta.forEach(optionGroup => {
          optionGroup.options.forEach(option => {
            if (option.stock > 0) {
              optionHasStock = true;
            }
          });
        });
        if (product.inventory_rule == 2) {
          if (!optionHasStock) {
            this.inventoryMessage = product.inventory_rule_msg;  // only show this if entirely out of stock
          }
          this.checkingAvailability = false;
          this.canAdd = true;
        }
        else {
          this.checkingAvailability = false;
          this.canAdd = true;
          if (!optionHasStock) {
            this.cantAddMessage = product.inventory_rule_msg;  // only show this if entirely out of stock
            this.canAdd = false;
          }
        }
      }
      else {
        this.cart.getAvailabilityForProduct(product.id).then((res) => {
          this.currentProductAvailability = res;
          this.canAdd = this.checkProductMatchesCart();
          this.checkingAvailability = false;
        });
      }
    } else {
      if (product.available_type === 'none') {
        this.cantAddMessage = 'This product is out of stock';
        this.checkingAvailability = false;
        this.canAdd = false;
      }
      else if (product.track_inventory && (product.product_options_meta == null || product.product_options_meta.length == 0)) {  // tracked stock no options
        if (product.inventory_rule == 2) {
          this.inventoryMessage = product.inventory_rule_msg;
          this.checkingAvailability = false;
          this.canAdd = true;
        }
        else {
          if (product.stock <= 0) {
            this.cantAddMessage = product.inventory_rule_msg;
            this.checkingAvailability = false;
            this.canAdd = false;
          }
          else {
            this.checkingAvailability = false;
            this.canAdd = true;
          }
        }
      }
      else if (product.track_inventory) {  // tracked options
        var optionHasStock = false;
        console.log(product);
        product.product_options_meta.forEach(optionGroup => {
          optionGroup.options.forEach(option => {
            if (option.stock > 0) {
              optionHasStock = true;
            }
          });
        });
        if (product.inventory_rule == 2) {
          if (!optionHasStock) {
            this.inventoryMessage = product.inventory_rule_msg;  // only show this if entirely out of stock
          }
          this.checkingAvailability = false;
          this.canAdd = true;
        }
        else {
          this.checkingAvailability = false;
          this.canAdd = true;
          if (!optionHasStock) {
            this.cantAddMessage = product.inventory_rule_msg;  // only show this if entirely out of stock
            this.canAdd = false;
          }
        }
      }
      else {
        this.checkingAvailability = false;
        this.canAdd = true;
      }

    }



    this.viewingProduct = product;
    this.itemToAdd = new CartVendorItem();
    this.itemToAdd.product_id = product.id;
    this.itemToAdd.quantity = 1;

    if (decodedProduct.discount_price) {
      this.itemToAdd.cost_per_item = parseFloat(String(decodedProduct.discount_price));
      this.itemToAdd.base_cost = parseFloat(String(decodedProduct.discount_price));
    } else {
      this.itemToAdd.cost_per_item = parseFloat(String(decodedProduct.base_price));
      this.itemToAdd.base_cost = parseFloat(String(decodedProduct.base_price));
    }

    this.itemToAdd.options = decodedProduct.product_options_meta;
  }

  updateBasketItemOption(newOptionValue, index) {

    const optionsToUpdateCheck = this.itemToAdd.options[index];

    if (optionsToUpdateCheck.type === 'Single') {

      const optionsToUpdate = this.itemToAdd.options[index].options;

      const indexToUpdate = optionsToUpdate.findIndex((optionToCheck) => optionToCheck.title === newOptionValue.title);

      for (const item of optionsToUpdate) {
        item.selected = false;
      }

      optionsToUpdate[indexToUpdate].selected = true;

    }

    this.recalculateItemPrice();

  }

  recalculateItemPrice() {

    let price = this.itemToAdd.base_cost;

    for (const optionGroup of this.itemToAdd.options) {

      for (const option of optionGroup.options) {
        if (option.selected && option.additional_cost) {
          price += option.additional_cost;
        }
      }

    }

    this.itemToAdd.cost_per_item = price;

  }

  updateProductOptions(event: ProductOptionValue[], option, index) {


    // RESET ADDITIONAL COST

    if (this.viewingProduct.discount_price) {
      this.itemToAdd.cost_per_item = parseFloat(String(this.viewingProduct.discount_price));
    } else {
      this.itemToAdd.cost_per_item = parseFloat(String(this.viewingProduct.base_price));
    }

    for (const choice of this.itemToAdd.options[index].options) {

      const choiceIndex = event.findIndex((choyce) => choyce.title === choice.title);
      if (choiceIndex > -1) {
        choice.selected = true;
      } else {
        choice.selected = false;
      }
    }

    this.recalculateItemPrice();

  }

  addToCart() {
    this.itemToAdd.total_cost = this.itemToAdd.cost_per_item * this.itemToAdd.quantity;

    const modal = this.modal.create({
      nzTitle: 'Configure Order',
      nzContent: ConfigureOrderComponent,
      nzComponentParams: {
        itemToAdd: this.itemToAdd,
        vendor: this.vendor,
        product: this.viewingProduct
      },
      nzFooter: null
    });

    // free up the resources
    this.cartConfigModalClose = modal.afterClose.subscribe((data) => {
      this.cartConfigModalClose.unsubscribe();
    });

    this.viewingProduct = null;
  }

  updateTruncation(data) {

    this.showbioMore = data;

  }

  splitTime(startTime, endTime, interval) {

    const result = [startTime.toString()];
    let time = startTime.add(interval, 'm');
    while (time.isBetween(startTime, endTime, undefined, [])) {
      result.push(time.toString());

      // TODO PUSH AVAILABLE TIMES

      time = time.add(interval, 'm');
    }
    return result;

  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  showStock() {
    if (this.viewingProduct.track_inventory && this.viewingProduct.show_inventory && this.viewingProduct.stock > 0 && !this.cantAddMessage) {
      return true;
    }
    else {
      return false;
    }
  }

  availableQuantity() {
    if (this.viewingProduct.track_inventory) {
      if (this.viewingProduct.product_options_meta == null || this.viewingProduct.product_options_meta.length == 0) {
        if (this.viewingProduct.stock > 25) {
          return Array.from({ length: 25 }, (_, i) => i + 1)
        }
        else {
          return Array.from({ length: this.viewingProduct.stock }, (_, i) => i + 1);
        }
      }
      else {
        // determine based on selected option stock
        //return Array.from({ length: 25 }, (_, i) => i + 1)
        var selected = false;
        var min = 1;
        var max = 0;
        this.itemToAdd.options.forEach(optionGroup => {
          optionGroup.options.forEach(option => {
            if (option.selected) {
              selected = true;
              if (option.stock > max) {
                max = option.stock;
              }
              if (this.itemToAdd.quantity > option.stock) {
                this.itemToAdd.quantity = option.stock;
              }
            }
            if (min == 1) {
              min = option.stock;
            }
            else if (min > option.stock) {
              min = option.stock;
            }
          });
        });
        // if (min == 0) {
        //   min = 1;
        // }
        // if (max == 0) {
        //   max = 1;
        // }
        //this.itemToAdd.quantity = 1
        if (selected) {
          return Array.from({ length: max }, (_, i) => i + 1)
        }
        else {
          return Array.from({ length: min }, (_, i) => i + 1)
        }

      }
    }
    else {
      return Array.from({ length: 25 }, (_, i) => i + 1)
    }
  }

  optionStock(option) {
    if (this.viewingProduct.track_inventory) {
      if (option.stock > 0) {
        if (this.viewingProduct.show_inventory) {
          return option.stock + ' in stock'
        }
        else {
          return ''
        }
      }
      else {
        return 'Out of stock'
      }
    }
    else {
      return ''
    }
  }

  optionOutOfStock(option) {
    if (this.viewingProduct.track_inventory) {
      if (option.stock > 0) {
        return false;
      }
      else {
        return true;
      }
    }
    else {
      return false;
    }
  }

}
