import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-truncated-text-block',
  templateUrl: './truncated-text-block.component.html',
  styleUrls: ['./truncated-text-block.component.scss']
})
export class TruncatedTextBlockComponent implements OnInit {


  @Input() content: string;
  @Input() numberOfLines = 3;

  hiding = true;
  showMoreButton = false;

  constructor() { }

  ngOnInit(): void {
  }

  updateTruncation(data){

    this.showMoreButton = data;

  }

}
