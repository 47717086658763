export class NewSearchCriteria{

  sort: string;
  term: string;
  page: number;
  collection: number;
  delivery: number;
  genuine_jersey: number;
  special_offer: number;
  title: string;
  categories: string[];

  constructor(){
    this.sort = 'relevance';
    this.page = 1;
    this.collection = 1;
    this.delivery = 1;
    this.genuine_jersey = 0;
    this.special_offer = 0;
    this.term = '';
  }

}
