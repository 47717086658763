<section class="section hero">

  <div class="container">

    <div class="row">

      <div class="col-sm-12 text-center">

        <h1>{{curationMeta.title}}</h1>
        <h4>Viewing {{curation.length}} products</h4>

      </div>

    </div>

  </div>

</section>
<section class="section">

  <div class="container">

    <div class="rwo">

      <div class="col-sm-12">

        <div class="d-flex curation-container" *ngIf="loaded">

          <div class="single-curation-product" *ngFor="let item of curation" (click)="goToProduct(item)">

            <div class="curation-product-image" *ngIf="item.main_image.length" [style.background-image]="'url('+item.main_image[0].url+')'">



            </div>

            <div class="curation-product-image" *ngIf="!item.main_image.length">



            </div>

            <div class="curation-product-text">

              <div class="truncated-container">
                <h4 [line-truncation]="2">{{item.product_name}}</h4>
              </div>
              <h5>{{item.vendor.trading_name}}</h5>
              <div class="price-block d-flex">

                <h3 class="price" *ngIf="item.discount_price">{{item.discount_price | currency: 'GBP'}}</h3>
                <h3 class="price" *ngIf="!item.discount_price">{{item.base_price | currency: 'GBP'}}</h3>
                <h3 class="reduced-price" *ngIf="item.discount_price"><span class="strike" style="text-decoration: line-through;font-size: 14px;">{{item.base_price | currency:'GBP'}}</span></h3>

              </div>

              <h6 class="text-danger delivery-text">
                <span *ngIf="item.vendor.delivery_on && !item.vendor.collection_on">Delivery Available</span>
                <span *ngIf="!item.vendor.delivery_on && item.vendor.collection_on">Collection Available</span>
                <span *ngIf="item.vendor.delivery_on && item.vendor.collection_on">Delivery & Collection Available</span>
              </h6>

            </div>

          </div>

        </div>


      </div>

    </div>

  </div>

</section>
