import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/Auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FacebookLoginProvider, SocialAuthService} from 'angularx-social-login';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerForm: FormGroup;
  error = '';
  returnUrl: string;

  constructor(private fb: FormBuilder, public auth: AuthService, public route: ActivatedRoute, public router: Router, public socialLogin: SocialAuthService) {

    this.registerForm = fb.group({
      email: ['', [Validators.required, Validators.minLength(4)]],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      phone_number: ['', [Validators.required]],
      password: ['', [Validators.required]],
      gdpr_consent: [false]
    });

  }

  get email(): AbstractControl {
    return this.registerForm.controls.email;
  }

  get phone_number(): AbstractControl {
    return this.registerForm.controls.phone_number;
  }

  get firstName(): AbstractControl {
    return this.registerForm.controls.first_name;
  }

  get lastName(): AbstractControl {
    return this.registerForm.controls.last_name;
  }

  get password(): AbstractControl {
    return this.registerForm.controls.password;
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl;
  }

  submitForm(): void {
    if (this.email.invalid || this.password.invalid || this.firstName.invalid || this.lastName.invalid  || this.phone_number.invalid) {
      this.error = 'Please complete all the fields';
      return;
    }
    this.auth.doRegister(this.registerForm.value).then((res) => {
      this.auth.desktopAuthStore(res).then((res2) => {
        this.router.navigateByUrl('/', );
      //  this.router.navigate(['activate'], {queryParams: {returnUrl: this.returnUrl}});
      });
    }).catch((err) => {
      this.error = err.error.Error;
    });
  }

  facebookRegister(){

    this.socialLogin.signIn(FacebookLoginProvider.PROVIDER_ID).then((res) => {
      this.auth.facebookRegister(res).then((res2) => {
        this.auth.desktopAuthStore(res2).then((res3) => {
          if (res2.user.is_activated){

            if (this.returnUrl){
              // RETURN TO PAGE THAT THEY WERE TRYING TO ACCESS
              this.router.navigateByUrl(this.returnUrl);
            }else{
              // THEY MANUALLY CLICKED LOG IN SO JUST TAKE THEM TO HOME PAGE
              this.router.navigateByUrl('/');
            }

          }else{
            this.router.navigate(['activate'], {queryParams: {returnUrl: this.returnUrl}});
          }
        });
      });
    });

  }

  goToLogin(){

    this.router.navigate(['login'], {queryParams: {returnUrl: this.returnUrl}});

  }

}
