<div class="page-container bg-light">

  <div class="container">

    <div class="row">

      <div class="col-sm-12">

        <h1>Give a gift...</h1>
        <h4>And we'll fetch it for you!</h4>

        <div class="card p-5 mt-4">


          <div class="row mt-4">

            <div class="col-md-6">
              <img class="gift-card-image" src="assets/img/gift-card.jpg">
            </div>

            <div class="col-md-6">

              <h5>You can now purchase gift cards and we'll deliver them digitally to your loved ones in under 5 minutes.</h5>
              <p>Select the amount you want to gift below and hit confirm purchase.</p>

              <div class="row">

                <div class="col-sm-6">
                  <div class="voucherBox" (click)="selectVoucher(10)" [class.selected]="voucherAmount === 10 && voucherType === 'Fixed'">
                      <h4 class="text-primary mb-0 text-center">£10</h4>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="voucherBox" (click)="selectVoucher(20)" [class.selected]="voucherAmount === 20 && voucherType === 'Fixed'">
                    <h4 class="text-primary mb-0 text-center">£20</h4>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="voucherBox" (click)="selectVoucher(50)" [class.selected]="voucherAmount === 50 && voucherType === 'Fixed'">
                    <h4 class="text-primary mb-0 text-center">£50</h4>

                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="voucherBox" (click)="selectVoucher(100)" [class.selected]="voucherAmount === 100 && voucherType === 'Fixed'">
                    <h4 class="text-primary mb-0 text-center">£100</h4>
                  </div>
                </div>

              </div>

              <div class="btn btn-primary btn-lg btn-block" *ngIf="voucherAmount" (click)="generateVoucherPurchase()">Confirm Purchase</div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>
