import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../services/Api.service';
import { CartService } from '../../services/Cart.service';
import { Router } from '@angular/router';
import { Vendor } from '../../models/Vendor';
import { Cart } from '../../models/Cart';
import { CustomerAddressUpdate } from '../../models/CustomerAddressUpdate';
import { PaymentMethod } from '../../models/PaymentMethod';
import { CheckoutService } from '../../services/Checkout.service';
import { Gtag } from 'angular-gtag';
import Timeout = NodeJS.Timeout;
import { GiftInfo } from '../../models/GiftInfo';

@Component({
  selector: 'app-checkout',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.scss']
})
export class BasketComponent implements OnInit {

  constructor(public cartService: CartService, public router: Router, public checkoutService: CheckoutService, public gtag: Gtag) { }

  ngOnInit(): void {
  }

  description() {
    if (this.cartService.hasCart) {
      let items = 0;
      for (let basketVendor of this.cartService.cart.vendors) {
        items += basketVendor.items.length;
      }
      return items + ' items from ' + this.cartService.cart.vendors.length + ' Merchants';
    }
    else {
      return 'Your basket is currently empty.';
    }
  }
}
