<div class="items" *ngIf="cart.cart">

  <h3>Your Vouchers</h3>
  <hr>


  <div class="inner-items">
    <div class="single-cart-item" *ngFor="let item of cart.cart.items">

      <button (click)="removeFromCart(item)" class="delete-btn"><i class="fa fa-times"></i></button>

      <div class="cart-item-details">

        <div class="row">

          <div class="col-8" *ngIf="!item.is_business">
            <h6 class="cart-product-title mb-0">Gift Card for {{item.recipient_name}}:</h6>
            <p>{{item.recipient_email}}</p>
          </div>

          <div class="col-8" *ngIf="item.is_business">
            <h6 class="cart-product-title mb-0">Bulk Voucher Purchase:</h6>
            <p>{{item.business_quantity}}x {{item.voucher_amount | currency :'GBP'}}</p>
          </div>

          <div class="col-4 text-right" *ngIf="item.is_business">
            <p class="product-price mb-0">{{item.voucher_amount * item.business_quantity | currency : 'GBP'}}</p>
          </div>

          <div class="col-4 text-right" *ngIf="!item.is_business">
            <p class="product-price mb-0">{{item.voucher_amount | currency : 'GBP'}}</p>
          </div>

        </div>

      </div>


    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-8">
      <h6>Order Total:</h6>

    </div>
    <div class="col-4 text-right total"><h6>{{cart.totalOrder() | currency : 'GBP'}}</h6></div>
  </div>

  <button class="btn btn-primary btn-block btn-lg mt-3" *ngIf="showCheckout" routerLink="/gift-card-checkout">Checkout Vouchers</button>


</div>

<div class="empty" *ngIf="!cart.cart">

  <p style="font-size: 18px">You currently have no vouchers in your cart</p>

</div>
