import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
  StripeElementsOptions,
  PaymentRequestPaymentMethodEvent,
  PaymentIntent,
  PaymentRequestShippingAddressEvent
} from '@stripe/stripe-js';
import { StripeCardNumberComponent, StripeService } from 'ngx-stripe';
import { CartService } from '../../services/Cart.service';
import { CheckoutService } from '../../services/Checkout.service';
import { Router } from '@angular/router';
import { Gtag } from 'angular-gtag';

@Component({
  selector: 'app-checkout-payments',
  templateUrl: './checkout-payments.component.html',
  styleUrls: ['./checkout-payments.component.scss']
})
export class CheckoutPaymentsComponent implements OnInit {

  @Input() paymentMethods: any[];

  @Input() missingFields: string[];
  @Output() paymentComplete = new EventEmitter<any>();
  @Output() paymentInProcess = new EventEmitter<any>();
  @Output() paymentError = new EventEmitter<any>();

  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

  loaded = false;
  submitted = false;
  errorMessage = '';

  cardNumberValid = false;
  cardExpiryValid = false;
  cardCVCValid = false;

  method = -1;
  
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#00c4a7',
        color: '#18161b',
        fontWeight: '400',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'
  };

  paymentRequestOptions = {
    country: 'GB',
    currency: 'gbp',
    total: {
      label: 'Order with Fetch.je',
      amount: (this.cartService.calculateBasketTotal()) * 100,
    },
    requestPayerName: true,
    requestPayerEmail: true,
  };

  constructor(public stripe: StripeService, public checkout: CheckoutService, public cartService: CartService, public router: Router, public gtag: Gtag) { }

  ngOnInit(): void {
    this.loaded = true;
    if (this.paymentMethods.length) {
      this.method = 0;
    }
  }

  async makePayment() {
    this.errorMessage = '';
    if (this.submitted) {
      return;
    }

    this.submitted = true;
    this.paymentInProcess.emit(true);

    // let's ensure we have all the gift details (hard to update notes via ajax with UI)
    let giftItems = [];
    for (let basketVendor of this.cartService.cart.vendors) {
      for (let basketVendorItem of basketVendor.items) {
        if (basketVendorItem.gift) {
          giftItems.push({ id: basketVendorItem.id, note: basketVendorItem.gift_note, address: basketVendorItem.address_id });
        }
      }
    }

    if (this.method == -1) {
      this.checkout.makePayment('new', giftItems).then((res) => {
        var orderId = res.Order.id;
        this.stripe.confirmCardPayment(res.Intent.client_secret, {
          payment_method: {
            card: this.card.element
          },
        }).subscribe((result) => {
          if (result.error) {
            this.submitted = false;
            this.paymentInProcess.emit(false);
            this.errorMessage = result.error.message;
          } else {
            this.checkout.paymentSuccess(result.paymentIntent, orderId).then((res) => {
              this.paymentSuccess(orderId, res);
            }).catch((err) => {
              this.submitted = false;
              this.paymentInProcess.emit(false);
              this.errorMessage = 'Something has gone wrong, please try again later.';
            });
          }
        });
      }).catch((err) => {  // order creation failed
        this.submitted = false;
        this.paymentInProcess.emit(false);
        this.errorMessage = 'Something has gone wrong, please try again later.';
      });
    }
    else { // stored card
      this.checkout.makePayment('saved', giftItems, this.paymentMethods[this.method].id).then((res) => {
        var orderId = res.Order.id;
        if (res.Intent.status === 'succeeded') {
          this.checkout.paymentSuccess(res.Intent, orderId).then((res) => {
            this.paymentSuccess(orderId, res);
          });
        } else {
          if (res.Intent.status === 'requires_action' || res.Intent.status === 'authentication_required') {
            // HANDLE 3D SECURE
            this.stripe.confirmCardPayment(res.Intent.client_secret).subscribe((result) => {
              if (result.error) {
                this.errorMessage = result.error.message;
                this.submitted = false;
                this.paymentInProcess.emit(false);
              }
              else {
                this.checkout.paymentSuccess(res.Intent, orderId).then((res) => {
                  this.paymentSuccess(orderId, res);
                }).catch((err) => {
                  this.submitted = false;
                  this.paymentInProcess.emit(false);
                  this.errorMessage = 'Something has gone wrong, please try again later.';
                });
              }
            });
          } else {
            this.paymentInProcess.emit(false);
            this.errorMessage = res.Intent.status;
          }
        }
      },(err) => {
        this.submitted = false;
        this.paymentInProcess.emit(false);
        this.errorMessage = 'Something has gone wrong, please try again later.';
      });
    }
  }

  async makeVoucherPayment() {
    if (this.submitted) {
      return;
    }
    this.submitted = true;
    this.paymentInProcess.emit(true);

    // let's ensure we have all the gift details (hard to update notes via ajax with UI)
    let giftItems = [];
    for (let basketVendor of this.cartService.cart.vendors) {
      for (let basketVendorItem of basketVendor.items) {
        if (basketVendorItem.gift) {
          giftItems.push({ id: basketVendorItem.id, note: basketVendorItem.gift_note, address: basketVendorItem.address_id });
        }
      }
    }

    this.checkout.makeVoucherPayment(giftItems).then((res) => {
      this.paymentSuccess(res.id, res);
    }).catch((err) => {
      this.submitted = false;
      this.paymentInProcess.emit(false);
      this.errorMessage = 'Something has gone wrong, please try again later.';
    });
  }

  paymentSuccess(orderId, order) {
    this.submitted = false;
    sessionStorage.clear();
    sessionStorage.setItem('order_id', orderId);

    // analytic tagging
    this.gtag.event('purchase', {
      transaction_id: orderId,
      event_category: 'Fetch.je Website',
      value: order.order_total,
      tax: 0,
      currency: 'GBP',
      shipping: order.delivery_charge,
      items: this.cartService.arrangeItemsForTagManager()
    });

    // clear everything
    this.checkout.cartId = null;
    this.cartService.hasCart = false;
    this.cartService.cartId = null;
    this.cartService.cart = null;
    this.paymentInProcess.emit(false);

    // go to order complete
    this.router.navigateByUrl('/order-complete');
  }

  isInvalid() {
    if (this.method > -1) {
      return false;
    }
    return !this.cardCVCValid || !this.cardExpiryValid || !this.cardNumberValid;
  }

  cardClass(card) {
    if (card.brand == 'visa') {
      return 'payment-card-visa';
    }
    else {
      return ''
    }
  }

  onChange(event: any, type) {
    switch (type) {
      case 'card-number':
        this.cardNumberValid = event.complete && !event.error;
        break;
      case 'card-expiry':
        this.cardExpiryValid = event.complete && !event.error;
        break;
      case 'card-cvc':
        this.cardCVCValid = event.complete && !event.error;
        break;
    }
  }
}