import {Component, Input, OnInit} from '@angular/core';
import {ApiService} from '../../../services/Api.service';
import {CurationProductMin} from '../../../models/CurationProductMin';
import {NewSearchService} from '../../../services/NewSearch.service';
import {Router} from '@angular/router';
import {Product} from '../../../models/Product';
import {SwiperOptions} from 'swiper';

@Component({
  selector: 'app-curation',
  templateUrl: './curation.component.html',
  styleUrls: ['./curation.component.scss']
})
export class CurationComponent implements OnInit {

  @Input() curationID: number;
  @Input() subtitle: string;
  @Input() buttonColour: string;
  @Input() quantity: number;
  total: number;
  loaded = false;

  swiperConfig: SwiperOptions = {

    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1.5,
        spaceBetween: 10
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1.5,
        spaceBetween: 10
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 3.5,
        spaceBetween: 15
      },
      988: {
        slidesPerView: 5,
        spaceBetween: 15
      }
    }

  };

  curation: CurationProductMin[];

  constructor(public api: ApiService, public search: NewSearchService, public router: Router) { }

  ngOnInit(): void {
    if (this.curationID){
      this.api.apiGet('public/curations/' + this.curationID + '?number=' + this.quantity).then((res) => {
        this.curation = res.Results;
        this.total = res.total;
        this.loaded = true;
      });
    }
  }

  navigateToProduct(result){

    const slug = this.search.convertToSlug(result.vendor.trading_name);
    const vendorID = result.vendorID;

    this.router.navigate(['stores', slug, vendorID], {queryParams: {
        viewProduct: result.id
    }});

  }



}
