import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
import './../assets/smtp.js';
import {en_US, NzI18nService} from 'ng-zorro-antd/i18n';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {Gtag} from 'angular-gtag';
import {ScrollService} from './services/ScrollService';

declare let $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
  location: any;
  routerSubscription: any;

  constructor(private router: Router,
              private i18n: NzI18nService,
              public scroll: ScrollService,
              public gtag: Gtag,
  ) {

    this.i18n.setLocale(en_US);

  }

  ngOnInit(){
      this.recallJsFuntions();
  }

  recallJsFuntions() {
      this.router.events
      .subscribe((event) => {
          if ( event instanceof NavigationStart ) {
              $('#preloaders').fadeIn('slow');
          }

        if (event instanceof NavigationEnd) {


        }

      });
      this.routerSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
      .subscribe(event => {
          $.getScript('assets/js/fetch-custom.js');
          $('#preloader').fadeOut('slow');
          this.location = this.router.url;
          if (!(event instanceof NavigationEnd)) {
              return;
          }
          if (this.scroll.scrollToTop){
            window.scrollTo(0, 0);
          }
      });
  }
}


