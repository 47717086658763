<div class="post-container">

  <div class="single-post d-lg-block" [class.d-none]="last" *ngFor="let post of posts; let last = last;">

    <a target="_blank" [href]="post['post-url']" class="image-container"  [style.backgroundImage]="'url('+ post.photo +')'">

    </a>

  </div>

</div>
