<div class="inner-wrapper">
    <div class="container-fluid no-padding">
      <div class="row no-gutters overflow-auto">
        <div class="col-md-6">
          <div class="main-banner dogBg">
          </div>
        </div>
        <div class="col-md-6">
          <div class="section-2 user-page main-padding">
            <div class="login-sec">
              <div class="login-box">
                <form [formGroup]="forgotForm" (submit)="doReset()" *ngIf="view === 1">
                  <h4 class="text-light-black fw-600">Forgotten Password</h4>
                  <div class="row">
                    <div class="col-12">
                      <p class="text-light-black">Enter your e-mail below and we'll send you a code to reset your password.
                      </p>
                      <div class="form-group">
                        <label class="text-light-white fs-14">Email</label>
                        <input formControlName="email" type="email" class="form-control form-control-submit" placeholder="Email Address" required>
                      </div>

                      <div class="form-group" *ngIf="error">

                        <label class="text-danger">Error: {{error}}</label>

                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn-second btn-submit full-width">
                         Request New Password</button>
                      </div>

                      <div class="form-group text-center mb-0"> <a (click)="goToLogin()">Return to login</a>
                      </div>
                    </div>
                  </div>
                </form>

                <form [formGroup]="checkForm" (submit)="doCheck()" *ngIf="view === 2">
                  <h4 class="text-light-black fw-600">Forgotten Password</h4>
                  <div class="row">
                    <div class="col-12">
                      <p class="text-light-black">Please enter the 6 digit code we have sent you.
                      </p>
                      <div class="form-group">
                        <label class="text-light-white fs-14">6 Digit Code</label>
                        <input formControlName="code" type="text" class="form-control form-control-submit" placeholder="123456" required>
                      </div>

                      <div class="form-group" *ngIf="error">

                        <label class="text-danger">Error: {{error}}</label>

                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn-second btn-submit full-width">
                         Verify 6 Digit Code</button>
                      </div>
                    </div>
                  </div>
                </form>

                <form [formGroup]="changeForm" (submit)="doChange()" *ngIf="view === 3">
                  <h4 class="text-light-black fw-600">Update Password</h4>
                  <div class="row">
                    <div class="col-12">
                      <p class="text-light-black">Your new password must be at least 8 characters.
                      </p>
                      <div class="form-group">
                        <label class="text-light-white fs-14">Password</label>
                        <input formControlName="password" type="password" class="form-control form-control-submit" placeholder="" required>
                      </div>

                      <div class="form-group">
                        <label class="text-light-white fs-14">Confirm Password</label>
                        <input formControlName="confirmPassword" type="password" class="form-control form-control-submit" placeholder="" required>
                      </div>

                      <div class="form-group" *ngIf="error">
                        <label class="text-danger">Error: {{error}}</label>
                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn-second btn-submit full-width">
                         Change Password</button>
                      </div>
                    </div>
                  </div>
                </form>

                <div *ngIf="view === 4">
                  <h4 class="text-light-black fw-600">Success</h4>
                  <p>Your password has been reset. You can now log in by clicking below.</p>
                  <button type="button" routerLink="/login" class="btn-second btn-submit full-width">
                    Login</button>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
