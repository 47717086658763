export const environment = {
  production: true,
  apiURL: 'https://fetch-api-uat.azurewebsites.net/',
  tagManagerID: 'GTM-KMKP5C2',
  tagManagerAuth: 'ReJrnhIs2TzKgBN2j6QTTQ',
  tagManagerEnv: 'env-3',
  STRIPE: 'pk_test_51HmdxQFCXxkIBCvQJRozmvQsdwT2eAjPENq5f4dHRZNCLXhJKY0yPLlP4EyfVED3NxMIZzsIbT34bWi9lkGz9SR100AkCNUJbQ',
  elasticAutocompleteEngine: 'main-search-uat',
  elasticProductEngine: 'fetch-uat-products',
  elasticMerchantEngine: 'fetch-uat-merchants',
  elasticKey: 'search-dm9zsoorenqk2jj8cyomswat'
};

// export const environment = {
//   production: true,
//   apiURL: 'https://fetch-api.azurewebsites.net/',
//   tagManagerID: 'GTM-KMKP5C2',
//   tagManagerAuth: 'RLc9GZRW55Y7S2mDHwUwFA',
//   tagManagerEnv: 'env-1',
//   STRIPE: 'pk_live_51HmdxQFCXxkIBCvQltsrrLZ7WbUI2X7lSDW9URXEVm4gJXeHFsAxGPNuw3zZ6k32MwPRPazZTPIh9Giou1cv2NwL00JsLF7Q0Z',
//   elasticAutocompleteEngine: 'main-search-uat',
//   elasticProductEngine: 'fetch-live-products',
//   elasticMerchantEngine: 'fetch-live-merchants',
//   elasticKey: 'search-dm9zsoorenqk2jj8cyomswat'
// };
