<div class="basket">

  <section>
    <div class="container large-container pt-4">
      <div class="row">
        <div class="col-lg-9">


          <div class="heading padding-tb-10 pb-4">
            <div>
              <h3 class="text-light-black title fw-400 no-margin coustard">Your Basket</h3>
              <p class="text-light-black sub-title no-margin" *ngIf="cartService.loaded && cartService.itemsInBasket() > 0">{{description()}}</p>
              <p *ngIf="cartService.loaded && cartService.itemsInBasket() == 0">Your basket is currently empty.</p>
              <button class="btn btn-primary" routerLink="/browse/products" *ngIf="cartService.loaded && cartService.itemsInBasket() == 0">Continue Shopping</button>
            </div>
          </div>

          <div *ngIf="cartService.loaded">
            <basket-vendor [basketVendor]="basketVendor" *ngFor="let basketVendor of cartService.cart.vendors"></basket-vendor>
          </div>
        </div>

        <div class="col-lg-3 pb-md-4">

          <a class="continue-shopping" routerLink="/browse/products" *ngIf="cartService.itemsInBasket() > 0">Continue Shopping</a>

          <div class="sticky-container">
            <div class="inner-cart-container">
              <app-cart [basketPage]="true"></app-cart>
            </div>
          </div>



          <!-- <div class="mobile-cart" [class.show-mobile-cart]="showMobileCart">
            <div class="inner-cart-container">
              <app-cart [vendor]="vendor"></app-cart>
            </div>
            <div class="cart-close-button" *ngIf="showMobileCart">

              <button class="btn btn-secondary btn-block" (click)="showMobileCart = false">Hide Cart</button>

            </div>
          </div> -->

        </div>

      </div>
    </div>
  </section>

</div>

<!-- <div class="mobile-cart-fab" (click)="showMobileCart = true">
  <img src="assets/img/shopping-cart.png" alt="Your Cart">
  <span class="badge badge-dark" *ngIf="cart.cart">{{cart.cart.vendors.length}}</span>
  <span class="badge badge-dark" *ngIf="!cart.cart">0</span>
</div> -->