<div class="loaded" *ngIf="loaded">

  <div class="delivery-address-form" *ngIf="selectedAddress.addressType === 'New'">

    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group mb-4">
          <label class="fs-14">Flat Number / Building Name</label>
          <input type="text" (ngModelChange)="outputNewDeliveryUpdate()" class="form-control form-control-submit" placeholder="Flat 211" required [(ngModel)]="selectedAddress.address.building_name">
        </div>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-6">
        <div class="form-group mb-4">
          <label class="fs-14">Street Address</label>
          <input type="text" name="address-line1" (ngModelChange)="outputNewDeliveryUpdate()" class="form-control form-control-submit"  [(ngModel)]="selectedAddress.address.address_1" placeholder="1 George Avenue" required>
          <small>Include your house number and street name</small>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 col-sm-6">
        <div class="form-group mb-4">
          <label class="fs-14">Parish</label>
          <select [(ngModel)]="selectedAddress.address.town_parish" name="location" class="form-control" (ngModelChange)="outputNewDeliveryUpdate()">
            <option value="">Please Select One</option>
            <option value="St Helier" [disabled]="!willDeliver('St Helier')">St Helier</option>
            <option value="Grouville" [disabled]="!willDeliver('Grouville')">Grouville</option>
            <option value="St Brelade" [disabled]="!willDeliver('St Brelade')">St Brelade</option>
            <option value="St Clement" [disabled]="!willDeliver('St Clement')">St Clement</option>
            <option value="St John" [disabled]="!willDeliver('St John')">St John</option>
            <option value="St Lawrence" [disabled]="!willDeliver('St Lawrence')">St Lawrence</option>
            <option value="St Martin" [disabled]="!willDeliver('St Martin')">St Martin</option>
            <option value="St Mary" [disabled]="!willDeliver('St Mary')">St Mary</option>
            <option value="St Ouen" [disabled]="!willDeliver('St Ouen')">St Ouen</option>
            <option value="St Peter" [disabled]="!willDeliver('St Peter')">St Peter</option>
            <option value="St Saviour" [disabled]="!willDeliver('St Saviour')">St Saviour</option>
            <option value="Trinity" [disabled]="!willDeliver('Trinity')">Trinity</option>
          </select>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 col-sm-6">
        <div class="form-group mb-4">
          <label class="fs-14">Town / City</label>
          <input name="postal-code" type="text" (ngModelChange)="outputNewDeliveryUpdate()" [(ngModel)]="selectedAddress.address.post_code" class="form-control form-control-submit" placeholder="E.G. JE1 1AA" required>
        </div>
      </div>

      <div class="col-lg-6 col-md-12 col-sm-6">
        <div class="form-group mb-4">
          <label class="fs-14">Post Code</label>
          <input name="postal-code" type="text" (ngModelChange)="outputNewDeliveryUpdate()" [(ngModel)]="selectedAddress.address.post_code" class="form-control form-control-submit" placeholder="E.G. JE1 1AA" required>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 col-sm-6" *ngIf="!checkout.isAGift">
        <div class="form-group mb-4">
          <label class="fs-14">Delivery Instructions</label>
          <textarea (ngModelChange)="outputNewDeliveryUpdate()" [(ngModel)]="selectedAddress.address.instructions" class="form-control" placeholder="E.G. Call when outside"></textarea>
        </div>
      </div>

      <div class="col-12">

        <div class="info-box">

          <i class="fa fa-info-circle"></i>

          <p>We'll save this address for next time, don't worry you can remove addresses any time you want.</p>

        </div>

      </div>


    </div>

  </div>

  <div class="delivery-method-list" *ngIf="selectedAddress.addressType === 'Saved'">

    <div class="single-address" [class.selected]="selectedAddress.address.id === address.id" (click)="chooseNewAddress(address)" *ngFor="let address of deliveryMethods">
        <div class="row">
          <div class="col-sm-9">
            <h6 class="mb-0">{{address.building_name}}, {{address.address_1}}, {{address.town_parish}}</h6>
            <p class="mb-0">{{address.post_code}}</p>
          </div>
          <div class="col-sm-3 text-right">
            <i class="fa fa-check-circle fa-2x mt-2 text-white" *ngIf="selectedAddress.address.id === address.id"></i>
          </div>
        </div>
    </div>

    <div class="single-address add" (click)="clickAdd()">

      <h6 class="mb-0">Click to add a new address</h6>

    </div>

  </div>

</div>
