<div class="fullSizeContainer">

  <div class="middle-loader">

    <img src="assets/img/logo.svg" alt="">
    <h4>Fetching...</h4>

  </div>

</div>
