import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../services/Api.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {CurationProductMin} from '../../models/CurationProductMin';
import {NewSearchService} from '../../services/NewSearch.service';

@Component({
  selector: 'app-single-curation-page',
  templateUrl: './single-curation-page.component.html',
  styleUrls: ['./single-curation-page.component.scss']
})
export class SingleCurationPageComponent implements OnInit, OnDestroy {

  curationID: number;
  subscription: Subscription;
  curation: CurationProductMin[];
  curationMeta: any;
  loaded = true;

  constructor(public api: ApiService, public route: ActivatedRoute, public router: Router, public search: NewSearchService) { }

  ngOnInit(): void {
    this.subscription = this.route.params.subscribe((data) => {
        this.curationID = data.id;
        this.api.apiGet('public/curations/' + this.curationID + '?number=50').then((res) => {
          this.curation = res.Results;
          this.curationMeta = res.CurationData;
          this.loaded = true;
        });
    });
  }

  ngOnDestroy(): void{
    this.subscription.unsubscribe();
  }

  goToProduct(result){

    const slug = this.search.convertToSlug(result.vendor.trading_name);
    const vendorID = result.vendorID;

    this.router.navigate(['stores', slug, vendorID], {queryParams: {
        viewProduct: result.id
      }});


  }

}
