import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService} from '../../services/Auth.service';
import {SearchService} from '../../services/Search.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {NewSearchService} from '../../services/NewSearch.service';
import {NewSearchCriteria} from '../../models/NewSearchCriteria';
import {ScrollService} from '../../services/ScrollService';
import { CartService } from 'src/app/services/Cart.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy {

  authState = 'guest';
  user: any;
  isOpen: boolean;
  showMobileSearch: boolean;
  showCta: boolean;
  menuSubscription: Subscription;
  authSubscription: Subscription;
  menuSections: any[] = [];

  constructor(public auth: AuthService, public cartService: CartService, public search: NewSearchService, public router: Router, public route: ActivatedRoute, public scroll: ScrollService){
    this.authSubscription = this.auth.userObservable().subscribe((data) => {
      this.updateAuthState(data);
    });

    this.menuSubscription = this.route.url.subscribe((data) => {
      this.showCta = !data.length;
    });

  }

  ngOnInit(): void {
    this.menuSections = [];
    for (const section of this.search.categorySections){
      if (section.show_in_menu){
        this.menuSections.push(section);
      }
    }
  }

  ngOnDestroy() {
    this.authSubscription.unsubscribe();
    this.menuSubscription.unsubscribe();
  }

  toggleIsOpen(){
    this.isOpen = !this.isOpen;
  }

  updateAuthState(userData): void{

    if(!userData){
      this.authState = 'guest';
    }else{
      this.authState = 'loggedIn';
      this.user = userData;
    }
    console.log(userData);

  }

  giftCards(){
    this.isOpen = false;
    this.router.navigateByUrl('/gift-cards');
  }

  visitSection(section){

    this.search.searchCriteria = new NewSearchCriteria();
    this.search.searchCriteria.categories = [];
    this.search.searchCriteria.page = 1;
    this.isOpen = false;

    for (const cat of section.categories){
      this.search.searchCriteria.categories.push(cat.category_name);
    }

    this.search.updateSearch(true, 'products');

  }

  logout(){

    this.auth.logOut();

  }

}
