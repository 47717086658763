import { Component, Input, OnInit } from '@angular/core';
import { Vendor } from '../../models/Vendor';
import { Cart } from '../../models/Cart';
import { CartService } from '../../services/Cart.service';
import { SearchService } from '../../services/Search.service';
import { ProductOption } from '../../models/ProductOption';
import { OpeningTime } from '../../models/OpeningTime';
import * as moment from 'moment';
import { VoucherPurchasedComponent } from 'src/app/pages/voucher-purchased/voucher-purchased.component';
import { Voucher } from 'src/app/models/Voucher';
import { CartVoucher } from 'src/app/models/CartVoucher';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

  @Input() vendor: Vendor;
  @Input() basketPage: Boolean;
  @Input() checkoutPage: Boolean;
  showExpireModal: boolean;
  showVoucherInput = false;
  voucherCode = '';
  voucherCodeError = '';

  constructor(public cartService: CartService, public search: SearchService) { }

  ngOnInit(): void {
  }

  checkForExpired() {
    if (this.cartService.cart) {
      for (const cartVendor of this.cartService.cart.vendors) {
        for (const item of cartVendor.items) {
          if (item.stop_sale_expiry) {
            return true;
          }
        }
      }
    }
    return false;
  }

  addVoucher() {
    if (this.voucherCode) {
      this.voucherCodeError = '';
      this.cartService.submitVoucherCode(this.voucherCode).then((res: CartVoucher) => {
        this.voucherCode = '';
        this.showVoucherInput = false;
        this.cartService.cart.vouchers.push(res);
      }).catch((err) => {
        this.voucherCode = '';
        this.voucherCodeError = err.error.Error;
      });
    }
  }
}