<section class="banner-1 p-relative">
  <div class="merchant-signup-banner">

      <div class="d-flex justify-content-center">
        <h6 class="p-relative"><img class="dog-special" src="assets/img/maxhide.png"> Become a Fetch Merchant. <br class="d-md-none"><a target="_blank" href="https://merchant.fetch.je/signup">Join us for free today!</a></h6>
      </div>

    <div class="ctaseparator"></div>
  </div>
  <img class="max-img" src="assets/img/max.png" alt="Max">

  <div class="transform-center">
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <h2 class="text-light-black fw-700">Need local products, shopping & fresh food?</h2>
          <h1 class="text-white fw-700">We'll fetch it.</h1>

          <div class="search-bar-container">
            <app-search-bar></app-search-bar>
          </div>

          <h5 class="text-white my-4" routerLink="/browse">Or view all shops <i class="fa fa-arrow-right ml-2"></i></h5>
        </div>
      </div>
    </div>
  </div>


</section>


<!-- Explore collection -->
<section class="ex-collection section-padding">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="section-header-left">
          <h2 class="text-light-black header-title title">Explore local shops, restaurants & more</h2>
        </div>
      </div>
    </div>
<!--    <div class="row">-->
<!--      <div class="col-md-6">-->
<!--        <div class="mb-xl-20">-->
<!--          <img src="assets/img/home1.gif" class="img-fluid full-width" alt="image">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="col-md-6">-->
<!--        <div class="mb-xl-20">-->
<!--          <a href="https://merchant.fetch.je/signup"><img src="assets/img/home2.gif" class="img-fluid full-width" alt="image"></a>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="row">
      <div class="col-lg-3 col-md-4 d-none d-md-block">
        <div class="mb-xl-20 p-relative">
          <video style="cursor: pointer; width: 100%;" routerLink="/gift-cards" src="assets/img/voucherhome.mp4" oncanplay="this.play()" onloadedmetadata="this.muted = true" autoplay loop muted playsinline poster="assets/img/poster-voucher.jpg">
          </video>
        </div>
      </div>
      <div class="col-lg-9 col-md-8">
          <app-home-page-stores></app-home-page-stores>
      </div>
    </div>
  </div>
</section>
<!-- Explore collection -->
