<div class="row">

  <div class="col-lg-4  col-sm-6" *ngFor="let vendor of stores">
    <div class="single-vendor-list" [routerLink]="'/browse/' + vendor.id" >

      <div class="vendor-image" [style.backgroundImage]="'url('+ getBackgroundImageForVendor(vendor) +')'">
      </div>

      <div class="vendor-content">
<!--  <p class="rating excellent"><i class="fa fa-star"></i>No Ratings Yet</p>-->
        <p class="mb-0">{{vendor.trading_name}}</p>
        <p class="categories">
          <span *ngFor="let category of vendor.categories; let last = last">{{category.category_name}}<span *ngIf="!last">, </span> </span>
        </p>

      </div>

    </div>
  </div>

</div>


