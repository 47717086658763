<app-navbar></app-navbar>
<div class="content-container">
  <div class="search-header" style="background-image: url('assets/img/max-search.png')">

    <div class="container">

      <div class="row">

        <div class="col-sm-12">
          <div class="text-center w-100">

            <h1>Fetch is closing on the 18th October.</h1>

          </div>
        </div>

      </div>

    </div>

  </div>
  <router-outlet></router-outlet>
</div>
<app-footer1></app-footer1>
