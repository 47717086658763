import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {
  StripeCardElementChangeEvent,
  StripeCardElementOptions,
  StripeElementsOptions,
  PaymentRequestPaymentMethodEvent,
  PaymentIntent,
  PaymentRequestShippingAddressEvent} from '@stripe/stripe-js';
import {StripeCardNumberComponent, StripeService} from 'ngx-stripe';
import {VoucherCheckoutService} from '../../../services/VoucherCheckout.service';
import {CartVoucherService} from '../../../services/CartVoucher';

@Component({
  selector: 'app-voucher-payments',
  templateUrl: './voucher-payments.component.html',
  styleUrls: ['./voucher-payments.component.scss']
})
export class VoucherPaymentsComponent implements OnInit {

  @Input() missingFields: string[];
  @Output() paymentComplete = new EventEmitter<any>();
  @Output() paymentInProcess = new EventEmitter<any>();
  @Output() paymentError = new EventEmitter<any>();

  @ViewChild(StripeCardNumberComponent) card: StripeCardNumberComponent;

  cardNumberValid = false;
  cardExpiryValid = false;
  cardCVCValid = false;

  view = 'New';

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#00c4a7',
        color: '#18161b',
        fontWeight: '400',
        fontFamily: 'Poppins, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en'

  };

  paymentRequestOptions = {
    country: 'GB',
    currency: 'gbp',
    total: {
      label: 'Voucher Purchase with Fetch.je',
      amount: this.voucherCheckout.voucherRequest.voucher_amount * 100,
    },
    requestPayerName: true,
    requestPayerEmail: true,
  };

  loaded = false;
  lockForm = false;

  constructor(public stripe: StripeService, public voucherCheckout: VoucherCheckoutService, public cart: CartVoucherService) { }

  ngOnInit(): void {

    this.loaded = true;

  }

  tryPayment(){

    this.paymentInProcess.emit(true);
    this.newStripeCardPayment();

  }

  newStripeCardPayment(){

    this.lockForm = true;

    this.voucherCheckout.paymentIntent(this.cart.cart.unique_basket_id).then((res) => {

      this.stripe.confirmCardPayment(res.PaymentIntent.client_secret, {
        payment_method: {
          card: this.card.element,
        },
      }).subscribe((result) => {

        if (result.error){
          this.paymentError.emit(result.error);
        }else{
          this.paymentComplete.emit(result.paymentIntent);
        }

        this.lockForm = false;

      });

    }).catch((err) => {
      this.lockForm = false;
    });

  }

  checkValid(){

    if (this.lockForm){
      return true;
    }

    if (
      this.voucherCheckout.voucherRequest.sender_name &&
      this.voucherCheckout.voucherRequest.sender_email
    ){
      return !this.cardCVCValid || !this.cardExpiryValid || !this.cardNumberValid;
    }else{
      return true;
    }

  }

  onChange(event: any, type){

    switch (type){

      case 'card-number':

        this.cardNumberValid = event.complete && !event.error;

        break;

      case 'card-expiry':

        this.cardExpiryValid = event.complete && !event.error;

        break;

      case 'card-cvc':

        this.cardCVCValid = event.complete && !event.error;

        break;

    }

  }


}
