import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import {MatDialogModule} from '@angular/material/dialog';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ListviewComponent } from './pages/listview/listview.component';
import { LoginComponent } from './pages/login/login.component';
import { RegisterComponent } from './pages/register/register.component';
import { NavbarComponent } from './layouts/navbar/navbar.component';
import { Footer1Component } from './layouts/footer1/footer1.component';
import { Footer2Component } from './layouts/footer2/footer2.component';
import { AdvertisementbannerComponent } from './layouts/advertisementbanner/advertisementbanner.component';
import { BlogleftsidebarComponent } from './layouts/blogleftsidebar/blogleftsidebar.component';
import { BlogrightsidebarComponent } from './layouts/blogrightsidebar/blogrightsidebar.component';
import { RestaurantleftsidebarComponent } from './layouts/restaurantleftsidebar/restaurantleftsidebar.component';
import { RestaurantrightsidebarComponent } from './layouts/restaurantrightsidebar/restaurantrightsidebar.component';
import { Advertisementbanner1Component } from './layouts/advertisementbanner1/advertisementbanner1.component';
import { Advertisementbanner2Component } from './layouts/advertisementbanner2/advertisementbanner2.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ContactComponent } from './pages/contact/contact.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ActivateComponent} from './pages/activate/activate.component';
import { SearchSidebarComponent } from './Components/search-sidebar/search-sidebar.component';
import { SidebarAdvertComponent } from './Components/sidebar-advert/sidebar-advert.component';
import {NzRadioModule} from 'ng-zorro-antd/radio';
import {NzCheckboxModule} from 'ng-zorro-antd/checkbox';
import { PageLoaderComponent } from './Components/page-loader/page-loader.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SingleStoreComponent } from './pages/single-store/single-store.component';
import { CartComponent } from './Components/cart/cart.component';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzIconModule} from 'ng-zorro-antd/icon';
import {NzButtonModule} from 'ng-zorro-antd/button';
import {NzDropDownModule} from 'ng-zorro-antd/dropdown';
import {ScrollToModule} from '@nicky-lenaers/ngx-scroll-to';
import {NzModalModule} from 'ng-zorro-antd/modal';
import {NzPipesModule} from 'ng-zorro-antd/pipes';
import {CartCheckout} from './Components/cart-checkout/cart-checkout.component';
import { CheckoutDeliveryComponent } from './Components/checkout-delivery/checkout-delivery.component';
import { CheckoutPaymentsComponent } from './Components/checkout-payments/checkout-payments.component';
import {NgxStripeModule} from 'ngx-stripe';
import { OrderTrackerComponent } from './pages/order-tracker/order-tracker.component';
import {CartOrderComponent} from './Components/cart-order/cart-order.component';
import {NzStepsModule} from 'ng-zorro-antd/steps';
import {SingleOrderComponent} from './pages/single-order/single-order.component';
import {NzTimePickerModule} from 'ng-zorro-antd/time-picker';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { OrderListComponent } from './pages/order-list/order-list.component';
import { OldOrderComponent } from './pages/old-order/old-order.component';
import { VouchersComponent } from './pages/vouchers/vouchers.component';
import {CheckoutVoucherComponent} from './pages/checkout-voucher/checkout-voucher.component';
import {CartVoucherComponent} from './Components/vouchers/cart-voucher/cart-voucher.component';
import {VoucherDeliveryComponent} from './Components/vouchers/voucher-delivery/voucher-delivery.component';
import {VoucherPaymentsComponent} from './Components/vouchers/voucher-payments/voucher-payments.component';
import { VoucherPurchasedComponent } from './pages/voucher-purchased/voucher-purchased.component';
import {NgxStarRatingModule} from 'ngx-star-rating';
import {NzNotificationModule} from 'ng-zorro-antd/notification';
import { HomePageStoresComponent } from './Components/home-page-stores/home-page-stores.component';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule} from 'angularx-social-login';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {TermsComponent} from './pages/terms/terms.component';
import {SellingOnFetchComponent} from './pages/selling-on-fetch/selling-on-fetch.component';
import {ErrorMonitor} from './Incerceptor/ErrorMonitor';
import {NzDatePickerModule} from 'ng-zorro-antd/date-picker';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {environment} from '../environments/environment';
import { OrderConfigurationComponent } from './Components/order-configuration/order-configuration.component';
import {DateTimeFormatPipe} from './Pipes/NewData';
import { SearchBarComponent } from './Components/search-bar/search-bar.component';
import {ProductListComponent} from './pages/productList/product-list.component';
import { HomeLayoutComponent } from './layouts/home-layout/home-layout.component';
import { MainLayoutComponent } from './layouts/main-layout/main-layout.component';
import {NavBarHomeComponent} from './layouts/navbar-home/navbar.component';
import { NzAnchorModule } from 'ng-zorro-antd/anchor';
import {NgDecodeURIComponentPipeModule} from './Pipes/decodeURIPipe';
import { BulkVouchersComponent } from './pages/bulk-vouchers/bulk-vouchers.component';
import {BulkContactComponent} from './pages/bulk-contact/bulk-contact.component';
import { LineTruncationLibModule } from 'ngx-line-truncation';
import {LoginSpecialComponent} from './pages/loginSpecial/login-special.component';
import {CookieService} from 'ngx-cookie-service';
import {GtagModule} from 'angular-gtag';
import {JpFulfilmentComponent} from './pages/jp-fulfilment/jp-fulfilment.component';
import {BsDatepickerModule, BsLocaleService} from 'ngx-bootstrap/datepicker';
import {ConfigureOrderComponent} from './Components/Modals/configure-order/configure-order.component';
import { defineLocale } from 'ngx-bootstrap/chronos';
import {enGbLocale} from 'ngx-bootstrap/locale';
import {MerchantListComponent} from './pages/merchantList/merchant-list.component';
import {NgAisModule} from 'angular-instantsearch';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import { SearchAutoCompleteComponent } from './Components/search-auto-complete/search-auto-complete.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BasketVendorComponent } from './Components/basket-vendor/basket-vendor.component';

import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';
import { SubscribeComponent } from './Components/Widgets/subscribe/subscribe.component';
import { CurationComponent } from './Components/Widgets/curation/curation.component';
import { GenericDynamicPageComponent } from './pages/generic-dynamic-page/generic-dynamic-page.component';
import { InstagramComponent } from './Components/instagram/instagram.component';
import {NewSearchService} from './services/NewSearch.service';
import {SearchLayoutComponent} from './layouts/search-layout/search-layout.component';
import {MatPaginatorModule} from '@angular/material/paginator';
import {NzPaginationModule} from 'ng-zorro-antd/pagination';
import {NzCarouselModule} from 'ng-zorro-antd/carousel';
import { SingleCurationPageComponent } from './pages/single-curation-page/single-curation-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { ScrollTopComponent } from './Components/scroll-top/scroll-top.component';
import {NzSelectModule} from 'ng-zorro-antd/select';
import { SwiperModule } from 'swiper/angular';
import {LivechatWidgetModule} from '@livechat/angular-widget';
import { TruncatedTextBlockComponent } from './Components/truncated-text-block/truncated-text-block.component';
import {NzAffixModule} from 'ng-zorro-antd/affix';
import {NgxStickySidebarModule} from '@smip/ngx-sticky-sidebar';
import { BasketComponent } from './pages/basket/basket.component';
import { CheckoutAddressComponent } from './Components/checkout-address/checkout-address.component';
import { OrderComponent } from './pages/order/order.component';

//import { PixelModule } from 'ngx-pixel';

defineLocale('en-gb', enGbLocale); // only setting up Norwegian bokmaal (nb) in this sample

const componentParsers: Array<HookParserEntry> = [
  {component: CurationComponent},
  {component: SubscribeComponent}
];

@NgModule({
  declarations: [
    AppComponent,
    ConfigureOrderComponent,
    HomeComponent,
    AboutComponent,
    ActivateComponent,
    BasketComponent,
    CheckoutComponent,
    ListviewComponent,
    NavBarHomeComponent,
    LoginComponent,
    RegisterComponent,
    NavbarComponent,
    Footer1Component,
    Footer2Component,
    SearchLayoutComponent,
    AdvertisementbannerComponent,
    BlogleftsidebarComponent,
    BlogrightsidebarComponent,
    RestaurantleftsidebarComponent,
    RestaurantrightsidebarComponent,
    ResetPasswordComponent,
    Advertisementbanner1Component,
    Advertisementbanner2Component,
    ContactComponent,
    SearchSidebarComponent,
    SidebarAdvertComponent,
    PageLoaderComponent,
    SingleStoreComponent,
    CartComponent,
    BasketVendorComponent,
    MerchantListComponent,
    CartCheckout,
    CartOrderComponent,
    CheckoutDeliveryComponent,
    CheckoutPaymentsComponent,
    OrderTrackerComponent,
    OrderComponent,
    SingleOrderComponent,
    MyAccountComponent,
    OrderListComponent,
    OldOrderComponent,
    ProductListComponent,
    VouchersComponent,
    CheckoutVoucherComponent,
    CartVoucherComponent,
    CheckoutDeliveryComponent,
    VoucherDeliveryComponent,
    VoucherPaymentsComponent,
    VoucherPurchasedComponent,
    HomePageStoresComponent,
    PrivacyComponent,
    TermsComponent,
    DateTimeFormatPipe,
    SellingOnFetchComponent,
    OrderConfigurationComponent,
    BulkContactComponent,
    SearchBarComponent,
    HomeLayoutComponent,
    JpFulfilmentComponent,
    LoginSpecialComponent,
    MainLayoutComponent,
    BulkVouchersComponent,
    SearchAutoCompleteComponent,
    SubscribeComponent,
    CurationComponent,
    GenericDynamicPageComponent,
    InstagramComponent,
    SingleCurationPageComponent,
    NotFoundPageComponent,
    ScrollTopComponent,
    TruncatedTextBlockComponent,
    CheckoutAddressComponent

  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        NgAisModule.forRoot(),
        MatInputModule,
        MatAutocompleteModule,
        SwiperModule,
        NgDecodeURIComponentPipeModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        BsDatepickerModule.forRoot(),
        NgxSkeletonLoaderModule.forRoot(),
        ScrollToModule.forRoot(),
        HttpClientModule,
        MatDialogModule,
        NzRadioModule,
        NgxStarRatingModule,
        MatPaginatorModule,
        NgxStickySidebarModule,
        NzPaginationModule,
        NzCarouselModule,
        NzCheckboxModule,
        NzButtonModule,
        NzTabsModule,
        AccordionModule.forRoot(),
        SocialLoginModule,
        NzIconModule,
        NgxStripeModule.forRoot(environment.STRIPE),
        NzDropDownModule,
        NzModalModule,
        NzPipesModule,
        NzStepsModule,
        NzTimePickerModule,
        NzNotificationModule,
        NzDatePickerModule,
        NzAnchorModule,
        LivechatWidgetModule,
        LineTruncationLibModule,
        DynamicHooksModule.forRoot({
            globalParsers: componentParsers
        }),
        GtagModule.forRoot({trackingId: '387249387', trackPageviews: true}),
        NzSelectModule,
        NzAffixModule
        //PixelModule.forRoot({ enabled: true, pixelId: '126698261577772' })
    ],
  providers: [
      Location, {
        provide: LocationStrategy,
        useClass: PathLocationStrategy
      },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('761535918052746')
          }
        ]
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorMonitor,
      multi: true
    },
    CookieService,
    NewSearchService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(private bsLocaleService: BsLocaleService) {
    this.bsLocaleService.use('en-gb');
  }

}
