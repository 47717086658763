import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../services/Api.service';
import {Search} from '../../models/Search';
import {SearchService} from '../../services/Search.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Product} from '../../models/Product';
import {NewSearchCriteria} from '../../models/NewSearchCriteria';
import {NewSearchService} from '../../services/NewSearch.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements OnInit, OnDestroy {

  constructor(public api: ApiService, public search: NewSearchService, public router: Router, public route: ActivatedRoute) {

  }

  error = false;
  loaded = false;
  showMobileFilter = false;
  subscription;
  products: Product[];

  productResults: any[];
  meta: any;
  sections: any;

  searchOptions = {
    page: {
      size: 24,
      current: 1
    },
    sort: [],
    filters: {
      all: [

      ]
    }
  };


  ngOnInit(): void {
      this.subscription = this.route.queryParams.subscribe((data: any) => {
        if (Object.keys(data).length){
            this.sortCriteriaFromQuery(data);
            this.doSearch(this.search.searchCriteria);
          }else{
            // BLANK SEARCH
            this.search.searchCriteria = new NewSearchCriteria();
            this.search.updateSearch(false, 'products');
          }
      });
  }

  viewProduct(product): void{

   // this.router.navigate(['browse', product.vendor.id], {queryParams: {viewProduct: product.id}});

  }

  ngOnDestroy(): void {

    this.subscription.unsubscribe();

  }

  isOpen(section){

    for (const item of section.categories){
      if (this.isChecked(item.category_name)){
        return true;
      }
    }

    return false;

  }

  getTotalResultsFake(results, size){
    if (results > (size * 100)){
      return 2000;
    }else{
      return results;
    }
  }

  navigateToProduct(result){

    const slug = this.search.convertToSlug(result.vendor.raw);
    const vendorID = result.vendor_id.raw.replace('vend-', '');

    this.router.navigate(['stores', slug, vendorID], {queryParams: {
        viewProduct: result.id.raw.replace('prod-', '')
    }});

  }

  sortCriteriaFromQuery(data){

    if (data.categories){
      this.search.searchCriteria.categories = data.categories.split(',');
    }
    this.search.searchCriteria.collection = parseInt(data.collection, 0);
    this.search.searchCriteria.delivery = parseInt(data.delivery, 0);
    this.search.searchCriteria.genuine_jersey = parseInt(data.genuine_jersey, 0);
    this.search.searchCriteria.special_offer = parseInt(data.special_offer, 0);
    this.search.searchCriteria.page = parseInt(data.page, 0);
    this.search.searchCriteria.term = data.term;
    this.search.searchCriteria.sort = data.sort;
  }

  checkBox(box, event){

    this.search.searchCriteria[box] = +event;
    this.search.searchCriteria.page = 1;

    this.search.updateSearch(false, 'products');

  }

  adjustSort(event){

    this.search.searchCriteria.sort = event;
    this.search.searchCriteria.page = 1;
    this.search.updateSearch(false, 'products');

  }

  isChecked(box){

    if (this.search.searchCriteria.categories){
      return this.search.searchCriteria.categories.indexOf(box) > -1;
    }else{
      return false;
    }


  }

  checkCategory(box, event){

    if (!this.search.searchCriteria.categories){
      this.search.searchCriteria.categories = [];
    }

    if (!this.isChecked(box)){
      this.search.searchCriteria.categories.push(box);
    }else{
      const index = this.search.searchCriteria.categories.indexOf(box);
      this.search.searchCriteria.categories.splice(index, 1);
    }

    this.search.updateSearch(false);

  }

  changePage(page){

    this.search.searchCriteria.page = page;
    this.search.updateSearch(true);

  }

  doSearch(data: NewSearchCriteria): void{

    const parsedData = JSON.parse(JSON.stringify(data));

    this.searchOptions.filters.all = [];
    this.searchOptions.sort = [];

    this.searchOptions.filters.all.push({
      is_available : 'true'
    });

    this.searchOptions.filters.all.push({
      vendor_is_online : 1
    });

    if (parsedData.genuine_jersey){
      this.searchOptions.filters.all.push({
        is_genuine_jersey: 1
      });
    }

    if (parsedData.special_offer){
      this.searchOptions.filters.all.push({
        on_offer: 1
      });
    }

    switch (parsedData.sort){

      case '':
      case 'relevance':
        this.searchOptions.sort.push({
          _score : 'desc'
        });
        break;

      case 'popular':
        this.searchOptions.sort.push({
          sales_count : 'desc'
        });
        break;

      case 'new':
        this.searchOptions.sort.push({
          date_created : 'desc'
        });
        break;

      case 'z-a':
        this.searchOptions.sort.push({
            name: 'desc'
        });
        break;

      case 'a-z':
        this.searchOptions.sort.push({
            name: 'asc'
        });
        break;

      case 'price-d':
        this.searchOptions.sort.push({
            price: 'desc'
        });
        break;

      case 'price-a':
        this.searchOptions.sort.push({
            price: 'asc'
        });
        break;

    }


    if (parsedData.page){
      this.searchOptions.page.current = parseInt(parsedData.page, 0);
    }


    if (data.delivery !== data.collection){
      this.searchOptions.filters.all.push({
        delivery_available: (data.delivery === 1).toString()
      });

      this.searchOptions.filters.all.push({
        collection_available: (data.collection === 1).toString()
      });
    }

    if (data.categories && data.categories.length){
      this.searchOptions.filters.all.push({
        category: data.categories
      });
    }

    if (data.term || data.term === ''){
      this.search.productElastic.search(data.term, this.searchOptions).then((searchResults) => {
        this.meta = searchResults.info.meta;
        this.productResults = searchResults.rawResults;
      });
    }else{
      this.search.productElastic.search('', this.searchOptions).then((searchResults) => {
        this.meta = searchResults.info.meta;
        this.productResults = searchResults.rawResults;
      });
    }

  }

  switchToMerchantSearch(){

    this.search.searchCriteria.sort = 'relevance';
    this.search.updateSearch(false, 'merchants');
  }

  clearFilters(){

    this.search.searchCriteria.categories = [];
    this.search.searchCriteria.genuine_jersey = 0;
    this.search.searchCriteria.delivery = 1;
    this.search.searchCriteria.collection = 1;
    this.search.searchCriteria.special_offer = 0;
    this.search.updateSearch(false, 'products');

  }

}
