import {Component, OnDestroy, OnInit} from '@angular/core';
import {ApiService} from '../../services/Api.service';
import {Search} from '../../models/Search';
import {SearchService} from '../../services/Search.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Product} from '../../models/Product';
import {Vendor} from '../../models/Vendor';
import {NewSearchService} from '../../services/NewSearch.service';
import {NewSearchCriteria} from '../../models/NewSearchCriteria';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-merchant-list',
  templateUrl: './merchant-list.component.html',
  styleUrls: ['./merchant-list.component.scss']
})
export class MerchantListComponent implements OnInit, OnDestroy {

  constructor(public api: ApiService, public search: NewSearchService, public router: Router, public route: ActivatedRoute, private titleService: Title) {

  }

  error = false;
  loaded = false;
  showMobileFilter = false;
  subscription;
  products: Product[];

  vendorResults: any[];
  meta: any;
  sections: any;

  searchOptions = {
    page: {
      size: 12,
      current: 1
    },
    sort: [],
    filters: {
      all: [
      ]
    }
  };


  ngOnInit(): void {

    this.subscription = this.route.queryParams.subscribe((data: any) => {
      console.log('search changed');
      if(Object.keys(data).length){
        this.sortCriteriaFromQuery(data);
        this.doSearch(this.search.searchCriteria);
      }else{
        // BLANK SEARCH
        this.search.searchCriteria = new NewSearchCriteria();
        this.router.navigate(['browse', 'merchants'], {
          queryParams: this.search.searchCriteria
        });
      }
    });
  }

  clearFilters(){

    this.search.searchCriteria.categories = [];
    this.search.searchCriteria.genuine_jersey = 0;
    this.search.searchCriteria.delivery = 1;
    this.search.searchCriteria.collection = 1;
    this.search.searchCriteria.special_offer = 0;
    this.search.updateSearch(false, 'products');

  }

  viewProduct(product): void{

    // this.router.navigate(['browse', product.vendor.id], {queryParams: {viewProduct: product.id}});

  }

  adjustSort(event){

    this.search.searchCriteria.sort = event;
    this.search.searchCriteria.page = 1;
    this.search.updateSearch(false, 'merchants');

  }

  ngOnDestroy(): void {

    this.subscription.unsubscribe();

  }

  isOpen(section){

    for (const item of section.categories){
      if (this.isChecked(item.category_name)){
        return true;
      }
    }

    return false;

  }

  sortCriteriaFromQuery(data){

    if (data.categories){
      this.search.searchCriteria.categories = data.categories.split(',');
    }
    this.search.searchCriteria.collection = parseInt(data.collection, 0);
    this.search.searchCriteria.delivery = parseInt(data.delivery, 0);
    this.search.searchCriteria.genuine_jersey = parseInt(data.genuine_jersey, 0);
    this.search.searchCriteria.special_offer = parseInt(data.special_offer, 0);
    this.search.searchCriteria.page = parseInt(data.page, 0);
    this.search.searchCriteria.term = data.term;
    this.search.searchCriteria.sort = data.sort;

  }

  checkBox(box, event){

    this.search.searchCriteria[box] = +event;
    this.search.searchCriteria.page = 1;

    this.search.updateSearch(false, 'merchants');

  }

  isChecked(box){

    if (this.search.searchCriteria.categories){
      return this.search.searchCriteria.categories.indexOf(box) > -1;
    }else{
      return false;
    }

  }

  goToMerchant(result){

    const slug = this.search.convertToSlug(result.name.raw);
    const vendorID = result.id.raw.replace('vend-', '');

    this.router.navigate(['stores', slug, vendorID]);

  }

  checkCategory(box, event){

    if (!this.search.searchCriteria.categories){
      this.search.searchCriteria.categories = [];
    }

    if (!this.isChecked(box)){
      this.search.searchCriteria.categories.push(box);
    }else{
      const index = this.search.searchCriteria.categories.indexOf(box);
      this.search.searchCriteria.categories.splice(index, 1);
    }

    this.search.updateSearch(false, 'merchants');

  }

  changePage(page){

    this.search.searchCriteria.page = page;
    this.search.updateSearch(true, 'merchants');

  }

  doSearch(data: NewSearchCriteria): void{

    const parsedData = JSON.parse(JSON.stringify(data));

    this.searchOptions.filters.all = [];
    this.searchOptions.sort = [];
    this.searchOptions.filters.all.push({
      is_online : 1
    });

    if (parsedData.genuine_jersey){
      this.searchOptions.filters.all.push({
        is_genuine_jersey: 1
      });
    }

    switch (parsedData.sort){

      case '':
      case 'relevance':
        this.searchOptions.sort.push({
          _score : 'desc'
        });
        break;

      case 'z-a':
        this.searchOptions.sort.push({
          name: 'desc'
        });
        break;

      case 'new':
        this.searchOptions.sort.push({
          date_created: 'desc'
        });
        break;

      case 'a-z':
        this.searchOptions.sort.push({
          name: 'asc'
        });
        break;

    }

    if (parsedData.page){
      this.searchOptions.page.current = parseInt(parsedData.page, 0);
    }

    if (data.delivery !== data.collection){
      this.searchOptions.filters.all.push({
        delivery: data.delivery
      });

      this.searchOptions.filters.all.push({
        collection: data.collection
      });
    }

    if (data.categories && data.categories.length){
      this.searchOptions.filters.all.push({
        categories: data.categories
      });
    }

    if (data.term || data.term === ''){
      this.search.merchantElastic.search(data.term, this.searchOptions).then((searchResults) => {
        this.meta = searchResults.info.meta;
        this.vendorResults = searchResults.rawResults;
      });
    }else{
      this.search.merchantElastic.search('', this.searchOptions).then((searchResults) => {
        this.meta = searchResults.info.meta;
        this.vendorResults = searchResults.rawResults;
      });
    }

  }

  switchToProductSearch(){

    this.router.navigate(['browse', 'products'], {queryParamsHandling: 'preserve'});

  }

}
