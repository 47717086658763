import { Injectable } from '@angular/core';
import {
  Router, Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot
} from '@angular/router';
import {EMPTY, Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContentResolver implements Resolve<boolean> {
  private result: any;
  private slug: string;

  constructor(private http: HttpClient, public router: Router) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {

    console.log(route.data);
    console.log(route.params);

    // IF SLUG IS DECLARED IN THE DATA PARAMS OVERRIDE EVERYTHING
    if (route.data.slug){
      this.slug = route.data.slug;
    }else{

      if (!route.data.bucket){

        // NO BUCKET DECLARED JUST USE THE SLUG
        this.slug = route.params.slug;
      }else{
        if (route.params.slug){
          this.slug = route.data.bucket + route.params.slug;
        }else{
          this.slug = route.data.bucket;
        }
      }

    }

    this.slug = this.slug.replace('-undefined', '');

    return this.http.get<any>('https://fetch-cms-wp.azurewebsites.net/wp-json/wp/v2/pages?slug=' + this.slug)
      .pipe(
        map((result: any[]) => {
          this.result = result;
          if (!result.length){
            this.router.navigate(['/404-page-not-found']);
            return EMPTY;
          }
          return result;
        }),
        catchError((err, caught) => {
          this.router.navigate(['/404-page-not-found']);
          return EMPTY;
          })
        );
  }
}
