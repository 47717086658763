<app-page-loader *ngIf="!loaded"></app-page-loader>
<div class="loaded" *ngIf="loaded">
  <div class="single-account-header">

    <div class="container">

      <div class="row">
        <div class="col-sm-12">

          <h4 style="background-color: white;padding:5px;">Fetch.je is closing on the 18th October.<br/>Please continue to support and shop local, directly with retailers.</h4>
          
          <h2>Welcome back {{customer.first_name}} {{customer.last_name}}.</h2>
          <p class="mb-2">Manage your fetch account here.</p>

          <button class="btn btn-dark" routerLink="/previous-orders">View My Orders</button>

        </div>

      </div>

    </div>

  </div>

  <div class="main-section pt-5 bg-light">

    <div class="container">

      <div class="row">

        <div class="col-md-4">

          <div class="account-card p-0">

            <div class="single-tab" [class.selected]="selectedTab === 1" (click)="selectedTab = 1">
              <h5>My Information</h5>
            </div>

            <div class="single-tab" [class.selected]="selectedTab === 2" (click)="selectedTab = 2">
              <h5>Saved Addresses</h5>
            </div>

            <div class="single-tab" [class.selected]="selectedTab === 3" (click)="selectedTab = 3">
              <h5>Saved Payment Methods</h5>
            </div>
            <div class="single-tab" [class.selected]="selectedTab === 4" (click)="selectedTab = 4">
              <h5>Recover Lost Gift Vouchers</h5>
            </div>
            <div class="single-tab" [class.selected]="selectedTab === 5" (click)="selectedTab = 5">
              <h5>Delete My Account</h5>
            </div>

          </div>

        </div>

        <div class="col-md-8">

          <div class="account-card" *ngIf="selectedTab === 1">

            <h4>My Account Information</h4>

            <form [formGroup]="customerForm" (submit)="updateCustomerData()">

              <div class="row">

                <div class="col-md-6">
                  <div class="form-group">
                    <label>First Name</label>
                    <input type="text" class="form-control" formControlName="first_name">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Last Name</label>
                    <input type="text" class="form-control" formControlName="last_name">
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Telephone Number</label>
                    <input type="text" class="form-control" formControlName="phone_number">
                    <span><small><strong>Useful for getting SMS updates on your order.</strong></small></span>
                  </div>
                </div>
                <div class="col-sm-12">
                  <button type="submit" class="btn btn-primary">Update My Information</button>
                </div>

              </div>

            </form>

          </div>

          <div class="account-card" *ngIf="selectedTab === 4">

            <h4>Recover Lost Gift Vouchers</h4>

            <div>

              <p>Simply enter your e-mail address below and we'll send any voucher codes associated to that e-mail directly to you..</p>

              <input type="email" [(ngModel)]="lostVoucherEmail" class="form-control" required>

              <button type="submit" class="btn btn-primary mt-3" (click)="submitLostVoucherForm()"  [disabled]="!lostVoucherEmail.length">Submit</button>

              <h6 *ngIf="invalidEmail" class="text-danger mt-3"><small><strong>You have entered an invalid e-mail address.</strong></small></h6>
              <h6 *ngIf="successMessage" class="text-primary mt-3"><small><strong>If any voucher codes are linked to your e-mail, they will be sent to you.</strong></small></h6>
              <h6 *ngIf="errorMessage" class="text-danger mt-3"><small><strong>There has been an error in sending your voucher codes, please contact us.</strong></small></h6>

            </div>

          </div>

          <div class="account-card" *ngIf="selectedTab === 5">

            <h4>Delete My Account</h4>

            <div>
              <p>This will fully delete your account. Note your account cannot be recovered once you have done this - you will lose your order history and will need to register again to login.</p>
              <p>Enter "DELETE" in the field below and press the button below to delete your account.</p>
              <input type="email" [(ngModel)]="deleteMe" class="form-control" required placeholder="DELETE">
              <button type="submit" class="btn btn-danger mt-3" (click)="submitDelete()"  [disabled]="deleteMe != 'DELETE'">Submit</button>

              <h6 *ngIf="invalidEmail" class="text-danger mt-3"><small><strong>You have entered an invalid e-mail address.</strong></small></h6>
              <h6 *ngIf="successMessage" class="text-primary mt-3"><small><strong>If any voucher codes are linked to your e-mail, they will be sent to you.</strong></small></h6>
              <h6 *ngIf="errorMessage" class="text-danger mt-3"><small><strong>There has been an error in sending your voucher codes, please contact us.</strong></small></h6>

            </div>

          </div>

          <div class="account-card" *ngIf="selectedTab === 2">

            <h4 class="m-0">Saved Addresses</h4>
            <p>You can remove saved addresses from here.</p>

            <div class="single-address" *ngFor="let address of customer.addresses">

              <div class="row">

                <div class="col-8">
                  <h5 class="mb-0">{{address.recipient}}</h5>
                  <p class="mb-0">{{address.building_name}} {{address.address_1}}</p>
                  <p class="mb-0">{{address.address_2}}</p>
                  <p class="mb-0">{{address.town_parish}}</p>
                  <p class="mb-0">{{address.post_code}}</p>
                </div>
                <div class="col-4 text-right">
                  <button class="btn btn-sm btn-danger" (click)="removeAddress(address.id)">Delete Address</button>
                </div>

              </div>

            </div>

          </div>

          <div class="account-card" *ngIf="selectedTab === 3">

            <h4 class="m-0">Saved Cards</h4>
            <p>You can remove saved cards from here. New cards are saved on checkout.</p>

            <div class="single-address" *ngFor="let paymentMethod of customer.payment_methods">

              <div class="row">

                <div class="col-8">
                  <h5 class="mb-0">{{paymentMethod.card.brand | titlecase}} ending in {{paymentMethod.card.last4}}</h5>
                  <p class="mb-0">{{paymentMethod.card.exp_month}} / {{paymentMethod.card.exp_year}}</p>
                </div>
                <div class="col-4 text-right">
                  <button class="btn btn-sm btn-danger" (click)="removePaymentMethod(paymentMethod.id)">Delete Card</button>
                </div>

              </div>

            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>
