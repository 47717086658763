<section class="vc_section dark-overlay vc_section-has-fill gift-section" style="background-size:cover; background-image: url('https://fetch-cms-wp.azurewebsites.net/wp-content/uploads/2021/10/iStock-923079848.png')">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="vc_column-inner">
          <div class="wpb_wrapper">
            <div class="wpb_text_column wpb_content_element primary-underline text-white">
              <div class="wpb_wrapper">
                <p style="text-align: center;">Give the <strong>gift</strong> of fetch</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="vc_row-full-width vc_clearfix"></div>
</section>

<div class="page-container bg-light">

  <div class="container">

    <div class="row">

      <div class="col-lg-7">

        <div class="card p-4 mt-4">

          <div class="row">

            <div class="col-md-12">

              <p>You can now purchase multiple gift cards at once and we'll deliver them digitally to everyone in under 5 minutes.</p>
              <p>Select the amount you want to gift below, enter the recipient details, then add to the cart.</p>

              <h3  *ngIf="!cart.cart">I am purchasing for...</h3>
              <h3  *ngIf="cart.cart">I am purchasing for <span *ngIf="cart.cart.is_business">a lot of people.</span><span *ngIf="!cart.cart.is_business"> a few people.</span></h3>

              <div class="d-flex voucher-options" *ngIf="!cart.cart">

                <div class="voucherBox" (click)="setBusiness(false)" [class.selected]="is_business === false">
                  <h5 class="text-primary mb-2 text-center  w-100">Personal Use</h5>
                  <p class="mb-0">We’ll e-mail the voucher codes on your behalf</p>
                </div>

                <div class="voucherBox" (click)="setBusiness(true)" [class.selected]="is_business === true">
                  <h5 class="text-primary mb-2 text-center w-100">A Business</h5>
                  <p class="mb-0">We’ll deliver a spreadsheet with your voucher codes</p>
                </div>

              </div>

              <div class="d-flex voucher-quantities">

                <div class="voucherBox" (click)="selectVoucher('Fixed', 10)" [class.selected]="voucherAmount === 10 && voucherType === 'Fixed'">
                  <h6 class="text-primary mb-0 text-center">£10</h6>
                </div>

                <div class="voucherBox" (click)="selectVoucher('Fixed', 20)" [class.selected]="voucherAmount === 20 && voucherType === 'Fixed'">
                  <h6 class="text-primary mb-0 text-center">£20</h6>
                </div>

                <div class="voucherBox" (click)="selectVoucher('Fixed', 50)" [class.selected]="voucherAmount === 50 && voucherType === 'Fixed'">
                  <h6 class="text-primary mb-0 text-center">£50</h6>
                </div>

                <div class="voucherBox" (click)="selectVoucher('Fixed', 100)" [class.selected]="voucherAmount === 100 && voucherType === 'Fixed'">
                  <h6 class="text-primary mb-0 text-center">£100</h6>
                </div>

                <div class="voucherBox" (click)="selectVoucher('Custom', null)" [class.selected]="voucherType === 'Custom'">
                  <h6 class="text-primary mb-0 text-center">Other Amount</h6>
                </div>

              </div>

              <p>Our gift vouchers have a validity of one year from the date of purchase.</p>

              <div class="form">

                <div class="form-group mb-4" *ngIf="voucherType === 'Custom'">
                  <label class="fs-14">Custom Amount</label>
                  <input type="number" name="voucherAmount" class="form-control form-control-submit"  [(ngModel)]="voucherAmount" placeholder="Enter an amount in pounds" required>
                </div>

                <hr *ngIf="voucherType === 'Custom'">

                <div class="inner-form" *ngIf="voucherAmount && isBusiness()">

                  <div class="row">
                    <div class="col-md-6">

                      <div class="form-group mb-4">
                        <label class="fs-14">Quantity*</label>
                        <input type="number" name="business_quantity" class="form-control form-control-submit"  [(ngModel)]="business_quantity" placeholder="E.g. 20" required>
                      </div>

                    </div>
                  </div>

                </div>

                <div class="inner-form" *ngIf="voucherAmount && !isBusiness()">

                  <div class="row">

                    <div class="col-md-6">

                      <div class="form-group mb-4">
                        <label class="fs-14">Recipient Name*</label>
                        <input type="text" name="recipient_name" class="form-control form-control-submit"  [(ngModel)]="recipient_name" placeholder="E.g. John Doe" required>
                      </div>

                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-4">
                        <label class="fs-14">Recipient Email*</label>
                        <input type="text" name="recipient_email" class="form-control form-control-submit"  [(ngModel)]="recipient_email" placeholder="E.g. john@doe.com" required>
                      </div>
                    </div>

                  </div>

                  <div class="row">

                    <div class="col-md-12">

                      <div class="form-group mb-4">
                        <label class="fs-14">Your Message</label>
                        <textarea name="message" class="form-control form-control-submit"  [(ngModel)]="message" placeholder="E.g. Happy Birthday John" required></textarea>
                      </div>

                    </div>

                  </div>

                </div>

              </div>

              <div class="btn btn-primary btn-lg btn-block" *ngIf="voucherAmount" (click)="addVoucherToCart()">Add To Cart (<span *ngIf="!isBusiness()">{{voucherAmount | currency : 'GBP'}}</span><span *ngIf="isBusiness() && business_quantity">{{voucherAmount * business_quantity | currency : 'GBP'}}</span>)</div>

            </div>

          </div>

        </div>

      </div>

      <div class="col-lg-5">

        <div class="card p-4 mt-4">


          <app-cart-voucher></app-cart-voucher>


        </div>

        <div class="card mt-4 p-4" *ngIf="cart.cart && cart.cart.is_business">You are purchasing for business use, all voucher codes will be sent to you in an excel spreadsheet.</div>

      </div>

    </div>

    <div class="row">

      <div class="col-md-6">

        <div class="card mt-4">

          <div class="p-4">

            <H3>Want to pay with BACS Transfer?</H3>

            <p>Click below to contact us to make an order.</p>

            <a href="mailto:info@fetch.je" class="btn btn-primary">Email us</a>

          </div>

        </div>


      </div>
      <div class="col-md-6">

        <div class="card mt-4">

          <div class="p-4">

            <H3>Lost your voucher codes?</H3>

            <p>Simply enter your e-mail address below and we'll send any voucher codes associated to that e-mail directly to you..</p>

            <input type="email" [(ngModel)]="lostVoucherEmail" class="form-control" required>

            <button type="submit" class="btn btn-primary mt-3" (click)="submitLostVoucherForm()"  [disabled]="!lostVoucherEmail.length">Submit</button>

            <h6 *ngIf="invalidEmail" class="text-danger mt-3"><small><strong>You have entered an invalid e-mail address.</strong></small></h6>
            <h6 *ngIf="successMessage" class="text-primary mt-3"><small><strong>If any voucher codes are linked to your e-mail, they will be sent to you.</strong></small></h6>
            <h6 *ngIf="errorMessage" class="text-danger mt-3"><small><strong>There has been an error in sending your voucher codes, please contact us.</strong></small></h6>

          </div>

        </div>


      </div>

    </div>

  </div>

</div>
