import { Component, OnInit } from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/Auth.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.css']
})
export class ActivateComponent implements OnInit {

  activateForm: FormGroup;
  error = '';
  user;
  returnUrl;
  resent = false;

  constructor(private fb: FormBuilder, public auth: AuthService, public router: Router, public route: ActivatedRoute) {
  }

  get code(): AbstractControl {
    return this.activateForm.controls.code;
  }

  get password(): AbstractControl {
    return this.activateForm.controls.password;
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl;

    const checkStorage = JSON.parse(localStorage.getItem('user'));

    if ( checkStorage ){
      this.user = checkStorage;
    }

    this.activateForm = this.fb.group({
      code: ['', [Validators.required]],
      userID: [checkStorage.id]
    });


  }

  submitForm(): void {
    if (this.code.invalid) {
      this.error = 'Please enter the code.';
      return;
    }else{
      this.auth.doActivate(this.activateForm.value).then((res) => {
        localStorage.setItem('user', JSON.stringify(res.UpdatedUser));
        this.auth.user.next(res.UpdatedUser);
        if (this.returnUrl){
          // RETURN TO PAGE THAT THEY WERE TRYING TO ACCESS
          this.router.navigateByUrl(this.returnUrl);
        }else{
          // THEY MANUALLY CLICKED LOG IN SO JUST TAKE THEM TO HOME PAGE
          this.router.navigateByUrl('/');
        }

      });
    }

  }

  reSendCode(){

    this.auth.reSendCode(this.activateForm.value.userID).then((res) => {
        this.resent = true;
    });

  }

}
