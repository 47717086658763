<div class="inner-wrapper">
    <div class="container-fluid no-padding">
      <div class="row no-gutters overflow-auto">
        <div class="col-md-6">
          <div class="main-banner dogBg">
          </div>
        </div>
        <div class="col-md-6">
          <div class="section-2 user-page main-padding">
            <div class="login-sec">
              <div class="login-box">
                <form [formGroup]="registerForm" (submit)="submitForm()">
                  <!-- <h4 class="text-light-black fw-600">Create your fetch account</h4> -->
                  <div class="row">

                    <div class="col-12">

                      <p>Sorry we're not accepting new registrations for Fetch, please get in touch directly with the merchant you wish to buy from.</p>

     
                      <!--<div class="form-group text-center"> <span>or</span>
                      </div>
                      <div class="form-group">
                        <button type="submit" class="btn-second btn-facebook full-width" (click)="facebookRegister()">
                          <img src="assets/img/facebook-logo.svg" alt="btn logo">Continue with Facebook</button>
                      </div>-->
                      <div class="form-group text-center">
                        <p class="text-light-black mb-0">Have an account? <a (click)="goToLogin()">Sign in</a>
                        </p>
                      </div> 
                      <!-- <span class="text-light-black fs-12 terms">By registering, you agree to the <a routerLink="/terms-and-conditions"> Terms of Use </a> and <a routerLink="/privacy-policy"> Privacy Policy.</a></span>
                      <hr> -->

                      <!-- <p class="text-light-black mb-0 text-center">Want to sell on fetch? <a href="https://merchant.fetch.je/signup">Click Here</a>
                      </p> -->
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
