import {Component, Input, OnInit} from '@angular/core';
import {CartVoucherService} from '../../../services/CartVoucher';
import {GiftCartItem} from '../../../models/GiftCartItem';

@Component({
  selector: 'app-cart-voucher',
  templateUrl: './cart-voucher.component.html',
  styleUrls: ['./cart-voucher.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class CartVoucherComponent implements OnInit {

  @Input() amount: number;
  @Input() showCheckout = true;

  constructor(public cart: CartVoucherService) { }

  ngOnInit(): void {

  }

  removeFromCart(item: GiftCartItem){

    this.cart.removeItemFromCart(item).then((res) => {
      if (res.items.length){
        this.cart.cart = res;
      }else{
        // CART IS NOW EMPTY, START AGAIN
        this.cart.cart = null;
        sessionStorage.removeItem('fetch-voucher-cart');
      }
    });

  }


}
