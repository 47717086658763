import {AfterContentInit, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {SearchService} from '../../services/Search.service';
import {AuthService} from '../../services/Auth.service';
import {NzModalService} from 'ng-zorro-antd/modal';
import {CookieService} from 'ngx-cookie-service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../services/Api.service';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, AfterContentInit {

  @ViewChild('tplContent') public tplContent;
  @ViewChild('tplFooter') public tplFooter;
  modalRef: any;
  wasLoggedIn: boolean;
  signUpForm: FormGroup;
  signupMessage = '';
  constructor(
    public search: SearchService,
    public auth: AuthService,
    public cookie: CookieService,
    public modal: NzModalService,
    public fb: FormBuilder,
    public api: ApiService, private titleService: Title) {

    this.signUpForm = fb.group({
      email: ['', Validators.required]
    });

  }

  ngOnInit(): void {
    

    // RESET LOCATION
    if (this.search.search.location === 'Everywhere'){
      this.search.search.location = 'St Helier';
      this.search.update();
    }

  }

  ngAfterContentInit() {

    this.auth.userObservable().subscribe((data) => {
      if (data || this.wasLoggedIn){
        this.wasLoggedIn = true;
      }
    });

  }

  setCookie() {
    if (!this.cookie.get('0242ac130003')) {
      const setCookie = this.cookie.set('0242ac130003', '{set: true}', {path: '/', sameSite: 'Lax', expires: this.twoWeeks()});
    }
  }

  setCookiePerm(){
    const setCookie = this.cookie.set('0242ac130003', '{set: true}', {path: '/', sameSite: 'Lax', expires: this.forever()});
  }

  visitMerchant(){
    window.location.replace('https://merchant.fetch.je');
  }

  signUpNewsletter(){

    if (this.signUpForm.valid){
      this.api.apiPost('public/subscribe', this.signUpForm.value).then((result) => {
        this.signupMessage = result.Success;
        this.setCookiePerm();
      }).catch((error) => {
        this.signupMessage = error.error.Error;
      });
    }else{
      this.signupMessage = 'Please complete your e-mail address.';
    }

  }

  twoWeeks(): Date{

    return this.add_weeks(new Date(), 2);

  }

  forever(): Date{

    return this.add_weeks(new Date(), 200);

  }

  add_weeks(dt, n): Date
  {
    return new Date(dt.setDate(dt.getDate() + (n * 7)));
  }


}
