  <div class="d-flex p-relative">
    <input placeholder="Search..." [ngModel]="elastic.searchCriteria.term" matInput [matAutocomplete]="auto" (keyup)="handleChange($event)"/>
    <button *ngIf="this.currentTerm || elastic.searchCriteria.term" class="cancel-search-btn" type="button" (click)="clearSearch()"><i class="fas fa-close"></i></button>
    <button #searchNtn class="search-btn" type="submit" (click)="triggerSearch()"><img src="assets/icons/loupe.svg" alt="Search"></button>
  </div>
 <mat-autocomplete [class]="'searchAutocomplete'" [hidden]="!elastic.showAutocompleteResults" #auto="matAutocomplete" style="margin-top: 30px; max-height: 600px">
   <mat-option *ngFor="let result of elastic.autoCompleteResults" [value]="result.data.name.raw" (click)="triggerSearch(result)">
     <div>
       <div class="d-flex">
         <h6>{{result.data.name.raw}}</h6>
         <div class="badge"></div>
       </div>
     </div>
   </mat-option>
  </mat-autocomplete>


