<div class="loaded" *ngIf="loaded">

  <div class="delivery-address-form">

    <div class="row">

      <div class="col-sm-12">
        <h4>Your Details</h4>
      </div>


      <div class="col-lg-6 col-md-12 col-sm-6">
        <div class="form-group mb-4">
          <label class="fs-14">Your Name</label>
          <input type="text" class="form-control form-control-submit" placeholder="John Doe" required [(ngModel)]="giftService.voucherRequest.sender_name">
        </div>
      </div>

      <div class="col-lg-6 col-md-12 col-sm-6">
        <div class="form-group mb-4">
          <label class="fs-14">Your Email Address</label>
          <input type="text" [(ngModel)]="giftService.voucherRequest.sender_email" class="form-control form-control-submit" placeholder="john@doe.com" required>
        </div>
      </div>

      <div class="col-12">

        <div class="info-box">

          <i class="fa fa-info-circle"></i>

          <p>A copy will be sent to you and the recipients, usually within 5 minutes but sometimes longer. If you don't receive this after 1 hour please contact our customer services.</p>

        </div>

      </div>


    </div>

  </div>

</div>
