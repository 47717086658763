<div *ngIf="loaded" class="payment payment-loaded">

  <nz-radio-group [(ngModel)]="method" *ngIf="cartService.calculateBasketTotalAfterVouchers()">
    <label nz-radio [nzValue]="i" *ngFor="let paymentMethod of paymentMethods; index as i">
      <div class="payment-card" [ngClass]="cardClass(paymentMethod.card)"><strong>Card ending {{paymentMethod.card.last4}}</strong> Expiry {{paymentMethod.card.exp_month}}/{{paymentMethod.card.exp_year}}</div>
    </label>
    <label nz-radio [nzValue]="-1">
      <div class="payment-card"><strong>Add a new card</strong></div>
    </label>
  </nz-radio-group>

  <div *ngIf="method == -1" class="new-payment-form">

    <div [elementsOptions]="elementsOptions" ngxStripeCardGroup>

      <div class="row">
        <div class="col-sm-12 col-md-5">
          <div class="form-group">
            <label>Card Number</label>
            <ngx-stripe-card-number (change)="onChange($event, 'card-number')" [options]="cardOptions" containerClass="form-control"></ngx-stripe-card-number>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-6 col-md-2">
          <div class="form-group">
            <label>Expiry Date</label>
            <ngx-stripe-card-expiry (change)="onChange($event, 'card-expiry')" [options]="cardOptions" containerClass="form-control"></ngx-stripe-card-expiry>
          </div>
        </div>

        <div class="col-sm-6 col-md-2">
          <div class="form-group">
            <label>CVV Number</label>
            <ngx-stripe-card-cvc (change)="onChange($event, 'card-cvc')" [options]="cardOptions" containerClass="form-control"></ngx-stripe-card-cvc>
          </div>
        </div>

      </div>

    </div>

  </div>

  <div class="row" *ngIf="errorMessage">
    <div class="col-sm-12 payment-error">
      {{errorMessage}}
    </div>
  </div>

  <div class="row" *ngIf="cartService.calculateBasketTotalAfterVouchers()">
    <div class="col-12 col-md-3">
      <button (click)="makePayment()" class="btn btn-primary btn-block btn-big" [disabled]="submitted || isInvalid()">Pay Now</button>
    </div>
  </div>

  <div class="row" *ngIf="!cartService.calculateBasketTotalAfterVouchers()">
    <div class="col-sm-12 col-md-6">
      <button (click)="makeVoucherPayment()" class="btn btn-primary btn-block btn-big" [disabled]="submitted">Complete Order Using Vouchers</button>
    </div>
  </div>