import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../../services/Api.service';

@Component({
  selector: 'app-footer1',
  templateUrl: './footer1.component.html',
  styleUrls: ['./footer1.component.scss']
})
export class Footer1Component implements OnInit {

  signUpForm: FormGroup;
  showBigFoot = true;
  signupMessage = '';

  constructor(public route: ActivatedRoute, public router: Router, public fb: FormBuilder, public api: ApiService) {

    this.route.data.subscribe((data) => {
      console.log(data);
      this.showBigFoot = !data.hideFooter;
    });

    this.signUpForm = fb.group({
      email: ['', Validators.required]
    });


  }

  ngOnInit(): void {
  }

  signUpNewsletter(){

    if (this.signUpForm.valid){
      this.api.apiPost('public/subscribe', this.signUpForm.value).then((result) => {
        this.signupMessage = result.Success;
      }).catch((error) => {
        this.signupMessage = error.error.Error;
      });
    }else{
      this.signupMessage = 'Please complete your e-mail address.';
    }

  }

}
