import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {AuthService} from './Auth.service';
import {Cart} from '../models/Cart';
import {Vendor} from '../models/Vendor';
import {Search} from '../models/Search';
import {ApiService} from './Api.service';
import {NewCartCriteria} from '../models/NewCartCriteria';
import {OpeningTime} from '../models/OpeningTime';
import {TimeExclusion} from '../models/TimeExclusion';
import * as moment from 'moment';
import {VoucherCart} from '../models/VoucherCart';

// @ts-ignore
@Injectable({
  providedIn: 'root',
})
// @ts-ignore
export class CartVoucherService {

  apikey: string;
  httpOptions: any;
  uploadOptions: any;
  cartId;
  cart: VoucherCart;
  hasCart = false;

  constructor(
    public router: Router,
    public auth: AuthService,
    public api: ApiService,
    public http: HttpClient
  ) {
      this.auth.tokenObservable().subscribe(data => {
          this.apikey = data;

          this.httpOptions = {
              headers: new HttpHeaders({
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + this.apikey,
              }),
          };

          this.uploadOptions = {
              headers: new HttpHeaders({
                  Authorization: 'Bearer ' + this.apikey,
              }),
          };

      });

      const checkCart = sessionStorage.getItem('fetch-voucher-cart');

      if (checkCart){

        this.cartId = checkCart;

        this.getCart().then((res) => {
          this.cart = res;
          this.hasCart = true;
        });

      }else{
        this.hasCart = false;
        console.log('No Cart Found');
      }

  }


  totalOrder(){

    let totalcost = 0;

    for (const item of this.cart.items){

      if(item.business_quantity){
        totalcost += parseFloat(String(item.voucher_amount)) * item.business_quantity;
      }else{
        totalcost += parseFloat(String(item.voucher_amount));
      }
    }

    return totalcost;

  }

  public calculateCheckoutTotal(){
      return this.totalOrder();
  }

  getCart(): any{

      return new Promise((resolve, reject) => {
        this.http
          .get(environment.apiURL + 'api/public/gift-vouchers/cart/' + this.cartId,  this.httpOptions)
          .subscribe(
            res => {
              resolve(res);
            },
            err => {
              reject(err);
            },
          );
      });

  }

  newCart(item): any{

    const payload = {
      newItem: item
    } as any;

    if (item.is_business){
      payload.is_business = true;
      payload.newItem = item;
    }else{
      payload.newItem = item;
    }

    return new Promise((resolve, reject) => {

        this.http
          .post(environment.apiURL + 'api/public/gift-vouchers/cart', payload,  this.httpOptions)
          .subscribe(
            res => {
              resolve(res);
            },
            err => {
              reject(err);
            },
          );

    });

  }

  addToCart(item): any{

      return new Promise((resolve, reject) => {
        this.http
          .post(environment.apiURL + 'api/public/gift-vouchers/cart/' + this.cartId + '/add', {
            newItem: item
          }, this.httpOptions)
          .subscribe(
            res => {
              resolve(res);
            },
            err => {
              reject(err);
            },
          );
      });
  }


  removeItemFromCart(item): any{

      return new Promise((resolve, reject) => {
        this.http
          .get(environment.apiURL + 'api/public/gift-vouchers/cart/' + this.cart.unique_basket_id + '/' + item.id + '/remove',  this.httpOptions)
          .subscribe(
            res => {
              resolve(res);
            },
            err => {
              reject(err);
            },
          );
      });
  }

  emptyCart(){

      return new Promise((resolve, reject) => {
        this.http
          .get(environment.apiURL + 'api/public/cart/' + this.cart.unique_basket_id,  this.httpOptions)
          .subscribe(
            res => {
              resolve(res);
            },
            err => {
              reject(err);
            },
          );
      });
  }

}
