<div class="list-container" *ngIf="loaded">

  <!-- Browse by category -->
  <div class="most-popular section-padding">
    <div class="container-fluid special-container">
      <div class="row">

        <aside class="col-xl-3 col-lg-4 col-md-5 mb-md-40 search-sidebar" [class.showFilter]="showFilter">
          <app-search-sidebar [categories]="categories"></app-search-sidebar>
        </aside>


        <div class="col-xl-9 col-lg-8 col-md-7 border-0" [class.filterShowing]="showFilter">
          <div class="px-3">
            <div class="row">
              <div class="col-12">

                <h5>Showing {{search.search.orderType}} <span *ngIf="search.search.orderType === 'Delivery'">to</span> <span *ngIf="search.search.orderType === 'Collection'">from</span> <span *ngIf="search.search.orderType !== 'Everything'">{{search.search.location}}</span></h5>

                <div class="sort-tag-filter">
                  <div class="restaurent-tags" *ngFor="let item of search.search.mustBe; let i = index;">
                  <span class="tags">{{item}}
                    <span class="close" (click)="removeFilter('MustHave', i)">x</span>
                  </span>
                  </div>
                  <div class="restaurent-tags" *ngFor="let item of search.search.categories; let x = index;">
                  <span class="tags">{{item.category_name}}
                    <span class="close" (click)="removeFilter('Category', x)">x</span>
                  </span>
                  </div>
                </div>

                <div *ngIf="!updating">

                  <div class="vendor-list" *ngIf="search.vendors.Open.length">

                    <div class="row">

                      <div class="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-6" *ngFor="let vendor of search.vendors.Open">
                        <div class="single-vendor-list" (click)="viewVendor(vendor.id)">

                          <div class="vendor-image" [style.backgroundImage]="'url('+ getBackgroundImageForVendor(vendor) +')'">
                          </div>

                          <div class="vendor-content">
<!--
                            <p class="rating excellent"><i class="fa fa-star"></i>No Ratings Yet</p>
-->
                            <p class="mb-0">{{vendor.trading_name}}</p>
                            <p class="categories">
                              <span *ngFor="let category of vendor.categories; let last = last">{{category.category_name}}<span *ngIf="!last">, </span> </span>
                            </p>

                          </div>

                        </div>
                      </div>

                    </div>

                  </div>


                  <div class="vendor-list" *ngIf="search.vendors.Offline.length">

                    <h6 class="mb-4">Coming Soon</h6>

                    <div class="row">
                      <div class="col-lg-4 col-xl-3 col-md-6 col-sm-6 col-6" *ngFor="let vendor of search.vendors.Offline">
                        <div class="single-vendor-list">

                          <div class="vendor-image"  [style.backgroundImage]="'url(assets/img/placeholder.png)'">

                          </div>

                          <div class="vendor-content">

                            <!--<p class="rating excellent"><i class="fa fa-star"></i>No Ratings Yet</p>-->
                            <p class="mb-0">{{vendor.trading_name}}</p>
                            <p class="categories">
                              <span *ngFor="let category of vendor.categories; let last = last">{{category.category_name}}<span *ngIf="!last">, </span> </span>
                            </p>


                          </div>


                        </div>
                      </div>

                    </div>

                  </div>

                  <div class="empty-state" *ngIf="!search.vendors.Open.length && !search.vendors.Offline.length">

                      <img src="assets/img/max-look-down.jpg" alt="Max">
                      <div class="text">
                        <h2 class="mb-0">Oh dear...</h2>
                        <p>Max couldn't find any stores matching your criteria, why not alter your search criteria and try again.</p>
                      </div>

                  </div>

                </div>


              </div>


            </div>
          </div>
        </div>

      </div>
    </div>
  </div>


</div>

<app-page-loader *ngIf="!loaded"></app-page-loader>

<div class="top-bar">

  <div class="container">

    <div class="row">

      <div class="col text-center">

        <button class="btn btn-primary" (click)="toggleFilter()" *ngIf="!showFilter">Amend / Filter your search</button>
        <button class="btn btn-primary" (click)="toggleFilter()" *ngIf="showFilter">Show search results</button>

      </div>

    </div>

  </div>

</div>
