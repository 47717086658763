import {Component, forwardRef, Inject, OnInit, Output, EventEmitter, Optional, ViewChild, ElementRef} from '@angular/core';
import {NgAisIndex, NgAisInstantSearch, TypedBaseWidget} from 'angular-instantsearch';
import connectAutocomplete, {AutocompleteConnectorParams, AutocompleteWidgetDescription} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete';
import {NewSearchService} from '../../services/NewSearch.service';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'search-auto-complete',
  templateUrl: './search-auto-complete.component.html',
  styleUrls: ['./search-auto-complete.component.scss']
})

export class SearchAutoCompleteComponent implements OnInit{

  @ViewChild('searchNtn') searchButton: ElementRef;
  currentTerm = '';

  public constructor(public elastic: NewSearchService, public router: Router, public route: ActivatedRoute) {
  }

  public handleChange($event: KeyboardEvent) {
    if ($event.key !== 'Enter'){
      this.currentTerm = ($event.target as HTMLInputElement).value;
      this.elastic.autocompleteSearchTerm.next(($event.target as HTMLInputElement).value);
    }
  }

  public ngOnInit() {

  }

  showState(data){

    console.log(data);

   }

  clearSearch(){

    let route;
    const url = this.route.snapshot.children[0].url[0];

    if (url){
      route = url.path;
    }else{
      route = 'products';
    }
    this.elastic.searchCriteria.term = '';
    this.currentTerm = '';
    this.elastic.updateSearch(false, route);

  }

  triggerSearch(data = null){

    this.searchButton.nativeElement.focus();

    let route;
    const url = this.route.snapshot.children[0].url[0];

    if (!url){
      route = 'products';
    }else{
      if (url.path === 'merchants'){
        route = 'merchants';
      }
    }

    if (data){

      route = 'products';

      if (data.data._meta.engine === environment.elasticMerchantEngine){
        route = 'merchants';
      }

      this.elastic.searchCriteria.term = data.data.name.raw;
    }else{
      if (this.currentTerm){
        this.elastic.searchCriteria.term = this.currentTerm;
      }
    }

    this.elastic.searchCriteria.categories = [];
    this.elastic.updateSearch(false, route, true);

  }

}
