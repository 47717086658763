<div class="offline-message" *ngIf="loaded && showOffline">

  <div class="container">

    <div class="row">
      <div class="col-12">

        <img src="assets/img/max.png" alt="Max" class="max-img">
        <h3>{{vendor.trading_name}} is currently offline.</h3>
        <p>They are currently not accepting orders at this time, please check back later.</p>

        <p>But don’t worry, we have plenty of other merchants available, click here to discover them!</p>

        <button class="btn btn-light" routerLink="/browse">Browse Stores</button>

      </div>
    </div>

  </div>

</div>

<div class="single-store" *ngIf="loaded && !showOffline">

  <div class="page-banner p-relative smoothscroll" id="menu" [style.backgroundImage]="'url('+getSingleBackgroundStore()+')'">
    <div class="overlay-2">

    </div>
  </div>

  <section class="restaurent-details">
    <div class="container large-container">
      <div class="row">
        <div class="col-lg-3">
          <div class="restaurent-logo" [style.backgroundImage]="'url('+getSingleLogoStore()+')'">

          </div>

          <div class="category-list d-lg-block d-none">

            <div class="single-category-item" [ngxScrollTo]="'#category-' + i" [ngxScrollToOffset]="-100" *ngFor="let category of categories; let i = index;">

              <h6>
                {{category.category_name}}
                <i class="fas fa-lg fa-chevron-right ml-auto text-primary"></i>
              </h6>

            </div>

          </div>

        </div>

        <div class="col-lg-6">

          <div class="heading padding-tb-10">
            <div class="vendor-content">
              <h3 class="text-light-black title fw-700 no-margin">{{vendor.trading_name}}</h3>
              <p class="text-light-black sub-title no-margin">{{vendor.address_1}} {{vendor.address_2}}
              </p>
              <!--<p class="rating excellent"><i class="fa fa-star"></i>No Ratings Yet</p>-->
            </div>
          </div>

          <p class="vendor-bio mt-2" [disabled]="!hidingBio" (hasTruncated)="updateTruncation($event)" [line-truncation]="3">{{vendor.biography}}</p>
          <button class="btn btn-primary btn-sm" *ngIf="showbioMore" (click)="hidingBio = !hidingBio"><span *ngIf="hidingBio">Show More</span><span *ngIf="!hidingBio">Show Less</span></button>

          <div class="store-message mt-4 mb-5" *ngIf="vendor.store_message">

            <div class="message-header d-flex">
              <img src="assets/img/warning.png">
              <h5 class="m-0">Important Message:</h5>
            </div>

            <div class="text">
              <p class="m-0">{{vendor.store_message}}</p>
            </div>

          </div>

          <div class="cart-icons d-block d-md-none" *ngIf="vendor.free_delivery_on">

            <div class="single-cart-icon">

              <img src="assets/icons/delivery.png" alt="Delivery">

              <p>Free Delivery Available on Orders over {{vendor.free_delivery_threshold | currency : 'GBP'}}</p>

            </div>

            <!--<div class="single-cart-icon">

              <img src="assets/icons/clock.png" alt="Opening Hours">

              <p *ngIf="vendor.isOpenToday.length">Premises open {{vendor.isOpenToday[0].open_from}} - {{vendor.isOpenToday[0].open_to}}</p>
              <p *ngIf="!vendor.isOpenToday.length">Premises closed today</p>

            </div>-->

          </div>

          <div class="category-menu d-block d-sm-none">

            <div class="container">
              <div class="row">

                <div class="col-12 p-0">

                  <nz-anchor [nzOffsetTop]="173" (nzScroll)="onScroll($event)">

                    <div class="links d-flex pr-2" style="overflow-x: auto">
                      <nz-link [nzHref]="'#category-' + i" *ngFor="let category of categories; let i = index;" [nzTitle]="category.category_name"></nz-link>
                    </div>

                  </nz-anchor>
                </div>


              </div>
            </div>

          </div>

          <div class="single-category" *ngFor="let category of categories; let i = index; let last = last;">

            <span [id]="'category-' + i" style="position: relative; top: -50px;"></span>

            <div class="category-content">

              <div class="category-header">
                <h4 class="category-title mb-1">{{category.category_name | titlecase}}</h4>
                <app-truncated-text-block [content]="category.category_description" [numberOfLines]="4" class="mt-3 category-description"></app-truncated-text-block>
              </div>

              <hr>

              <div class="result-grid row">

                <div [id]="'product-' + result.id" class="single-result col-lg-4 col-md-4 col-sm-6 col-12" (click)="viewProduct(result)" *ngFor="let result of category.active_products">

                  <div class="product-image" *ngIf="result.main_image.length" [style.background-image]="'url('+result.main_image[0].url+')'">

                  </div>

                  <div class="product-image" *ngIf="!result.main_image || !result.main_image.length" style="background-image: url('assets/img/placeholder.png')">

                  </div>

                  <div class="product-meta">

                    <div class="title-container">
                      <h6 class="product-title" [line-truncation]="2">
                        {{result.product_name}}
                      </h6>
                    </div>

                    <div class="price-block d-flex">

                      <h6 class="product-main-price" *ngIf="result.discount_price">{{result.discount_price | currency: 'GBP'}}</h6>
                      <h6 class="product-main-price" *ngIf="!result.discount_price">{{result.base_price | currency: 'GBP'}}</h6>
                      <h6 class="reduced-price" *ngIf="result.discount_price"><span class="strike">{{result.base_price | currency:'GBP'}}</span></h6>

                    </div>


                    <h6 class="text-danger delivery-text">
                      {{determineProductDeliveryText(result)}}
                    </h6>


                  </div>

                </div>
              </div>

            </div>

          </div>

        </div>

        <div class="col-lg-3 pb-md-4" id="stickySidebar">

          <div class="cart-icons" *ngIf="vendor.free_delivery_on">

            <div class="single-cart-icon">

              <img src="assets/icons/delivery.png" alt="Delivery">

              <p>Free Delivery Available on Orders over {{vendor.free_delivery_threshold | currency : 'GBP'}}</p>

            </div>

            <!--<div class="single-cart-icon">

              <img src="assets/icons/clock.png" alt="Opening Hours">

              <p *ngIf="vendor.isOpenToday.length">Premises open {{vendor.isOpenToday[0].open_from}} - {{vendor.isOpenToday[0].open_to}}</p>
              <p *ngIf="!vendor.isOpenToday.length">Premises closed today</p>

            </div>-->

          </div>



          <div class="sticky-container">


            <div class="inner-cart-container">
              <app-cart [vendor]="vendor"></app-cart>
            </div>

          </div>



          <div class="mobile-cart" [class.show-mobile-cart]="showMobileCart">
            <div class="inner-cart-container">
              <app-cart [vendor]="vendor"></app-cart>
            </div>
            <div class="cart-close-button" *ngIf="showMobileCart">

              <button class="btn btn-secondary btn-block" (click)="showMobileCart = false">Hide Cart</button>

            </div>
          </div>

        </div>

      </div>
    </div>
  </section>

</div>

<nz-modal nzClassName="single-product-modal" [nzTitle]="null" (nzOnCancel)="cancelView()" [nzFooter]="null" [nzVisible]="viewingProduct !== null" [nzOkText]="'Add To Cart'">
  <div *ngIf="viewingProduct" class="single-product-large">
    <div class="row">

      <div class="col-md-5 mb-3 mb-md-0" *ngIf="viewingProduct.main_image.length">

        <div>
          <swiper #swiper class="mb-3" [slidesPerView]="1" [spaceBetween]="0" [navigation]="true" (slideChange)="changeBottomSlide($event)" [pagination]="false" [scrollbar]="false">
            <ng-template *ngFor="let image of viewingProduct.main_image" swiperSlide>
              <div class="product-image cursor-pointer" [style.backgroundImage]="'url('+decodeURI(image.url)+')'"></div>
            </ng-template>
            <ng-template *ngFor="let image of viewingProduct.images" swiperSlide>
              <div class="product-image" [style.backgroundImage]="'url('+decodeURI(image.url)+')'"></div>
            </ng-template>
          </swiper>

          <swiper *ngIf="viewingProduct.images.length" #swiperBottom [slidesPerView]="3.5" [spaceBetween]="10" [pagination]="false" [scrollbar]="false">
            <ng-template *ngFor="let image of viewingProduct.main_image" swiperSlide>
              <div class="product-image" (click)="clickSlide(0)" [style.backgroundImage]="'url('+decodeURI(image.url)+')'"></div>
            </ng-template>
            <ng-template *ngFor="let image of viewingProduct.images; let i = index;" swiperSlide>
              <div class="product-image" (click)="clickSlide(i + 1)" [style.backgroundImage]="'url('+decodeURI(image.url)+')'"></div>
            </ng-template>
          </swiper>

        </div>

      </div>

      <div [class]="determineTextClass(viewingProduct)">

        <div class="pr-md-3">
          <div class="pl-md-4">

            <h2 class="single-title">{{getProductTitle() | titlecase}}</h2>
            <p>From: <span class="text-primary">{{vendor.trading_name}}</span></p>

            <hr class="mb-0">

            <div class="product-text-column">

              <div class="store-message my-3" *ngIf="vendor.store_message">

                <div class="message-header d-flex">
                  <img src="assets/img/warning.png">
                  <h5 class="m-0">Important Message:</h5>
                </div>

                <div class="text">
                  <p class="m-0">{{vendor.store_message}}</p>
                </div>

              </div>

              <div class="special-ribbon" *ngIf="cantAddMessage">
                {{cantAddMessage}}
              </div>

              <div class="special-ribbon" *ngIf="inventoryMessage">
                {{inventoryMessage}}
              </div>

              <p class="product-description" [disabled]="!hiding" [class.mb-0]="showMoreButton" [line-truncation]="3" (hasTruncated)="showMoreButton = $event">{{viewingProduct.product_description}}</p>
              <span *ngIf="showMoreButton" class="show-more-button" (click)="hiding = !hiding"><span *ngIf="hiding">...Show More</span><span *ngIf="!hiding">Hide</span></span>

              <div class="single-options mb-3" *ngIf="availableQuantity().length > 0">

                <p class="option-title"><strong>Quantity:</strong></p>

                <div class="single-value-select">
                  <nz-select [(ngModel)]="itemToAdd.quantity" nzPlaceHolder="Please select one">
                    <nz-option *ngFor="let n of availableQuantity()" nzValue="{{n}}" nzLabel="{{n}}">
                    </nz-option>

                  </nz-select>
                </div>

              </div>

              <div *ngIf="itemToAdd.options">

                <div class="single-options mb-3" *ngFor="let option of itemToAdd.options; let i = index;">

                  <p class="option-title"><strong>{{option.header}} <span *ngIf="option.type ==='Single'"><small>(Select One)</small></span></strong></p>

                  <div class="single-value-select" *ngIf="option.type === 'Single'">

                    <nz-select (ngModelChange)="updateBasketItemOption($event, i)" nzPlaceHolder="Please select one" [ngModel]="null" [nzCustomTemplate]="defaultTemplate">
                      <nz-option *ngFor="let item of option.options" nzCustomContent [nzValue]="item">
                        {{item.title}} <span *ngIf="item.additional_cost">+ ({{item.additional_cost | currency :'GBP'}})</span>
                        <span style="float:right" *ngIf="viewingProduct.track_inventory">{{optionStock(item)}}</span>
                      </nz-option>
                      <ng-template #defaultTemplate let-selected>
                        {{ selected.nzValue.title }} <span *ngIf="selected.nzValue.additional_cost">+ ({{selected.nzValue.additional_cost | currency :'GBP'}})</span>
                        <span *ngIf="optionOutOfStock(selected.nzValue)" class="text-danger" style="margin-left:10px;">Out of stock</span>
                      </ng-template>
                    </nz-select>

                  </div>

                  <div class="single-value-select" *ngIf="option.type === 'Multi'">

                    <nz-select nzMode="multiple" [nzShowSearch]="false" (ngModelChange)="updateProductOptions($event, option, i)" nzPlaceHolder="Please select at least one" [ngModel]="null" [nzCustomTemplate]="defaultTemplate2">
                      <nz-option *ngFor="let item of option.options" [nzValue]="item" nzCustomContent>
                        {{item.title}} <span *ngIf="item.additional_cost">+ ({{item.additional_cost | currency :'GBP'}})</span>
                        <span style="float:right" *ngIf="viewingProduct.track_inventory">{{optionStock(item)}}</span>
                      </nz-option>
                    </nz-select>
                    <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected
                    </ng-template>

                    <ng-template #defaultTemplate2 let-selected>
                      {{ selected.nzValue.title }} <span *ngIf="selected.nzValue.additional_cost">({{selected.nzValue.additional_cost | currency :'GBP'}})</span>
                    </ng-template>

                    <!--<div class="form-group" *ngFor="let optionValue of option.options; let x = index;">
                      <label nz-checkbox (ngModelChange)="updateBasketItemOptionCheckbox($event, i, x)" [ngModel]="null">{{optionValue.title}} <strong *ngIf="optionValue.additional_cost">+ {{optionValue.additional_cost | currency: 'GBP'}}</strong> </label>
                    </div>-->

                  </div>

                </div>
              </div>

            </div>

            <hr>

            <p *ngIf="showStock()">{{viewingProduct.stock}} in stock.</p>

            <button class="add-cart-button" [class.disabled-button]="!validateProductOptions() || cantAddMessage || itemToAdd.quantity < 1" [disabled]="checkCanAdd()" (click)="addToCart()">
              <span *ngIf="validateProductOptions()">Add For {{itemToAdd.cost_per_item * itemToAdd.quantity | currency: 'GBP'}}</span>
              <span *ngIf="!validateProductOptions()">Select required options</span>
            </button>

          </div>
        </div>

      </div>

    </div>

  </div>

</nz-modal>

<div class="mobile-cart-fab" (click)="showMobileCart = true">
  <img src="assets/img/shopping-cart.png" alt="Your Cart">
  <span class="badge badge-dark" *ngIf="cart.cart">{{cart.itemsInBasket()}}</span>
  <span class="badge badge-dark" *ngIf="!cart.cart">0</span>
</div>