import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-voucher-purchased',
  templateUrl: './voucher-purchased.component.html',
  styleUrls: ['./voucher-purchased.component.scss']
})
export class VoucherPurchasedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
