<div class="filter-sidebar mb-5 p-2">

  <div class="row sort">

    <div class="col-sm-12">

      <h6>Order Type</h6>
    <nz-radio-group nzSize="large" [ngModel]="searchService.search.orderType" (ngModelChange)="changeOrderType($event)">
      <label nz-radio [nzValue]="'Everything'">Everything</label>
      <label nz-radio [nzValue]="'Delivery'">Delivery</label>
      <label nz-radio [nzValue]="'Collection'">Collection</label>
    </nz-radio-group>
    </div>

  </div>

  <div class="row location mt-4" *ngIf="searchService.search.orderType !== 'Everything'">
    <div class="col-sm-12">
      <div class="form-group mb-0">
        <label>Your parish</label>
        <select class="form-control mb-0" [(ngModel)]="searchService.search.location" (ngModelChange)="searchService.update()">
          <option value="Everywhere" *ngIf="searchService.search.orderType === 'Collection'">Everywhere</option>
          <option value="St Helier">St Helier</option>
          <option value="Grouville">Grouville</option>
          <option value="St Brelade">St Brelade</option>
          <option value="St Clement">St Clement</option>
          <option value="St John">St John</option>
          <option value="St Lawrence">St Lawrence</option>
          <option value="St Martin">St Martin</option>
          <option value="St Mary">St Mary</option>
          <option value="St Ouen">St Ouen</option>
          <option value="St Peter">St Peter</option>
          <option value="St Saviour">St Saviour</option>
          <option value="Trinity">Trinity</option>
        </select>
      </div>
    </div>
  </div>

  <hr class="my-4">

  <div class="scroller">

    <div class="row sort">
      <div class="col-sm-12">

        <h6>Sort By</h6>

        <nz-radio-group nzSize="large">
          <label nz-radio nzValue="viral">Recommended</label>
          <label nz-radio nzValue="rating">Rating</label>
          <label nz-radio nzValue="time">Delivery Time</label>
        </nz-radio-group>

      </div>
    </div>

    <hr class="my-4">

    <div class="row categories">
      <div class="col-sm-12">

        <h6 class="mb-3">Must have</h6>

        <div class="form-group">
          <label [nzChecked]="isMust('Under 30 Min')" nz-checkbox (nzCheckedChange)="checkMust('Under 30 Min', $event)">Within 30 Min</label>
        </div>
        <div class="form-group">
          <label [nzChecked]="isMust('Under 60 Min')" nz-checkbox (nzCheckedChange)="checkMust('Under 60 Min', $event)">Within 60 Min</label>
        </div>

      </div>
    </div>


    <hr class="my-4">
    <div class="row categories">
      <div class="col-sm-12 mb-4">

        <h6 class="mb-3">Order Food:</h6>

        <div class="form-group" *ngFor="let item of categories">
          <label *ngIf="item.category_type === 'Order Food'" [nzChecked]="isChecked(item)" (nzCheckedChange)="checkCategory(item, $event)" nz-checkbox [nzValue]="item.id">{{item.category_name}}</label>
        </div>

      </div>
      <div class="col-sm-12">

        <h6 class="mb-3">Shopping:</h6>

        <div class="form-group" *ngFor="let item of categories">
          <label *ngIf="item.category_type === 'Shopping'" [nzChecked]="isChecked(item)" (nzCheckedChange)="checkCategory(item, $event)" nz-checkbox [nzValue]="item.id">{{item.category_name}}</label>
        </div>

      </div>

    </div>

  </div>

</div>
