import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/Auth.service';
import {ApiService} from '../../services/Api.service';
import {Customer} from '../../models/Customer';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzNotificationService} from 'ng-zorro-antd/notification';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  customer: Customer;
  selectedTab = 1;
  customerForm: FormGroup;
  loaded = false;

  lostVoucherEmail = '';
  errorMessage: boolean = null;
  successMessage: boolean = null;
  invalidEmail: boolean = null;

  deleteMe:  string = '';

  constructor(public auth: AuthService, public api: ApiService, public fb: FormBuilder, public notification: NzNotificationService) {
    this.customerForm = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      phone_number: ['', Validators.required]
    });

  }

  ngOnInit(): void {

    this.getEntireCustomerData();

  }

  getEntireCustomerData(){

    this.api.apiGet('core/customer').then((res) => {
      this.customer = res;

      this.customerForm = this.fb.group({
        first_name: [this.customer.first_name, Validators.required],
        last_name: [this.customer.last_name, Validators.required],
        phone_number: [this.customer.phone_number, Validators.required]
      });

      this.loaded = true;

    });

  }

  removeAddress(id){

    this.api.apiPost('core/customer/addresses/' + id + '/delete', {}).then((res) => {
        this.getEntireCustomerData();
        this.notification.success('Success', 'Your address has been removed');
    });

  }

  removePaymentMethod(id){

    this.api.apiPost('core/customer/savedCards/delete', {card: id}).then((res) => {
      this.customer.payment_methods = res;
      this.notification.success('Success', 'Your card has been removed');
    });

  }

  updateCustomerData(){

    if(this.customerForm.valid){
      this.api.apiPost('core/customer', this.customerForm.value).then((res) => {
        this.notification.success('Success', 'Your account has been updated');
      });
    }

  }

  ValidateEmail()
  {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.lostVoucherEmail);
  }

  submitLostVoucherForm(){

    if (this.ValidateEmail()){

      this.api.apiPost('public/lost-vouchers', {email: this.lostVoucherEmail}).then((LostVoucherResult) => {
        this.invalidEmail = false;
        this.errorMessage = false;
        this.successMessage = true;
        this.lostVoucherEmail = '';
      }).catch((LostVoucherError) => {
        this.invalidEmail = false;
        this.successMessage = false;
        this.errorMessage = true;
      });

    }else{
      this.invalidEmail = true;
      this.lostVoucherEmail = '';
    }

  }

  submitDelete(){
      this.api.apiPost('core/customer/delete', null).then((res) => {
        //console.log(res);
        this.auth.logOut();
      }).catch((err) => {
        // this.invalidEmail = false;
        // this.successMessage = false;
        // this.errorMessage = true;
      });

  }

}
