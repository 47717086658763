import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/Auth.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FacebookLoginProvider, SocialAuthService} from 'angularx-social-login';

@Component({
  selector: 'app-login-special',
  templateUrl: './login-special.component.html',
  styleUrls: ['./login-special.component.scss']
})
export class LoginSpecialComponent implements OnInit {

  loginForm: FormGroup;
  error = '';
  token: string;

  constructor(public auth: AuthService, public fb: FormBuilder, public router: Router, private route: ActivatedRoute, public socialLogin: SocialAuthService) {

  }

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParams.access_token;

    if(this.token){
      this.auth.doLoginVerify({access_token: this.token}).then((res) => {
        if(res.user.vendorID){
          this.error = 'You cannot log in with a merchant account';
        }else{
          this.auth.desktopAuthStore(res).then((res2) => {
            this.router.navigateByUrl('/');
          });
        }
      });
    }

  }


}
