import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ApiService } from '../../services/Api.service';
import { CustomerAddress } from '../../models/CustomerAddress';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-checkout-address',
  templateUrl: './checkout-address.component.html',
  styleUrls: ['./checkout-address.component.scss']
})
export class CheckoutAddressComponent implements OnInit {

  @Input() gift: boolean;
  @Input() collection: boolean;
  @Input() address: CustomerAddress;
  @Input() addresses: CustomerAddress[];
  @Output() addressSelected = new EventEmitter<CustomerAddress>();
  @Output() giftAddressSelected = new EventEmitter<CustomerAddress>();

  addressForm: FormGroup;
  submitted = false;

  constructor(public api: ApiService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.addressForm = this.formBuilder.group({
      recipient: ['', Validators.required],
      address1: ['', Validators.required],
      address2: ['', Validators.required],
      town_parish: ['', Validators.required],
      postcode: ['', Validators.required],
      telephone: ['', Validators.required],
      instructions: [''],
      what3: ['']
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.addressForm.controls; }

  selectAddress() {
    this.addressSelected.emit(this.address);
  }

  saveAddress() {
    this.submitted = true;
    if (this.addressForm.invalid) {
      this.submitted = false;
      return;
    }

    let address = new CustomerAddress();
    address.recipient = this.addressForm.value.recipient;
    address.address_1 = this.addressForm.value.address1;
    address.address_2 = this.addressForm.value.address2;
    address.town_parish = this.addressForm.value.town_parish;
    address.post_code = this.addressForm.value.postcode;
    address.telephone = this.addressForm.value.telephone;
    address.instructions = this.addressForm.value.instructions;
    address.what3 = this.addressForm.value.what3;

    this.api.saveAddress(address).then((res: CustomerAddress) => {
      this.submitted = false
      this.addresses.push(res);
      if (this.gift) {
        this.giftAddressSelected.emit(res);
      }
      else {
        this.addressSelected.emit(res);
      }
    }).catch((err) => {
      this.submitted = false
    });
  }

  willDeliver(area: string) {
    // TODO is this required?
    return true;
  }

}