import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {CartVoucherService} from '../../services/CartVoucher';
import {GiftCartItem} from '../../models/GiftCartItem';
import {VoucherCart} from '../../models/VoucherCart';
import {ApiService} from '../../services/Api.service';

@Component({
  selector: 'app-bulk-vouchers',
  templateUrl: './bulk-vouchers.component.html',
  styleUrls: ['./bulk-vouchers.component.scss']
})
export class BulkVouchersComponent implements OnInit {

  constructor(public router: Router, public cart: CartVoucherService, public api: ApiService) { }

  voucherAmount: number;
  business_quantity: number;
  voucherType: string;
  recipient_name: string;
  lostVoucherEmail = '';
  recipient_email: string;
  invalidEmail: boolean = null;
  is_business: boolean = null;
  errorMessage: boolean = null;
  successMessage: boolean = null;
  message: string;

  ngOnInit(): void {
  }

  selectVoucher(type, amount: number = 0){
    this.voucherType = type;
    this.voucherAmount = amount;
  }

  setBusiness(value: boolean){
    this.is_business = value;
  }

  addVoucherToCart(){

    if (!this.is_business && this.recipient_name && this.recipient_email){
      const voucherPurchase = new GiftCartItem();
      voucherPurchase.voucher_amount = this.voucherAmount;
      voucherPurchase.recipient_name = this.recipient_name;
      voucherPurchase.recipient_email = this.recipient_email;
      voucherPurchase.is_business = this.is_business;
      voucherPurchase.business_quantity = this.business_quantity;
      voucherPurchase.message = this.message;

      if (this.cart.cart){
        this.cart.addToCart(voucherPurchase).then((AddVoucherResult: VoucherCart) => {
          this.cart.cart = AddVoucherResult;
        });
      }else{
        this.cart.newCart(voucherPurchase).then((AddVoucherResult: VoucherCart) => {
          this.cart.cart = AddVoucherResult;
          this.cart.cartId = AddVoucherResult.unique_basket_id;
          sessionStorage.setItem('fetch-voucher-cart', AddVoucherResult.unique_basket_id);
        });
      }

      this.recipient_email = '';
      this.recipient_name = '';
      this.business_quantity = null;

    }else{
      const voucherPurchase = new GiftCartItem();
      voucherPurchase.voucher_amount = this.voucherAmount;
      voucherPurchase.is_business = this.is_business;
      voucherPurchase.business_quantity = this.business_quantity;
      if (this.cart.cart){
        this.cart.addToCart(voucherPurchase).then((AddVoucherResult: VoucherCart) => {
          this.cart.cart = AddVoucherResult;
        });
      }else{
        this.cart.newCart(voucherPurchase).then((AddVoucherResult: VoucherCart) => {
          this.cart.cart = AddVoucherResult;
          this.cart.cartId = AddVoucherResult.unique_basket_id;
          sessionStorage.setItem('fetch-voucher-cart', AddVoucherResult.unique_basket_id);
        });
      }

      this.recipient_email = '';
      this.recipient_name = '';
      this.business_quantity = null;
    }



  }

  ValidateEmail()
  {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.lostVoucherEmail);
  }

  submitLostVoucherForm(){

    if (this.ValidateEmail()){

      this.api.apiPost('public/lost-vouchers', {email: this.lostVoucherEmail}).then((LostVoucherResult) => {
        this.invalidEmail = false;
        this.errorMessage = false;
        this.successMessage = true;
        this.lostVoucherEmail = '';
      }).catch((LostVoucherError) => {
        this.invalidEmail = false;
        this.successMessage = false;
        this.errorMessage = true;
      });

    }else{
      this.invalidEmail = true;
      this.lostVoucherEmail = '';
    }

  }


  isBusiness(){

    if (!this.cart.cart){
      return this.is_business === true;
    }else{
      return this.cart.cart.is_business === true;
    }

  }

}
