import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/Auth.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FacebookLoginProvider, SocialAuthService} from 'angularx-social-login';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  forgotForm: FormGroup;
  checkForm: FormGroup;
  changeForm: FormGroup;
  loading = false;
  view = 1;
  returnUrl;
  error;

  constructor(public auth: AuthService, public fb: FormBuilder, public router: Router, private route: ActivatedRoute, public socialLogin: SocialAuthService) {

    this.forgotForm = this.fb.group({
      email: ['', Validators.required]
    });

    this.checkForm = this.fb.group({
      email: ['', Validators.required],
      code: ['', Validators.required]
    });

    this.changeForm = this.fb.group({
      email: ['', Validators.required],
      code: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    });

  }


  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl;
  }

  async doReset(){

    if (this.forgotForm.valid){
      this.loading = true;
      this.error = '';
      this.auth.forgotRequest(this.forgotForm.value).then((res) => {
        this.checkForm.controls.email.setValue(this.forgotForm.value.email);
        this.view = 2;
        this.loading = false;

      }).catch(async (err) => {
        console.log(err);
        this.loading = false;
        this.error = 'Your e-mail address cannot be found. You can register with this e-mail.';

      });
    }else{

    this.error = 'Please complete all the fields';

    }

  }

  async doCheck(){

    if (this.checkForm.valid){
      this.loading = true;
      this.error = '';
      this.auth.forgotCheck(this.checkForm.value).then((res) => {
        this.changeForm.controls.email.setValue(this.checkForm.value.email);
        this.changeForm.controls.code.setValue(this.checkForm.value.code);

        this.view = 3;
        this.loading = false;

      }).catch(async (err) => {
        console.log(err);

        this.loading = false;
        this.error = 'Your code is invalid, please try again.';


      });
    }else{
      this.error = 'Please complete all the fields';
    }

  }

  async doChange(){

    if (this.changeForm.valid){
      this.loading = true;
      this.error = '';
      this.auth.forgotChange(this.changeForm.value).then((res) => {

        this.view = 4;
        this.loading = false;

      }).catch(async (err) => {
        console.log(err);
        this.loading = false;
        this.error = 'Your password isn\'t strong enough.';

      });
    }else{
      this.error = 'Please complete all the fields';
    }

  }


  goToLogin(){

    this.router.navigate(['login'], {queryParams: {returnUrl: this.returnUrl}});

  }

}
