import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-jp-fulfilment',
  templateUrl: './jp-fulfilment.component.html',
  styleUrls: ['./jp-fulfilment.component.scss']
})
export class JpFulfilmentComponent implements OnInit {

  showImage: boolean;

  constructor() { }

  ngOnInit(): void {
  }

}
