<div class="list-container">

  <!-- Browse by category -->
  <div class="most-popular section-padding">
    <div class="container large-container">

      <div class="row title-row">
        <div class="col-12">

          <div class="d-block d-md-none">

            <div class="d-flex align-items-center" (click)="showMobileFilter = true">

              <h5 class="mb-0 fw-700">Filter & Sort</h5>

              <i class="fas fa-chevron-down text-primary ml-auto"></i>

            </div>

            <hr>

          </div>

          <div class="d-flex justify-content-between align-items-md-center pb-3 pb-md-5 flex-column flex-md-row">

            <h5 class="mb-2 fw-700">Product Results</h5>

            <div class="switcher d-flex ml-auto mr-auto">

              <div class="option-button" (click)="switchToMerchantSearch()">
                Retailers
              </div>
              <div class="option-button active">
                Products
              </div>

            </div>

            <div class="sorter align-items-center d-none d-md-flex">

              <p class="mb-0 mr-3"><small>Sort by:</small></p>

              <select (ngModelChange)="adjustSort($event)" class="form-control w-auto sorter-box" [ngModel]="search.searchCriteria.sort">
                <option value="relevance">Relevance</option>
                <option value="popular">Popular</option>
                <option value="new">Date Added</option>
                <option value="z-a">By Name (desc)</option>
                <option value="a-z">By Name (asc)</option>
                <option value="price-d">Price (desc)</option>
                <option value="price-a">Price (asc)</option>
              </select>

            </div>

          </div>

        </div>
      </div>

      <div class="d-flex">

        <div class="filter d-none d-md-block" *ngIf="search.categorySections">

          <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>

        </div>

        <div class="results empty-results pl-4" *ngIf="productResults && !productResults.length">

            <h1>We couldn't find any matching products.</h1>

            <h6>Please try another search or <a class="text-primary" (click)="search.clearFilters('products')">clear all filters</a></h6>

        </div>

        <div class="results pl-md-4" *ngIf="productResults && productResults.length">

          <div class="result-grid row">

              <div class="single-result col-lg-3 col-md-4 col-sm-6" (click)="navigateToProduct(result)" *ngFor="let result of productResults">

                <div class="product-image" *ngIf="result.product_image.raw" [style.background-image]="'url('+result.product_image.raw+')'">

                </div>

                <div class="product-image" *ngIf="!result.product_image.raw" style="background-image: url('assets/img/placeholder-dark.jpg')">

                </div>

                <div class="product-meta">

                  <div class="title-container">
                    <h6 class="product-title" [line-truncation]="2">
                      {{result.name.raw}}
                    </h6>
                  </div>

                  <h6 class="vendor-title">
                    {{result.vendor.raw}}
                  </h6>

                  <div class="price-block d-flex">

                    <h6 class="product-main-price">{{result.price.raw | currency: 'GBP'}}</h6>
                    <h6 class="reduced-price" *ngIf="result.full_price.raw !== result.price.raw"><span class="strike">{{result.full_price.raw | currency:'GBP'}}</span></h6>

                  </div>

                  <h6 class="text-danger delivery-text">
                    <span *ngIf="result.delivery_available.raw === 'true' && result.collection_available.raw !== 'true'">Delivery Available</span>
                    <span *ngIf="result.delivery_available.raw !== 'true' && result.collection_available.raw === 'true'">Collection Available</span>
                    <span *ngIf="result.delivery_available.raw === 'true' && result.collection_available.raw === 'true'">Delivery & Collection Available</span>
                  </h6>


                </div>

              </div>

          </div>

          <div class="pagination d-flex justify-content-center">

            <nz-pagination *ngIf="meta" (nzPageIndexChange)="changePage($event)" [nzPageSize]="meta.page.size" [nzPageIndex]="meta.page.current" [nzTotal]="getTotalResultsFake(meta.page.total_results, meta.page.size)"></nz-pagination>

          </div>

        </div>

      </div>


    </div>
  </div>

</div>


<ng-template #filterTemplate>
  <p class="coustard mb-3">Filter by</p>

  <div class="filter-section">

    <div class="form-group">
      <div class="form-group"><label (nzCheckedChange)="checkBox('special_offer', $event)" [nzChecked]="search.searchCriteria.special_offer === 1" nz-checkbox>Special Offer - Sale Item</label></div>
    </div>

    <div class="form-group"><label (nzCheckedChange)="checkBox('delivery', $event)" [nzChecked]="search.searchCriteria.delivery === 1" nz-checkbox>Delivery Available</label></div>
    <div class="form-group"><label (nzCheckedChange)="checkBox('collection', $event)" [nzChecked]="search.searchCriteria.collection === 1" nz-checkbox>Collection Available</label></div>
    <div class="form-group"><label (nzCheckedChange)="checkBox('genuine_jersey', $event)" [nzChecked]="search.searchCriteria.genuine_jersey === 1"  nz-checkbox>Genuine Jersey</label></div>

  </div>

  <p class="coustard mb-2 mt-3">Product Categories</p>

  <accordion [isAnimated]="true">
    <accordion-group *ngFor="let section of search.categorySections" [isOpen]="isOpen(section)">
      <div class="d-flex align-items-center" accordion-heading><h6 class="mb-0">{{section.section_name}}</h6> <i class="fas fa-lg fa-chevron-right ml-auto"></i></div>
      <div class="form-group" *ngFor="let item of section.categories">
        <label [nzChecked]="isChecked(item.category_name)" (nzCheckedChange)="checkCategory(item.category_name, $event)" nz-checkbox [nzValue]="item.category_name">{{item.category_name}}</label>
      </div>
    </accordion-group>
  </accordion>
</ng-template>

<div class="custom-filter-container slide-in-blurred-bottom" *ngIf="showMobileFilter">

 <div class="custom-filter-scroll-container">
   <div class="d-flex align-items-center mb-2">

     <h5 class="main-header-filter m-0">Filter & Sort</h5>

     <button class="btn btn-primary btn-sm px-2 ml-auto" (click)="showMobileFilter = false"><i class="fa fa-close"></i></button>

   </div>

   <div class="sorter align-items-center">

     <p class="coustard mb-2 mt-3">Sort by:</p>

     <select (ngModelChange)="adjustSort($event)" class="form-control w-100 sorter-box" [ngModel]="search.searchCriteria.sort">
       <option value="relevance">Relevance</option>
       <option value="popular">Popular</option>
       <option value="new">Date Added</option>
       <option value="z-a">By Name (desc)</option>
       <option value="a-z">By Name (asc)</option>
       <option value="price-d">Price (desc)</option>
       <option value="price-a">Price (asc)</option>
     </select>

   </div>

   <hr>

   <ng-container *ngTemplateOutlet="filterTemplate">

   </ng-container>
 </div>

  <div class="filter-container-footer-buttons d-flex">

    <button class="btn btn-light btn-lg" (click)="clearFilters()">Clear Filters</button>
    <button class="btn btn-primary btn-lg ml-auto" (click)="showMobileFilter = false">Apply</button>

  </div>

</div>
