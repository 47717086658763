import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/Api.service';
import {Search} from '../../models/Search';
import {SearchService} from '../../services/Search.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-listview',
  templateUrl: './listview.component.html',
  styleUrls: ['./listview.component.scss']
})
export class ListviewComponent implements OnInit {

  constructor(public api: ApiService, public search: SearchService, public router: Router) {

  }

  loaded = false;
  updating = true;
  showFilter = false;
  categories = [];

  ngOnInit(): void {

    this.api.apiGet('public/categories').then((res) => {
      this.loaded = true;
      this.categories = res;

      this.search.searchObservable().subscribe((data) => {
          console.log('Updating results');
          this.updateResults();
      });

    });

  }

  toggleFilter(){

    this.showFilter = !this.showFilter;

  }

  updateResults(){
    this.updating = true;
    this.api.apiPost('public/search', this.search.search).then((res2) => {
      this.updating = false;
      this.search.vendors = res2;
    });
  }

  getBackgroundImageForVendor(vendor){

    if (vendor.vendor_image){
      return encodeURI(vendor.vendor_image);
    }else{
      return 'assets/img/placeholder.png';
    }

  }

  removeFilter(type, index){

    if (type === 'Category'){
      this.search.search.categories.splice(index, 1);
    }else{
      this.search.search.mustBe.splice(index, 1);
    }

    this.search.update();

  }

  viewVendor(id){

    this.router.navigateByUrl('/browse/' + id);

  }

}
