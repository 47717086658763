<div class="list-container">

  <!-- Browse by category -->
  <div class="most-popular section-padding">
    <div class="container large-container">

      <div class="row title-row">
        <div class="col-12">

          <div class="d-block d-md-none">

            <div class="d-flex align-items-center" (click)="showMobileFilter = true">

              <h5 class="mb-0 fw-700">Filter & Sort</h5>

              <i class="fas fa-chevron-down text-primary ml-auto"></i>

            </div>

            <hr>

          </div>

          <div class="d-flex justify-content-between align-items-md-center pb-3 pb-md-5 flex-column flex-md-row">

            <h5 class="mb-2 fw-700">Merchant Results</h5>

            <div class="switcher d-flex ml-auto mr-auto">

              <div class="option-button active">
                Retailers
              </div>
              <div class="option-button" (click)="switchToProductSearch()">
                Products
              </div>

            </div>

            <div class="sorter align-items-center d-none d-lg-flex">

              <p class="mb-0 mr-3"><small>Sort by:</small></p>

              <select (ngModelChange)="adjustSort($event)" [ngModel]="search.searchCriteria.sort" class="form-control w-auto sorter-box">
                <option value="relevance">Default</option>
                <option value="new">Date Added</option>
                <option value="z-a">By Name (desc)</option>
                <option value="a-z">By Name (asc)</option>
              </select>

            </div>

          </div>

        </div>
      </div>

      <div class="d-flex">

        <div class="filter d-none d-md-block" *ngIf="search.categorySections">

          <ng-container *ngTemplateOutlet="filterTemplate"></ng-container>

        </div>

        <div class="results empty-results pl-4" *ngIf="vendorResults && !vendorResults.length">

          <h1>We couldn't find any matching merchants.</h1>

          <h6>Please try another search or <a class="text-primary" (click)="search.clearFilters('merchants')">clear all filters</a></h6>

        </div>


        <div class="results pl-md-4" *ngIf="vendorResults && vendorResults.length">

          <div class="result-grid row">

            <div class="col-lg-4 col-sm-6" *ngFor="let result of vendorResults" (click)="goToMerchant(result)">

              <div class="single-result">
                <div class="vendor-image" *ngIf="result.grid_image.raw" [style.background-image]="'url('+result.grid_image.raw+')'">

                </div>

                <div class="vendor-image" *ngIf="!result.grid_image.raw" style="background-image: url('assets/img/placeholder-dark.jpg')">

                </div>

                <div class="merchant-meta">

                  <div class="title-container">
                    <h6 class="product-title" [line-truncation]="2">
                      {{result.name.raw}}
                    </h6>
                  </div>

                  <h5 class="vendor-address" [line-truncation]="2">
                    {{result.address.raw}}
                  </h5>

                  <p class="category-list mb-1">
                    <span class="single-category-item" *ngFor="let category of result.categories.raw; let i = index; let l = last;">
                        {{category}}<span *ngIf="!l">, </span>
                    </span>
                  </p>

                  <h6 class="text-danger delivery-text mt-0">
                    <span *ngIf="result.delivery.raw && !result.collection.raw">Delivery Available</span>
                    <span *ngIf="!result.delivery.raw && result.collection.raw">Collection Available</span>
                    <span *ngIf="result.delivery.raw && result.collection.raw">Delivery & Collection Available</span>
                  </h6>

                </div>
              </div>

            </div>

          </div>

          <div class="pagination d-flex justify-content-center">

            <nz-pagination *ngIf="meta" (nzPageIndexChange)="changePage($event)" [nzPageSize]="meta.page.size" [nzPageIndex]="meta.page.current" [nzTotal]="meta.page.total_results"></nz-pagination>

          </div>

        </div>

      </div>


    </div>
  </div>


</div>

<ng-template #filterTemplate>
  <p class="coustard mb-3">Filter by</p>

  <div class="filter-section">
    <div class="form-group"><label (nzCheckedChange)="checkBox('delivery', $event)" [nzChecked]="search.searchCriteria.delivery === 1" nz-checkbox>Delivery Available</label></div>
    <div class="form-group"><label (nzCheckedChange)="checkBox('collection', $event)" [nzChecked]="search.searchCriteria.collection === 1" nz-checkbox>Collection Available</label></div>
    <div class="form-group"><label (nzCheckedChange)="checkBox('genuine_jersey', $event)" [nzChecked]="search.searchCriteria.genuine_jersey === 1"  nz-checkbox>Genuine Jersey</label></div>

  </div>

  <p class="coustard mb-2 mt-3">Product Categories</p>

  <accordion [isAnimated]="true">
    <accordion-group *ngFor="let section of search.categorySections" [isOpen]="isOpen(section)">
      <div class="d-flex align-items-center" accordion-heading><h6 class="mb-0">{{section.section_name}}</h6> <i class="fas fa-lg fa-chevron-right ml-auto"></i></div>
      <div class="form-group" *ngFor="let item of section.categories">
        <label [nzChecked]="isChecked(item.category_name)" (nzCheckedChange)="checkCategory(item.category_name, $event)" nz-checkbox [nzValue]="item.category_name">{{item.category_name}}</label>
      </div>
    </accordion-group>
  </accordion>
</ng-template>

<div class="custom-filter-container slide-in-blurred-bottom" *ngIf="showMobileFilter">

  <div class="custom-filter-scroll-container">
  <div class="d-flex align-items-center mb-2">

    <h5 class="main-header-filter m-0">Filter & Sort</h5>

    <button class="btn btn-primary btn-sm px-2 ml-auto" (click)="showMobileFilter = false"><i class="fa fa-close"></i></button>

  </div>

  <div class="sorter align-items-center">

    <p class="coustard mb-2 mt-3">Sort by:</p>

    <select (ngModelChange)="adjustSort($event)" [ngModel]="search.searchCriteria.sort" class="form-control w-100 sorter-box">
      <option value="relevance">Default</option>
      <option value="new">Date Added</option>
      <option value="z-a">By Name (desc)</option>
      <option value="a-z">By Name (asc)</option>
    </select>

  </div>

  <hr>

  <ng-container *ngTemplateOutlet="filterTemplate">

  </ng-container>
  </div>
  <div class="filter-container-footer-buttons d-flex">

    <button class="btn btn-light btn-lg" (click)="clearFilters()">Clear Filters</button>
    <button class="btn btn-primary btn-lg ml-auto" (click)="showMobileFilter = false">Apply</button>

  </div>

</div>
