<div class="inner-wrapper">
    <div class="container-fluid no-padding">
      <div class="row no-gutters overflow-auto">
        <div class="col-md-6">
          <div class="main-banner dogBg">
          </div>
        </div>
        <div class="col-md-6">
          <div class="section-2 user-page main-padding">
            <div class="login-sec">
              <div class="login-box">
                <form [formGroup]="activateForm" (submit)="submitForm()">
                  <h4 class="text-light-black fw-600">Activate your account</h4>
                  <p>Thanks for registering, we have sent a 6 digit code to the e-mail address you have provided. Please enter it below.</p>
                  <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <div class="form-group">
                        <label class="text-light-white fs-14">6 Digit Code</label>
                        <input type="text" formControlName="code" class="form-control form-control-submit" placeholder="" required>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="form-group">
                        <button type="submit" class="btn-second btn-submit full-width">Activate your account</button>
                      </div>

                      <div class="form-group text-center">
                        <p class="text-light-black mb-0">Didn't receive it? <a (click)="reSendCode()">Re-send Code</a>
                        </p>

                        <p class="text-primary" *ngIf="resent">Code Re-sent!</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
