<div class="checkout-header">

  <div class="container">

    <div class="row">

      <div class="col-12">
        <div *ngIf="loaded">
          <h3 class="mb-2 text-white">Purchase Gift Card</h3>
        </div>

      </div>

    </div>

  </div>

</div>

<div class="checkout-main bg-light">

  <div class="custom-shape-divider-top-1606758194">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M1200 0L0 0 598.97 114.72 1200 0z" class="shape-fill"></path>
    </svg>
  </div>


  <div *ngIf="loaded" class="cart-main-loaded">


    <div class="container" *ngIf="orderError">
      <div class="row">
        <div class="col-sm-12">
          <div class="checkout-card">

              <h3>We are very sorry...</h3>
            <p>There seems to have been an error processing your order. This could potentially be due to loss of your internet connection or another reason.</p><p> Your order may have not been processed or may have not been charged.</p>

            <p><strong>Please contact us quoting the order number below before resubmitting payment.</strong></p>

          </div>
        </div>
      </div>
    </div>

    <div class="container" *ngIf="!orderError">
      <div class="row">

        <div class="col-lg-7">

          <div class="checkout-card">

            <div class="lockFormCover" *ngIf="paymentProcessing">

              <div class="form-content">
                <img src="assets/img/tail-spin.svg" alt="">
                <h5 class="text-white mt-3">
                  Payment processing...
                </h5>
                <p class="text-white">Please don't close or refresh this window.</p>
              </div>

            </div>

            <div class="delivery-section">

              <app-voucher-delivery></app-voucher-delivery>

            </div>

            <hr>

            <div class="delivery-section">

              <h4>Payment</h4>

              <app-voucher-payments (paymentComplete)="paymentSuccess($event)" (paymentError)="paymentError($event)" (paymentInProcess)="paymentInProgress()"></app-voucher-payments>

            </div>

          </div>

        </div>

        <div class="col-lg-5">
          <div class="card p-4">
            <app-cart-voucher [showCheckout]="false"></app-cart-voucher>
          </div>
        </div>

      </div>
    </div>

  </div>

</div>
