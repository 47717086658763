<div class="inner-wrapper">
    <div class="container-fluid no-padding">
      <div class="row no-gutters overflow-auto">
        <div class="col-md-6">
          <div class="main-banner dogBg">
          </div>
        </div>
        <div class="col-md-6">
          <div class="section-2 user-page main-padding">
            <div class="login-sec">
              <div class="login-box">

                <h3>Logging you in...</h3>
                <p>Please wait.</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
