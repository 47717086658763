import { CartVendor } from './CartVendor';
import { CartVendorItem } from './CartVendorItem';
import { CartVoucher } from './CartVoucher';
import { CustomerAddress } from './CustomerAddress';

export class Cart {

  id: number;
  unique_basket_id: string;
  created_at: Date;
  updated_at: Date;
  address_id?: number;
  vendors?: CartVendor[];
  vouchers?: CartVoucher[];
}