
    <!-- slider -->
    <!-- about us -->
    <section class="aboutus section-padding">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="history-title mb-md-40">
                        <h2 class="text-light-black mt-5"> <span class="text-light-green">Fetch Delivery Service - Exclusively for Fetch Merchants</span></h2>

                     <div class="row pb-5">

                       <div class="col-lg-7">

                         <p><a href="http://fetch.je/" target="_blank">Fetch.je</a> offers you a delivery service through Jersey Post and two options are
                           available to you.</p>

                           <p>First one, drop your Fetch parcels at any post office and enjoy a next day delivery with a preferential rate.</p>
                           <p>Second one, a collection of your 5 Fetch parcels or more by a postie at your address for next day delivery.</p>

                         <p>Please complete the form available to download in your merchant portal and send it to  <a href="mailto:delivery@fetch.je" target="_blank">delivery@fetch.je</a>, make sure to read our FAQ below.</p>

                       </div>

                       <div class="col-lg-5">
                         <div class="row align-items-center">
                           <div class="col-lg-7 col-sm-7 col-md-7">
                             <div class="histry-img mb-xs-20">
                               <img src="assets/img/max1-about.jpg" class="img-fluid full-width" alt="Histry">
                             </div>
                           </div>
                         </div>
                       </div>

                     </div>


                      <h5><a href="https://fetchfilestorage.blob.core.windows.net/documents/delivery-map.pdf" target="_blank" class="text-primary" style="text-decoration: underline">Click HERE to discover our step-by-step delivery process. </a></h5>


                      <h2 class="text-light-black mt-5"> <span class="text-light-green">FAQs</span></h2>

                      <div class="single-question">

                        <h4 class="question-title">WHAT IS THE NEW DELIVERY SERVICE?
                        </h4>

                        <div class="question-answer">

                          <p>Deliveries take time and we know that our retailers are busy people. Therefore, we are introducing
                            this on-island parcel service to help you get your goods out to customers anywhere in Jersey.</p>

                          <p>The service is a next-day delivery service with two price options based on the weight of your items:</p>

                          <ul>
                            <li>Up to 3kg £3.00</li>
                            <li>3kg to 30kg £6.50</li>
                          </ul>

                          <p>You can drop the items off at your nearest Post Office, or, if you have 5 or more items, we’ll collect
                            them from you.</p>

                        </div>

                      </div>

                      <div class="single-question">

                        <h4 class="question-title">HOW DOES IT WORK?</h4>

                        <div class="question-answer">

                          <p> Your items will be delivered the next day** to customers by our Jersey Post posties on
                            their daily rounds.</p>
                          <ol>
                            <li class="mb-3">1) Package up and address your items ready to post.</li>
                            <li class="mb-3">2) Remember to put the customised Fetch sticker on the back of each item.</li>
                            <li class="mb-3">3) Then you have two options;
                              <ul>
                                <li class="p-2">
                                 - Drop off your items to a Post Office anywhere on the Island
                                </li>
                                <li class="p-2"><strong>or</strong></li>
                                <li class="p-2">
                                 - We’ll collect your items from you (if 5 items or more)
                                </li>
                              </ul>
                            </li>
                            <li class="mb-3">4) To book a delivery, you simply complete the booking form (available on the merchant
                              platform)
                              stating the number of items and selecting whether it is for drop-off or collection.
                            </li>
                            <li class="mb-3">5) Email one copy of the form to Delivery@fetch.je</li>
                            <li class="mb-3">6) Print off a copy of the form to accompany the delivery.</li>
                            <li class="mb-3">7) We’ll deliver your items to your customers the next day.</li>
                          </ol>
                        </div>

                        <small>** Next working day and Saturdays. Excludes public bank holidays.</small>


                      </div>

                      <div class="single-question">

                        <h4 class="question-title">WILL THE COLLECTION SERVICE BE AVAILABLE EVERY DAY?</h4>

                        <div class="question-answer">

                          <p>The collection service is available Monday to Friday, but must be booked by 1pm on the day
                            of collection.</p>
                          <p>You must have your items packaged, addressed and stickered, so they ready for collection
                            by 3.30pm.</p>
                          <p class="mb-0">Our posties will collect them from you, before 5pm, whilst on their afternoon collection
                            rounds.</p>


                        </div>

                      </div>

                      <div class="single-question">

                        <h4 class="question-title">WILL ITEMS BE DELIVERED EVERY DAY?</h4>

                        <div class="question-answer">

                          <p>Items will be delivered the following day on every day of the week, excluding Sunday and
                            Bank holidays.</p>
                          <p>Deliveries dropped off to a Post Office on a Saturday, will go out on Monday.</p>

                        </div>

                      </div>

                      <div class="single-question">

                        <h4 class="question-title">HOW DO I NEED TO PACKAGE MY ITEMS?</h4>

                        <div class="question-answer">

                          <p>Yes. You need to securely wrap and label your items as if you were sending them as parcels
                            through
                            the postal system. Fragile contents, such as glass bottles must be protected with bubble
                            wrap, or
                            similar and well-packaged. You must remember the sticker on the reverse of the item.</p>
                          <p>If sending fragile items, please note it on your booking form in the ‘Description of
                            items’ section.</p>

                        </div>

                      </div>

                      <div class="single-question">

                        <h4 class="question-title">HOW DO I CHARGE THE CUSTOMER FOR THE DELIVERY SERVICE?</h4>

                        <div class="question-answer">

                          <p>You will need to include this option on your seller page with the additional delivery
                            charge you would
                            like to pass on to the customer. It is up to you, the Fetch retailer, to decide how you
                            charge the
                            delivery to your customer</p>

                        </div>

                      </div>

                      <div class="single-question">

                        <h4 class="question-title">MY ITEMS ARE A LOT LIGHTER THAN 3KGS, DO YOU HAVE A SERVICE FOR LIGHTER ITEMS?</h4>

                        <div class="question-answer">

                          <p>This service is aimed more for the larger parcel items and provides preferential charges
                            at the heavier
                            weights.</p>
                          <p>For smaller and lighter items, we suggest you check out the range of standard on-island
                            postage rates
                            with Jersey Post which start at only 54p for up to 100g. You could post these items over the
                            counter
                            through the postal service as normal. You can check the rates online at <a
                              href="https://www.jerseypost.com/tools/calculator/">jerseypost.com</a>.</p>

                        </div>

                      </div>

                      <div class="single-question">

                        <h4 class="question-title">HOW WILL I BE CHARGED FOR THE DELIVERIES?</h4>

                        <div class="question-answer">

                          <p>The delivery charges will be automatically deducted, the following week, from the payment
                            we transfer to your account from the Fetch platform.</p>

                        </div>

                      </div>

                      <div class="single-question">

                        <h4 class="question-title">WHO DO I CONTACT TO START USING THE SERVICE?</h4>

                        <div class="question-answer">

                          <p>For more information, or to start using the service, contact Lucie by email at
                            <a href="mailto:lucie.sicard@fetch.je">lucie.sicard@fetch.je</a>.
                            Lucie will arrange and send you your personalised Fetch delivery stickers and the booking
                            form.</p>

                        </div>

                      </div>


                    </div>
                </div>

            </div>
        </div>
    </section>


    <nz-modal [(nzVisible)]="showImage" nzWidth="100%" (nzOnCancel)="showImage = false">

        <img style="display: block; margin: auto" src="/assets/img/orderprocess.png"/>

      <div *nzModalFooter>
      </div>

    </nz-modal>
