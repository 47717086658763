
<div class="sub-and-button">

  <h5 class="m-0 text-center text-lg-left">{{subtitle}}</h5>

  <button [routerLink]="'/curations/'+curationID" class="view-all">View All <i class="fas fa-chevron-right"></i></button>

</div>

<swiper #swiper class="mb-3 curation-container"
        *ngIf="loaded"
        [config]="swiperConfig"
        [slidesPerView]="5"
        [spaceBetween]="0"
        [navigation]="false"
        [pagination]="false"
        [scrollbar]="false"
>
  <ng-template *ngFor="let item of curation" swiperSlide>
    <div class="single-curation-product" (click)="navigateToProduct(item)">

      <div class="curation-product-image" *ngIf="item.main_image.length" [style.background-image]="'url('+item.main_image[0].url+')'">
      </div>

      <div class="curation-product-image" *ngIf="!item.main_image.length">
      </div>

      <div class="curation-product-text">

        <div class="truncated-container">
          <h4 [line-truncation]="2">{{item.product_name}}</h4>
        </div>
        <h5>{{item.vendor.trading_name}}</h5>
        <div class="price-block d-flex">
          <h3 class="price" *ngIf="item.discount_price">{{item.discount_price | currency: 'GBP'}}</h3>
          <h3 class="price" *ngIf="!item.discount_price">{{item.base_price | currency: 'GBP'}}</h3>
          <h3 class="reduced-price" *ngIf="item.discount_price"><span class="strike" style="font-size: 16px;text-decoration: line-through;">{{item.base_price | currency:'GBP'}}</span></h3>
        </div>

        <h6 class="text-danger delivery-text">
          <span *ngIf="item.vendor.delivery_on && !item.vendor.collection_on">Delivery Available</span>
          <span *ngIf="!item.vendor.delivery_on && item.vendor.collection_on">Collection Available</span>
          <span *ngIf="item.vendor.delivery_on && item.vendor.collection_on">Delivery & Collection Available</span>
        </h6>

      </div>

    </div>
  </ng-template>
</swiper>


