<div *ngIf="productAvailability">

  <div class="new-alert new-alert-primary" *ngIf="!productAvailability.Delivery.Available || !productAvailability.Collection.Available || !productAvailability.Collection.VendorAvailable || !productAvailability.Delivery.VendorAvailable">
    <label class="text-primary" *ngIf="!productAvailability.Delivery.Available && productAvailability.Delivery.VendorAvailable">This product isn't available for delivery.</label>
    <label class="text-primary" *ngIf="!productAvailability.Delivery.Available && !productAvailability.Delivery.VendorAvailable">{{vendor.trading_name}} does not offer delivery.</label>
    <label class="text-primary" *ngIf="!productAvailability.Collection.Available && productAvailability.Collection.VendorAvailable">This product isn't available for collection.</label>
    <label class="text-primary" *ngIf="!productAvailability.Collection.Available && !productAvailability.Collection.VendorAvailable">{{vendor.trading_name}} does not offer collection.</label>
  </div>

  <div class="delivery-selector">
    <label>Do you want delivery or collection?</label>
    <div class="single-value-select">
      <nz-radio-group [(ngModel)]="newCartConfig.fulfillment_method">
        <label [nzDisabled]="!productAvailability.Delivery.Available" nz-radio [nzValue]="'Delivery'">Delivery</label>
        <label [nzDisabled]="!productAvailability.Collection.Available" nz-radio [nzValue]="'Collection'">Collection</label>
      </nz-radio-group>
    </div>
  </div>

  <div class="delivery-scheduling" *ngIf="newCartConfig.fulfillment_method === 'Delivery'">
    <div class="location-selector">
      <label>Which parish do you live in?</label>
      <div class="single-value-select">
        <select [(ngModel)]="newCartConfig.delivery_location" class="form-control" name="location">
          <option [class.text-danger]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Helier')" [disabled]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Helier')" value="St Helier">St Helier <span class="text-danger" *ngIf="!cartService.willDeliver(vendor.deliver_exclusions, 'St Helier')"><i class="fa fa-times"></i></span></option>
          <option [class.text-danger]="!cartService.willDeliver(vendor.deliver_exclusions, 'Grouville')" [disabled]="!cartService.willDeliver(vendor.deliver_exclusions, 'Grouville')" value="Grouville">Grouville <span class="text-danger" *ngIf="!cartService.willDeliver(vendor.deliver_exclusions, 'Grouville')"><i class="fa fa-times"></i></span></option>
          <option [class.text-danger]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Brelade')" [disabled]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Brelade')" value="St Brelade">St Brelade <span class="text-danger" *ngIf="!cartService.willDeliver(vendor.deliver_exclusions, 'St Brelade')"><i class="fa fa-times"></i></span></option>
          <option [class.text-danger]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Clement')" [disabled]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Clement')" value="St Clement">St Clement <span class="text-danger" *ngIf="!cartService.willDeliver(vendor.deliver_exclusions, 'St Clement')"><i class="fa fa-times"></i></span></option>
          <option [class.text-danger]="!cartService.willDeliver(vendor.deliver_exclusions, 'St John')" [disabled]="!cartService.willDeliver(vendor.deliver_exclusions, 'St John')" value="St John">St John <span class="text-danger" *ngIf="!cartService.willDeliver(vendor.deliver_exclusions, 'St John')"><i class="fa fa-times"></i></span></option>
          <option [class.text-danger]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Lawrence')" [disabled]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Lawrence')" value="St Lawrence">St Lawrence <span class="text-danger" *ngIf="!cartService.willDeliver(vendor.deliver_exclusions, 'St Lawrence')"><i class="fa fa-times"></i></span></option>
          <option [class.text-danger]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Martin')" [disabled]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Martin')" value="St Martin">St Martin <span class="text-danger" *ngIf="!cartService.willDeliver(vendor.deliver_exclusions, 'St Martin')"><i class="fa fa-times"></i></span></option>
          <option [class.text-danger]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Mary')" [disabled]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Mary')" value="St Mary">St Mary <span class="text-danger" *ngIf="!cartService.willDeliver(vendor.deliver_exclusions, 'St Mary')"><i class="fa fa-times"></i></span></option>
          <option [class.text-danger]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Ouen')" [disabled]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Ouen')" value="St Ouen">St Ouen <span class="text-danger" *ngIf="!cartService.willDeliver(vendor.deliver_exclusions, 'St Ouen')"><i class="fa fa-times"></i><i class="fa fa-times"></i></span></option>
          <option [class.text-danger]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Peter')" [disabled]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Peter')" value="St Peter">St Peter <span class="text-danger" *ngIf="!cartService.willDeliver(vendor.deliver_exclusions, 'St Peter')"><i class="fa fa-times"></i></span></option>
          <option [class.text-danger]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Saviour')" [disabled]="!cartService.willDeliver(vendor.deliver_exclusions, 'St Saviour')" value="St Saviour">St Saviour <span class="text-danger" *ngIf="!cartService.willDeliver(vendor.deliver_exclusions, 'St Saviour')"><i class="fa fa-times"></i></span></option>
          <option [class.text-danger]="!cartService.willDeliver(vendor.deliver_exclusions, 'Trinity')" [disabled]="!cartService.willDeliver(vendor.deliver_exclusions, 'Trinity')" value="Trinity">Trinity <span class="text-danger" *ngIf="!cartService.willDeliver(vendor.deliver_exclusions, 'Trinity')"><i class="fa fa-times"></i></span></option>
        </select>
      </div>
    </div>

    <div *ngIf="vendor.delivery_schedule_on">
      <label>Select a date for delivery</label>
      <div class="row">
        <div class="col-8">
          <bs-datepicker-inline *ngIf="newCartConfig.scheduled_date" [bsConfig]="{daysDisabled: disabledDaysDelivery, showWeekNumbers:false, adaptivePosition: true, containerClass: 'theme-green availability-picker', displayMonths : 1 }" (bsValueChange)="getAvailableTimesWithinOpening($event, 'Delivery')" [datesDisabled]="blockedDaysDelivery" [maxDate]="maxDate" [minDate]="minDateDelivery" [bsValue]="newCartConfig.scheduled_date"></bs-datepicker-inline>
          <bs-datepicker-inline *ngIf="!newCartConfig.scheduled_date" [bsConfig]="{daysDisabled: disabledDaysDelivery, showWeekNumbers:false, adaptivePosition: true, containerClass: 'theme-green availability-picker', displayMonths : 1 }" (bsValueChange)="getAvailableTimesWithinOpening($event, 'Delivery')" [datesDisabled]="blockedDaysDelivery" [maxDate]="maxDate" [minDate]="minDateDelivery"></bs-datepicker-inline>
        </div>
      </div>

      <div class="time-selector" *ngIf="vendor.delivery_type === 'datetime'">
        <div class="single-value-select">
          <label>What Time:</label>
          <select [(ngModel)]="newCartConfig.scheduled_time" class="form-control">
            <option *ngFor="let time of availableTimesDelivery" [value]="time | date: 'HH:mm:ss'">{{time | date: 'HH:mm:ss'}}</option>
          </select>
        </div>
      </div>
    </div>

  </div>

  <div class="collection-scheduling" *ngIf="newCartConfig.fulfillment_method === 'Collection'">
    <label class="mb-2">Select a date for collection</label>
    <div class="row">
      <div class="col-8">
        <bs-datepicker-inline [bsConfig]="{daysDisabled: disabledDaysCollection, showWeekNumbers:false, adaptivePosition: true, containerClass: 'theme-green availability-picker', displayMonths : 1 }" (bsValueChange)="getAvailableTimesWithinOpening($event, 'Collection')" [datesDisabled]="blockedDaysCollection" [maxDate]="maxDate" [minDate]="minDateCollection" [bsValue]="newCartConfig.scheduled_date" *ngIf="newCartConfig.scheduled_date"></bs-datepicker-inline>
        <bs-datepicker-inline *ngIf="!newCartConfig.scheduled_date" [bsConfig]="{daysDisabled: disabledDaysCollection, showWeekNumbers:false, adaptivePosition: true, containerClass: 'theme-green availability-picker', displayMonths : 1 }" (bsValueChange)="getAvailableTimesWithinOpening($event, 'Collection')" [datesDisabled]="blockedDaysCollection" [maxDate]="maxDate" [minDate]="minDateCollection"></bs-datepicker-inline>
      </div>
    </div>

    <div class="time-selector" *ngIf="vendor.collection_type === 'datetime' && newCartConfig.scheduled_date">
      <div class="single-value-select">
        <label>What Time:</label>
        <select [(ngModel)]="newCartConfig.scheduled_time" class="form-control">
          <option *ngFor="let time of availableTimesCollection" [value]="time | date: 'HH:mm:ss'">{{time | date: 'HH:mm:ss'}}</option>
        </select>
      </div>
    </div>

  </div>

  <hr>

  <button class="btn btn-primary btn-block" (click)="submitCart()" [disabled]="!isCartValid()">
    <span *ngIf="isCartValid()">Confirm & Add To Cart</span>
    <span *ngIf="!isCartValid()">Please complete all the options</span>
  </button>

</div>