import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../models/Product';
import { Vendor } from '../../../models/Vendor';
import { NewCartCriteria } from '../../../models/NewCartCriteria';
import { CartService } from '../../../services/Cart.service';
import { CartVendorItem } from '../../../models/CartVendorItem';
import { ApiService } from '../../../services/Api.service';
import { ProductAvailabilityMatrix } from '../../../models/ProductAvailabilityMatrix';
import * as moment from 'moment';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-configure-order',
  templateUrl: './configure-order.component.html',
  styleUrls: ['./configure-order.component.scss']
})
export class ConfigureOrderComponent implements OnInit {

  @Input() itemToAdd: CartVendorItem;
  @Input() product: Product;
  @Input() vendor: Vendor;

  newCartConfig: NewCartCriteria = new NewCartCriteria();
  productAvailability: ProductAvailabilityMatrix;

  productCanBeAdded: boolean;

  minDateDelivery: Date = new Date();
  minDateCollection: Date = new Date();

  disabledDaysDelivery: number[];
  disabledDaysCollection: number[];

  blockedDaysDelivery: Date[];
  blockedDaysCollection: Date[];

  availableTimesCollection: any[];
  availableTimesDelivery: any[];
  maxDate: Date;
  loaded = false;

  constructor(public cartService: CartService, public api: ApiService, public modal: NzModalRef) { }

  ngOnInit(): void {

    this.maxDate = new Date();

    this.maxDate.setDate(this.maxDate.getDate() + 90);

    this.cartService.getAvailabilityForProduct(this.itemToAdd.product_id).then((AvailabilityData: ProductAvailabilityMatrix) => {
      this.productAvailability = AvailabilityData;

      this.blockedDaysDelivery = [];
      this.blockedDaysCollection = [];

      // APPLY MINIMUM DATE BASED ON AVAILABILITY SETTINGS
      this.minDateDelivery.setDate(this.minDateDelivery.getDate() + this.vendor.estimated_slow_delivery);
      this.minDateCollection.setDate(this.minDateCollection.getDate() + this.vendor.estimated_slow_collection);

      // BLOCK OUT ANY DAYS UNTICKED ON THE MERCHANT PORTAL
      this.disabledDaysCollection = this.productAvailability.Collection.BlockDays;
      this.disabledDaysDelivery = this.productAvailability.Delivery.BlockDays;

      // NOW BLOCK OFF ANY BLOCKED DATES
      if (this.productAvailability.StopSale) {
        for (const item of this.productAvailability.StopSale) {
          this.blockedDaysDelivery.push(new Date(item));
          this.blockedDaysCollection.push(new Date(item));
        }
      }

      if (this.productAvailability.Delivery.finishedToday) {
        this.blockedDaysDelivery.push(new Date(this.productAvailability.Delivery.finishedToday));
      }

      if (this.productAvailability.Collection.finishedToday) {
        this.blockedDaysCollection.push(new Date(this.productAvailability.Collection.finishedToday));
      }

      for (const exclusion of this.productAvailability.Delivery.BlockedTimes) {
        if (exclusion.all_day) {
          this.blockedDaysDelivery.push(new Date(exclusion.date));
        }
      }

      for (const exclusion of this.productAvailability.Collection.BlockedTimes) {
        if (exclusion.all_day) {
          this.blockedDaysCollection.push(new Date(exclusion.date));
        }
      }

      if (!this.cartService.hasCart) {
        // INITIALIZE THE WHOLE THING
        this.newCartConfig = new NewCartCriteria();
        // DOES THE MERCHANT ALLOW DELIVERY
        if (!this.productAvailability.Delivery.Available) {
          // FORCE COLLECTION
          this.newCartConfig.fulfillment_method = 'Collection';
        } else {
          this.newCartConfig.fulfillment_method = 'Delivery';
        }
      } else {
          // HANDLE EXTRA ITEM TO CART
      }
      this.loaded = true;
    });
  }

  submitCart() {
    this.cartService.addToCart(this.itemToAdd, this.vendor, this.newCartConfig).then((res) => {
      this.cartService.cartId = res.Cart.guid;
      this.cartService.cart = res.Cart;
      this.cartService.hasCart = true;
      sessionStorage.setItem('fetch-cart', res.Cart.guid);
      this.modal.destroy();
    });
  }

  getAvailableTimesWithinOpening(date: Date = new Date(), type = 'Delivery') {

    this.newCartConfig.scheduled_date = date;

    if (!date) { return; }

    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

    this.availableTimesCollection = [];
    this.availableTimesDelivery = [];

    const dayName = days[date.getDay()];

    const dayToTest = this.productAvailability[type].Days[dayName];

    // START TIME SPLITTING FOR THAT OPENING HOUR SEGMENT

    let today = false;

    const startTime = moment(dayToTest.start, 'HH:mm:ss');
    const endTime = moment(dayToTest.end, 'HH:mm:ss');

    if (date.getDate() === new Date().getDate()) {
      today = true;
    }

    this['availableTimes' + type].push(...
      this.cartService.splitTimeSpecificDate(
        startTime,
        endTime,
        15,
        today,
        this.productAvailability[type].BlockedTimes,
        date,
        this.product
      ));

    return;
  }


  splitTime(startTime, endTime, interval) {

    const result = [startTime.toString()];
    let time = startTime.add(interval, 'm');
    while (time.isBetween(startTime, endTime, undefined, [])) {
      result.push(time.toString());
      time = time.add(interval, 'm');
    }
    return result;

  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  isCartValid() {

    if (!this.productAvailability) {
      // console.log('product unavailable');
      return false;
    }

    if (!this.newCartConfig.fulfillment_method) {
      // console.log('No fulfilment method selected');
      return false;
    }

    // ASAP DELIVERY
    if (!this.vendor.delivery_schedule_on && this.newCartConfig.fulfillment_method === 'Delivery') {
      return true;
    }

    if (this.newCartConfig.fulfillment_method === 'Delivery' && !this.newCartConfig.delivery_location){
     // console.log('No location selected');
      return false;
    }

    // MUST PICK A DATE
    if (this.vendor.delivery_schedule_on && this.newCartConfig.fulfillment_method === 'Delivery') {

      // NO DATE PICKED
      if (!this.newCartConfig.scheduled_date) {
        //  console.log('No date picked');
        return false;
      } else {
        // console.log('date picked');
        // DATE PICKED AND TIME PICKED OR TIME NOT NEEDED
        return !!(this.newCartConfig.scheduled_time || this.vendor.delivery_type === 'date');

      }
    }

    if (this.newCartConfig.fulfillment_method === 'Collection' && !this.newCartConfig.scheduled_date) {
      return false;
    }

    if (this.vendor.collection_type === 'datetime' && !this.newCartConfig.scheduled_time) {
      return false;
    }

    return true;
  }
}