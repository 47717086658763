import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/Api.service';
import {Vendor} from '../../models/Vendor';

@Component({
  selector: 'app-home-page-stores',
  templateUrl: './home-page-stores.component.html',
  styleUrls: ['./home-page-stores.component.scss']
})
export class HomePageStoresComponent implements OnInit {

  stores: Vendor[];

  constructor(public api: ApiService) { }

  ngOnInit(): void {
    this.getStores();
  }

  getStores(){

    this.api.apiGet('public/stores/home').then((res) => {
        this.stores = res;
    });

  }

  getBackgroundImageForVendor(vendor){

    if (vendor.vendor_image){
      return encodeURI(vendor.vendor_image);
    }else{
      return 'assets/img/placeholder.png';
    }

  }


}
