<section class="section">

<div class="container">

  <div class="row align-items-center">

    <div class="col-md-5">

      <h1>I can't find that!</h1>

      <h3>Sorry the page you were looking for was not found.</h3>

    </div>

    <div class="col-md-6 offset-md-1">

      <img src="assets/img/not-found-max.png" alt="Not Found Max">

    </div>

  </div>

</div>

</section>
