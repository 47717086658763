import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './Auth.service';
import { Cart } from '../models/Cart';
import { Vendor } from '../models/Vendor';
import { Search } from '../models/Search';
import { ApiService } from './Api.service';
import { Order } from '../models/Order';
import { CustomerAddressUpdate } from '../models/CustomerAddressUpdate';
import { CustomerAddress } from '../models/CustomerAddress';
import { GiftInfo } from '../models/GiftInfo';

// @ts-ignore
@Injectable({
  providedIn: 'root',
})
// @ts-ignore
export class CheckoutService {

  cartId = sessionStorage.getItem('fetch-cart');
  order: Order;
  deliverTo: CustomerAddressUpdate;
  giftInfo: GiftInfo;
  isAGift = false;

  constructor(
    public router: Router,
    public auth: AuthService,
    public api: ApiService,
    public http: HttpClient
  ) {

  }

  public makePayment(type, giftItems, method = null): Promise<any> {
    return new Promise((resolve, reject) => {
      const cartId = sessionStorage.getItem('fetch-cart');
      if (cartId) {
        this.api.apiPost('core/customer/checkout/' + cartId + '/pay', {
          paymentMethod: method,
          paymentType: type,
          giftItems: giftItems
        }).then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      } else {
        reject(false);
      }
    });
  }

  public paymentSuccess(payment, orderId): Promise<any> {
    return new Promise((resolve, reject) => {
      const cartId = sessionStorage.getItem('fetch-cart');
      if (cartId) {
        this.api.apiPost('core/customer/checkout/' + cartId + '/payment-success',
          {
            payment: payment,
            orderId: orderId
          }).then((res) => {
            resolve(res);
          });
      } else {
        reject(false);
      }
    });
  }

  public makeVoucherPayment(giftItems): Promise<any> {
    return new Promise((resolve, reject) => {
      const cartId = sessionStorage.getItem('fetch-cart');
      if (cartId) {
        this.api.apiPost('core/customer/checkout/' + cartId + '/pay-voucher', {
          giftItems: giftItems
        }).then((res) => {
          resolve(res);
        });
      } else {
        reject(false);
      }
    });
  }







  public initializeCheckout(): Promise<any> {

    return new Promise((resolve, reject) => {
      const cartId = sessionStorage.getItem('fetch-cart');
      if (cartId) {
        this.api.apiPost('core/customer/checkout/' + cartId, {}).then((res) => {
          this.order = res;
          console.log(this.order);
          resolve(true);
        });
      } else {
        reject(false);
      }
    });

  }

  // arrangeItemsForTagManager() {

  //   const items = [];

  //   let x = 1;
  //   for (const item of this.order.order_items) {

  //     items.push({
  //       id: item.productID,
  //       name: item.product_name,
  //       brand: this.order.vendor.trading_name,
  //       list_position: x,
  //       quantity: item.quantity,
  //       price: item.cost_per_item
  //     });

  //     x++;

  //   }

  //   return items;

  // }


  public applyVoucherToCheckout(discountCode): Promise<any> {

    return new Promise((resolve, reject) => {
      const cartId = sessionStorage.getItem('fetch-cart');
      if (cartId) {
        this.api.apiPost('core/customer/checkout/' + cartId + '/addVoucher', { code: discountCode }).then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      } else {
        reject(false);
      }
    });

  }

  public addDataToOrder(phone, notes, isGift, giftInfo): Promise<any> {

    const data = { phone_number: phone, orderNotes: notes, is_gift: false, gift_info: null };

    if (isGift) {
      data.is_gift = true;
      data.gift_info = giftInfo
    }

    return new Promise((resolve, reject) => {
      const cartId = sessionStorage.getItem('fetch-cart');
      if (cartId) {
        this.api.apiPost('core/customer/checkout/' + cartId + '/addData', data).then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      } else {
        reject(false);
      }
    });

  }

  public applyDiscountToCheckout(discountCode): Promise<any> {

    return new Promise((resolve, reject) => {
      const cartId = sessionStorage.getItem('fetch-cart');
      if (cartId) {
        this.api.apiPost('core/customer/checkout/' + cartId + '/addDiscount', { code: discountCode }).then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      } else {
        reject(false);
      }
    });

  }

  public validateVoucherOrder(address): Promise<any> {

    return new Promise((resolve, reject) => {
      const cartId = sessionStorage.getItem('fetch-cart');
      if (cartId) {
        this.api.apiPost('core/customer/checkout/' + cartId + '/validateOrderBalance', address).then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
      } else {
        reject(false);
      }
    });

  }


  public paymentIntent(type, method = null): Promise<any> {

    return new Promise((resolve, reject) => {
      const cartId = sessionStorage.getItem('fetch-cart');
      if (cartId) {
        this.api.apiPost('core/customer/checkout/' + cartId + '/pre-payment', {
          payment_method: method,
          deliveryMethod: this.deliverTo,
          payment_type: type
        }).then((res) => {
          resolve(res);
        });
      } else {
        reject(false);
      }
    });

  }


}
