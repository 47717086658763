import {Component, Input, OnInit} from '@angular/core';
import {Cart} from '../../models/Cart';
import {CartService} from '../../services/Cart.service';
import {Vendor} from '../../models/Vendor';
import {NewCartCriteria} from '../../models/NewCartCriteria';
import {OpeningTime} from '../../models/OpeningTime';
import * as moment from 'moment';

@Component({
  selector: 'app-order-configuration',
  templateUrl: './order-configuration.component.html',
  styleUrls: ['./order-configuration.component.scss']
})
export class OrderConfigurationComponent implements OnInit {

  @Input() vendor: Vendor;

  configCart: boolean;
  newCartConfig: NewCartCriteria;
  availableTimes: string[];
  availableTimesNew: string[];
  availableTimeTemplate: OpeningTime[];

  constructor(public cartService: CartService) { }

  ngOnInit(): void {
  }



}
