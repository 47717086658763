<div class="checkout-address">
  <div class="checkout-address-existing" *ngIf="!gift && addresses && addresses.length > 0">
    <div class="checkout-address-existing-title">Delivery Address</div>
    <nz-radio-group [(ngModel)]="address">
      <label nz-radio [nzValue]="address" *ngFor="let address of addresses">
        <div class="checkout-address-existing-item-name">{{address.recipient}}</div>
        <div class="checkout-address-existing-item-line">{{address.address_1}}, {{address.address_2}}, {{address.town_parish}}, {{address.post_code}} - <span>T.</span> {{address.telephone}}</div>
      </label>
    </nz-radio-group>
    <button class="btn btn-select btn-sm" (click)="selectAddress()">Use this address</button>
  </div>
  <div class="checkout-address-new">
    <div class="checkout-address-new-title" *ngIf="!gift && !collection && (!addresses || addresses.length == 0)">Delivery Address</div>
    <div class="checkout-address-new-title" *ngIf="gift">Gift Recipient Address</div>
    <div class="checkout-address-new-title" *ngIf="collection">Your Details</div>
    <div class="checkout-address-new-title" *ngIf="!gift && !collection && (addresses && addresses.length > 0)">Or, enter a new address</div>
    <form [formGroup]="addressForm">
      <div class="row">
        <div class="col-lg-9">
          <div class="form-group">
            <label class="fs-14">Recipient</label>
            <input type="text" class="form-control form-control-submit" placeholder="e.g. Elvis Presley" formControlName="recipient" [ngClass]="{ 'is-invalid': submitted && f.recipient.errors }">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label class="fs-14">Address Line 1</label>
            <input type="text" class="form-control form-control-submit" placeholder="Flat number, house name" formControlName="address1" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="form-group">
            <label class="fs-14">Address Line 2</label>
            <input type="text" class="form-control form-control-submit" placeholder="Road or street" formControlName="address2" [ngClass]="{ 'is-invalid': submitted && f.address2.errors }">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-6">
          <div class="form-group">
            <label class="fs-14">Parish</label>
            <select name="location" class="form-control" formControlName="town_parish" [ngClass]="{ 'is-invalid': submitted && f.town_parish.errors }">
              <option value="">Please Select One</option>
              <option value="St Helier" [disabled]="!willDeliver('St Helier')">St Helier</option>
              <option value="Grouville" [disabled]="!willDeliver('Grouville')">Grouville</option>
              <option value="St Brelade" [disabled]="!willDeliver('St Brelade')">St Brelade</option>
              <option value="St Clement" [disabled]="!willDeliver('St Clement')">St Clement</option>
              <option value="St John" [disabled]="!willDeliver('St John')">St John</option>
              <option value="St Lawrence" [disabled]="!willDeliver('St Lawrence')">St Lawrence</option>
              <option value="St Martin" [disabled]="!willDeliver('St Martin')">St Martin</option>
              <option value="St Mary" [disabled]="!willDeliver('St Mary')">St Mary</option>
              <option value="St Ouen" [disabled]="!willDeliver('St Ouen')">St Ouen</option>
              <option value="St Peter" [disabled]="!willDeliver('St Peter')">St Peter</option>
              <option value="St Saviour" [disabled]="!willDeliver('St Saviour')">St Saviour</option>
              <option value="Trinity" [disabled]="!willDeliver('Trinity')">Trinity</option>
            </select>
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-6">
          <div class="form-group">
            <label class="fs-14">Post Code</label>
            <input name="postal-code" type="text" class="form-control form-control-submit" placeholder="JE1 1AD" formControlName="postcode" [ngClass]="{ 'is-invalid': submitted && f.postcode.errors }" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-9">
          <div class="form-group">
            <label class="fs-14">Telephone Number</label>
            <input type="text" class="form-control" placeholder="e.g. 07781 123456" formControlName="telephone" [ngClass]="{ 'is-invalid': submitted && f.telephone.errors }" />
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!collection">
        <div class="col-lg-12 col-md-12 col-sm-6">
          <div class="form-group">
            <label class="fs-14">Delivery Instructions</label>
            <textarea class="form-control" placeholder="e.g. Phone when outside" formControlName="instructions" [ngClass]="{ 'is-invalid': submitted && f.instructions.errors }"></textarea>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!collection">
        <div class="col-lg-9">
          <div class="form-group">
            <label class="fs-14">What 3 Words. Help us find you, optionally, enter your what3 words</label>
            <input type="text" class="form-control" placeholder="e.g. //snout.shout.clout" formControlName="what3" [ngClass]="{ 'is-invalid': submitted && f.what3.errors }" />
          </div>
        </div>
      </div>
    </form>

    <button class="btn btn-primary text-white" [disabled]="submitted" (click)="saveAddress()" *ngIf="!collection">Save address</button>
    <button class="btn btn-primary text-white" [disabled]="submitted" (click)="saveAddress()" *ngIf="collection">Save</button>
  </div>
</div>