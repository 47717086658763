import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {AuthService} from './Auth.service';
import {Cart} from '../models/Cart';
import {Vendor} from '../models/Vendor';
import {Search} from '../models/Search';
import {ApiService} from './Api.service';
import {Order} from '../models/Order';

// @ts-ignore
@Injectable({
  providedIn: 'root',
})
// @ts-ignore
export class OrderService {

  constructor(
    public router: Router,
    public auth: AuthService,
    public api: ApiService,
    public http: HttpClient
  ) {

  }


  getSingleActiveOrder(): any{
    return new Promise((resolve, reject) => {
      const orderId = sessionStorage.getItem('order_id');
      if (orderId){
        this.api.apiGet('core/customer/orders/' + orderId).then((res) => {
          resolve(res);
        });
      }else{
        reject(false);
      }
    });
  }

  getSingleOrder(id): any{

    return new Promise((resolve, reject) => {
        this.api.apiGet('core/customer/orders/' + id).then((res) => {
          resolve(res);
        }).catch((err) => {
          reject(err);
        });
    });

  }

  getActiveOrders(){



  }

  getOrders(){



  }

}
