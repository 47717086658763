import { Component, Input, OnInit } from '@angular/core';
import { Vendor } from '../../models/Vendor';
import { Cart } from '../../models/Cart';
import { CartService } from '../../services/Cart.service';
import { SearchService } from '../../services/Search.service';
import { ProductOption } from '../../models/ProductOption';
import { CheckoutService } from '../../services/Checkout.service';
import { Order } from '../../models/Order';

@Component({
  selector: 'app-cart-checkout',
  templateUrl: './cart-checkout.component.html',
  styleUrls: ['./cart-checkout.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class CartCheckout implements OnInit {

  codeToTry: string;
  discountCodeToTry: string;
  discountError: string;
  error: string;
  showExpireModal: boolean;

  constructor(public checkoutService: CheckoutService, public search: SearchService, public cartService: CartService) { }

  ngOnInit(): void {
  }

  getProductOptions(options: ProductOption[]) {

    let optionString = '';
    let index = 0;
    for (const option of options) {

      if (option.type) {
        for (const optionValue of option.options) {
          if (optionValue.selected) {
            if (index) {
              optionString += ', ' + optionValue.title;
              index++;
            } else {
              optionString += optionValue.title;
              index++;
            }
          }
        }
      }

    }

    return optionString;

  }

  calculateDeliveryCharge(order: Order){

    let charge = order.delivery_charge;

    if (!this.checkoutService.deliverTo){
      return parseFloat(charge.toString());
    }

    // for (const chargeItem of order.vendor.delivery_rates){
    //   if (chargeItem.area === this.checkoutService.deliverTo.address.town_parish){
    //     charge = chargeItem.delivery_charge;
    //   }
    // }

    return parseFloat(charge.toString());


  }

  applyVoucherToCheckout() {

    // this.checkoutService.applyVoucherToCheckout(this.codeToTry).then((res) => {
    //   this.checkoutService.order.vouchers = res.vouchers;
    //   this.checkoutService.order.discounts = res.discounts;
    // }).catch((err) => {
    //   this.error = err.error.Error;
    // });

  }

  applyDiscountToCheckout() {

    // this.checkoutService.applyDiscountToCheckout(this.discountCodeToTry).then((res) => {
    //   this.checkoutService.order.vouchers = res.vouchers;
    //   this.checkoutService.order.discounts = res.discounts;
    // }).catch((err) => {
    //   this.discountError = err.error.Error;
    // });

  }

  calculateAmountDue() {

    return 0;
   // return this.checkoutService.order.calculated_order_total + this.calculateDeliveryCharge(this.checkoutService.order) - this.calculateVoucherTotal() - this.calculateDiscountTotal();
  }

  calculateVoucherTotal() {

    let voucherTotal = 0;

    for (const voucher of this.checkoutService.order.vouchers) {
      voucherTotal += voucher.amount_used;
    }

    return voucherTotal;

  }

  calculateDiscountTotal() {

    let discountTotal = 0;

    // for (const discount of this.checkoutService.order.discounts) {
    //   discountTotal += discount.calculated_saving;
    // }

    return discountTotal;

  }

  checkForExpired() {

    // TODO
    // if (this.cartService.cart) {
    //   for (const item of this.cartService.cart.items) {
    //     if (item.stop_sale_expiry) {
    //       return true;
    //     }
    //   }
    // }

    return false;

  }

}

