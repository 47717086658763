import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {AboutComponent} from './pages/about/about.component';
import {ListviewComponent} from './pages/listview/listview.component';
import {LoginComponent} from './pages/login/login.component';
import {RegisterComponent} from './pages/register/register.component';
import {BasketComponent} from './pages/basket/basket.component';
import {CheckoutComponent} from './pages/checkout/checkout.component';
import {ContactComponent} from './pages/contact/contact.component';
import {AppComponent} from './app.component';
import {AuthCheck} from './services/AuthCheck';
import {ActivateComponent} from './pages/activate/activate.component';
import {AuthGuard} from './services/AuthGuard';
import {SingleStoreComponent} from './pages/single-store/single-store.component';
import {OrderTrackerComponent} from './pages/order-tracker/order-tracker.component';
import {OrderListComponent} from './pages/order-list/order-list.component';
import {OldOrderComponent} from './pages/old-order/old-order.component';
import {CheckoutVoucherComponent} from './pages/checkout-voucher/checkout-voucher.component';
import {VoucherPurchasedComponent} from './pages/voucher-purchased/voucher-purchased.component';
import {MyAccountComponent} from './pages/my-account/my-account.component';
import {AuthGuardWithoutActivate} from './services/AuthGuardWithoutActivate';
import {PrivacyComponent} from './pages/privacy/privacy.component';
import {TermsComponent} from './pages/terms/terms.component';
import {SellingOnFetchComponent} from './pages/selling-on-fetch/selling-on-fetch.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import {ProductListComponent} from './pages/productList/product-list.component';
import {HomeLayoutComponent} from './layouts/home-layout/home-layout.component';
import {MainLayoutComponent} from './layouts/main-layout/main-layout.component';
import {BulkVouchersComponent} from './pages/bulk-vouchers/bulk-vouchers.component';
import {BulkContactComponent} from './pages/bulk-contact/bulk-contact.component';
import {LoginSpecialComponent} from './pages/loginSpecial/login-special.component';
import {GenericDynamicPageComponent} from './pages/generic-dynamic-page/generic-dynamic-page.component';
import {ContentResolver} from './services/content.resolver';
import {MerchantListComponent} from './pages/merchantList/merchant-list.component';
import {SearchLayoutComponent} from './layouts/search-layout/search-layout.component';
import {MenuCheck} from './services/MenuCheck';
import {SingleCurationPageComponent} from './pages/single-curation-page/single-curation-page.component';
import {NotFoundPageComponent} from './pages/not-found-page/not-found-page.component';
import { OrderComponent } from './pages/order/order.component';

const routes: Routes = [

  {
    path: '',
    canActivateChild: [AuthCheck, MenuCheck],
    children: [
        {
          path: '',
          children: [
            {
              path: '',
              data: {
                slug: 'homepage'
              },
              resolve: {
                content: ContentResolver
              },
              component: GenericDynamicPageComponent
            }
          ],
          component: MainLayoutComponent
        },
        {
          path: '404-page-not-found',
          children: [
            {
              path: '',
              component: NotFoundPageComponent
            }
          ],
          component: MainLayoutComponent
        },
        {
          path: 'login',
          children: [
            {
              path: '',
              component: LoginComponent
            }
          ],
          component: MainLayoutComponent
        },
        {
          path: 'specialSignIn',
          children: [
            {
              path: '',
              component: LoginSpecialComponent
            }
          ],
          component: MainLayoutComponent
        },
        {
          path: 'curations',
          children: [
            {
              path: ':id',
              component: SingleCurationPageComponent
            }
          ],
          component: MainLayoutComponent
        },
        {
          path: 'register',
          children: [
            {
              path: '',
              component: RegisterComponent
            }
          ],
          component: MainLayoutComponent
        },
        {
          path: 'selling-on-fetch',
          runGuardsAndResolvers: 'always',
          data: {
            hideFooter: true
          },
          children: [
            {
              path: ':slug',
              data: {
                bucket: 'selling-on-fetch-'
              },
              resolve: {
                content: ContentResolver
              },
              component: GenericDynamicPageComponent
            },
            {
              path: '',
              data: {
                bucket: 'selling-on-fetch-'
              },
              resolve: {
                content: ContentResolver
              },
              component: GenericDynamicPageComponent
            }
          ],
          component: MainLayoutComponent
        },
        {
          path: 'about-fetch',
          children: [
            {
              path: ':slug',
              data: {
                bucket: 'about-fetch-'
              },
              runGuardsAndResolvers: 'pathParamsChange',
              resolve: {
                content: ContentResolver
              },
              component: GenericDynamicPageComponent
            },
            {
              path: '',
              data: {
                bucket: 'about-fetch-'
              },
              resolve: {
                content: ContentResolver
              },
              component: GenericDynamicPageComponent
            }
          ],
          component: MainLayoutComponent
        },

        {
          path: 'p',
          children: [
            {
              path: ':slug',
              resolve: {
                content: ContentResolver
              },
              component: GenericDynamicPageComponent
            }
          ],
          component: MainLayoutComponent
        },

        {
          path: 'browse',
          children: [
            {
              path: 'merchants',
              component: MerchantListComponent
            },
            {
              path: 'products',
              component: ProductListComponent
            },
            {
              path: 'collections/:id',
              component: ListviewComponent // SINGLE CURATION
            }
          ],
          component: SearchLayoutComponent
        },

        {
          path: 'stores/:name/:id',
          children: [
            {
              path: '',
              component: SingleStoreComponent
            },
          ],
          component: MainLayoutComponent
        },

        {
          path: 'order-complete',
          data: {
            hideFooter: true
          },
          children: [
            {
              path: '',
              component: OrderComponent
            }
          ],
          component: MainLayoutComponent
        },
     /*   {
          path: 'gift-cards',
          children: [
            {
              path: '',
              component: BulkVouchersComponent
            }
          ],
          component: MainLayoutComponent
        },
        */
  /*      {
          path: 'gift-cards-bulk',
          children: [
            {
              path: '',
              component: BulkVouchersComponent
            }
          ],
          component: MainLayoutComponent
        },
        {
          path: 'gift-card-checkout',
          children: [
            {
              path: '',
              component: CheckoutVoucherComponent
            }
          ],
          component: MainLayoutComponent
        },
*/
        {
          path: 'voucher-order-complete',
          children: [
            {
              path: '',
              component: VoucherPurchasedComponent
            }
          ],
          component: MainLayoutComponent
        },

        {
          path: 'previous-orders',
          children: [
            {
              path: '',
              component: OrderListComponent
            }
          ],
          component: MainLayoutComponent
        },
        {
          path: 'previous-orders/:id',
          children: [
            {
              path: '',
              component: OldOrderComponent
            }
          ],
          component: MainLayoutComponent
        },

        {
          path: 'my-account',
          children: [
            {
              path: '',
              component: MyAccountComponent
            }
          ],
          component: MainLayoutComponent
        },

        {
          path: 'privacy-policy',
          children: [
            {
              path: '',
              component: PrivacyComponent
            }
          ],
          component: MainLayoutComponent
        },

        {
          path: 'terms-and-conditions',
          children: [
            {
              path: '',
              component: TermsComponent
            }
          ],
          component: MainLayoutComponent
        },

        {
          path: 'forgot-password',
          children: [
            {
              path: '',
              component: ResetPasswordComponent
            }
          ],
          component: MainLayoutComponent
        },

        {
          path: 'activate',
          children: [
            {
              path: '',
              component: ActivateComponent
            }
          ],
          canActivate: [AuthGuardWithoutActivate],
          component: MainLayoutComponent
        },
        {
          path: 'checkout',
          children: [
            {
              path: '',
              component: CheckoutComponent
            }
          ],
          canActivate: [AuthGuard],
          component: MainLayoutComponent
        },
        {
          path: 'basket',
          children: [
            {
              path: '',
              component: BasketComponent
            }
          ],
          component: MainLayoutComponent
        },
        {
          path: 'about',
          children: [
            {
              path: '',
              component: AboutComponent
            }
          ],
          component: MainLayoutComponent
        },
        {
          path: 'contact',
          children: [
            {
              path: '',
              component: ContactComponent
            }
          ],
          component: MainLayoutComponent
        },
      {
        path: 'bulk-orders',
        children: [
          {
            path: '',
            component: BulkContactComponent
          }
        ],
        component: MainLayoutComponent
      },
      {path: '**', redirectTo: '/404-page-not-found'}
    ]
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
