export class GiftCartItem{

  id: number;
  created_at: Date;
  updated_at: Date;
  recipient_email: string;
  recipient_name: string;
  voucher_amount: number;
  message: string;
  business_quantity: number;
  is_business: boolean;
  basket_id: string;

}
