

<!-- slider -->
<!-- about us -->
<section class="aboutus section-padding">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-12">
        <div class="history-title mb-md-40">
          <h2 class="text-light-black text-center"> <span class="text-light-green">Terms & Conditions</span></h2>
        </div>
      </div>

      <div class="col-lg-12">

        <p><strong>WHO WE ARE</strong></p>
        <p>The Fetch Website at www.fetch.je (&ldquo;the Site&rdquo;) is owned, and the services offered on the Site are operated by OMT (Jersey) Limited formerly known as Hubtixs Limited (&ldquo;OMT&rdquo;), a Company registered in Jersey Channel Islands, Company Number 126512 located at the address Postal Headquarters, La Rue Grellier, La Rue Des Pres Trading Estate, St Saviour, Jersey JE2 7QS, referred to in this Site as &ldquo;we&rdquo; or &ldquo;us&rdquo;.&nbsp;</p>
        <p>OMT allows third party Suppliers to list and sell their Products/Services at fetch.je. In each such case this is indicated on the respective Supplier page. While OMT, as a service provider, helps facilitate transactions that are carried out on the Site, OMT is neither the buyer nor the Supplier of the Product/Service.&nbsp;</p>
        <p>OMT provides a service for Suppliers and Consumers to negotiate and complete transactions for the Product/Service. Accordingly, the contract formed at the completion of a sale for these third party Products/Service is solely between Consumer and Supplier. OMT is not a party to this contract nor assumes any responsibility arising out of or in connection with it nor is it the Supplier&rsquo;s agent. The Supplier is responsible for the sale, fulfilment, customer service and any financial charges, fees or refunds of the Product/Service only.&nbsp;</p>
        <p>These are the Terms &amp; Conditions which governs your use of the Site.</p>
        <p><strong>WHO YOU ARE</strong></p>
        <p>On the Site we describe a visitor to and / or a user of the Site as &ldquo;you&rdquo;.</p>
        <p><strong>DEFINITIONS</strong></p>
        <p>"Consumer" means the end consumer of the Product/Service;</p>
        <p>"Product/Service" A online platform enabling the promotion, marketing and acquiring of&nbsp;certain products and services;</p>
        <p>"Price" means the price to the Consumer of the Product/Service;</p>
        <p>"Site" means www.fetch.je;</p>
        <p>"Supplier" means the Supplier of the Product/Service (not being OMT);</p>
        <p>"Supplier T&amp;C" means the terms and conditions of purchase for the Product/Service of the Supplier.</p>
        <p>"the Terms" means the terms and conditions of use of the Site.</p>
        <p>"Transaction" the purchase of any Products/Services from the relevant Supplier through the Site (each such purchase, a &ldquo;Transaction&rdquo;),means the terms and conditions of use of the Site.</p>
        <p><strong>UNDERSTANDING THE TERMS</strong></p>
        <p>Please carefully read these Terms. It is important that you read and understand the Terms and our role as set out above in Clause 1. By using the Site, you confirm your understanding of these Terms.</p>
        <p>There are additional terms and conditions governing the sale contract for the Products/Services as each Supplier will have Supplier T&amp;C relating to its own Products/Services. Please make sure that you have also read those Supplier T&amp;C before completing your transaction. We accept no contractual liability to you in respect of any Product/Service.&nbsp;</p>
        <p>The contract for the Product/Service is between you and the Supplier. Any queries or concerns relating to the Product/Service should be addressed to them directly.&nbsp;</p>
        <p>You undertake to us that the details you give to us while using the Channels are correct in particular that the credit or debit card you are using is your own and that there are sufficient funds to cover the cost of the Products/Service .&nbsp;</p>
        <p>If there are any changes to the details supplied to us by you it is your responsibility to inform us via our web form or by contacting our Customer Services as soon as possible.&nbsp;</p>
        <p>OMT does not make any warranty that the website is free from infection by viruses or anything else that has contaminating or destructive properties.&nbsp;</p>
        <p><strong>ACCEPTANCE OF THE TERMS</strong></p>
        <p>By continuing to use the Site, you confirm your acceptance of and your agreement to the Terms as amended from time to time. We are not obliged to notify you of any changes or updates to the Terms but you may be advised of such changes or updates by posts on the Site or directly.</p>
        <p>Your continued use of the Site following shall confirm your acceptance of such changes or updates. Any such changes or updates to the Terms shall not apply retroactively to any claim or dispute between you and us in connection with the Terms if such dispute arose prior to the last updated date applicable to the version of the Terms in which we included such change. We may, at any time, change or delete all or part of the Site.</p>
        <p>We may levy charges, alter or waive charges which we might require to use the Site. We may make special offers, for limited periods, at our sole discretion, to you or to any Site user.</p>
        <p><strong>WHEN&nbsp;DO THE TERMS APPLY?</strong></p>
        <p>The Terms apply to you when you access the Site whether via the Internet, Wireless Application Protocol, Mobile Network or in any other way.</p>
        <p><strong>AGREEMENT BETWEEN YOU AND US</strong></p>
        <p>By using the Site, you are entering into an Agreement between you and us, &ldquo;this Agreement&rdquo;.</p>
        <p><strong>ON SITE CONDUCT</strong></p>
        <p>In using the Site, you agree to obey the law, respect the rights of others. We invite you, on our Reviews page, to accurately record and to add comment on your experiences of using our Site. We will not, however, permit, accept, publish or retain in a customer review, language which we, in our absolute discretion, deem to be obscene, foul-mouthed, objectionable or defamatory. We reserve the right to terminate your use of the Site for any conduct which we consider to be inappropriate, or for any breach by you of this Agreement.</p>
        <p><strong>REGISTRATION</strong></p>
        <p>If, in our absolute discretion, we decide that you need to register to use any part(s) of the Site,&nbsp;we may reject, or require you to change, any user name, password or other information which you attempt to provide to us in registering. Your user name and password are for your exclusive personal use and must be kept confidential. You are fully&nbsp;responsible for any use of your user name and password. You agree to immediately inform us, as soon as you become or are made aware, of any breach or unauthorized use of your user name and password on your Site account by initially completing this form.</p>
        <p>Any personal data you provide to us will be handled in accordance with the Privacy Notice.</p>
        <p><strong>SITE AVAILABILITY</strong></p>
        <p>The Site may not be appropriate or available for use in jurisdictions outside Jersey, Channel Islands. When you access the Site, you do so at your own risk, and you are responsible for complying with all local laws, rules and regulations applicable in the territory from which you access the Site. We may limit the Site&rsquo;s availability, in whole or in part, to any person, geographic area or jurisdiction we choose, at any time and in our sole discretion.</p>
        <p><strong>OUR SERVICES AND PRODUCTS</strong></p>
        <p>On the Site, we provide you with information and services enabling you to pay for Products/Services. You will contract directly with the relevant Supplier who will perform the terms of the relevant contract.</p>
        <p><strong>DESCRIPTIONS, IMAGES, INFORMATION ON PRODUCTS/SERVICES ON THE SITE</strong></p>
        <p>Descriptions of, images of, and / or references to Products/Services appearing on the Site have been provided to us by the Suppliers and are included on the Site for guidance purposes only.&nbsp;</p>
        <p>We do not, and we will not accept any liability for any inaccuracy in their use. They do not suggest, imply or confirm our recommendation, approval or endorsement of such Products/Services. Except to the extent prohibited by applicable law, we reserve the right, with or without prior notice, at our sole discretion, at times and dates of our selection, to change any or all such descriptions, images, and references on the Site.</p>
        <p><strong>PRICES AND PURCHASES THROUGH THE SITE</strong></p>
        <p>Prices are described fully on the Site. Price(s) and / or availability of any Product/Service are subject to change without notice.&nbsp;</p>
        <p>If, for any reason, any Product/Service is listed and offered at an incorrect price or with other incorrect information, the Supplier retains the right to refuse to supply such Product/Service or to cancel any Transaction and to refund any payment which we may have received for any such Transaction.</p>
        <p>You are responsible for paying all prices, costs, charges and expenses incurred by you or on your behalf through your use of the Site, at the price or prices in effect at the time such charges are incurred, including without limitation any delivery, postage, shipping and / or handling charges.</p>
        <p>Should you use the Site to commence a Transaction, you will be asked to supply certain information including, without limitation, valid credit or debit card information or other payment information, your billing address, your email address, your postal address or other shipping information.</p>
        <p>You will use only a name you are authorised to use in connection with a Transaction and will update all of the preceding information as necessary to ensure that it at all times remains accurate and complete.</p>
        <p>Except as provided otherwise, all amounts contemplated in this Agreement will be expressed and displayed in British Pounds Sterling, and all payments contemplated by these Terms will be made in British Pounds Sterling.&nbsp;</p>
        <p>By using the Site, you undertake and warrant to us that you are of a legal age to enter into this Agreement, or if you are not, that you have obtained parental or guardian consent to enter into this Agreement and are fully authorised and have a legal right to use the payment instrument which you intend to use to complete the Transaction.</p>
        <p>Unless we expressly provide otherwise, all Transactions commenced through the Site are subject to this Agreement.</p>
        <p><strong>CONFLICT WITH SUPPLIER T&amp;C</strong></p>
        <p>We do not sell the Products/Services on the Site. The Suppliers sell the Products/Services on the Site. [In addition, we may provide links to the sites of other businesses.] Any and all Products/Services ordered through the Site,&nbsp;are subject to the Supplier T&amp;C of the relevant Supplier. The Supplier T&amp;C govern the terms of your purchase of a Product/Service. In the event of a conflict between the Supplier T&amp;C and the terms of this Agreement, the Supplier T&amp;C shall prevail.&nbsp;As such, both you and the Supplier must conform to any licencing rules and regulations with respect to the Products/Services on the Site.</p>
        <p><strong>PAYMENT RECEIPT AND PROCESSING BY US</strong></p>
        <p>We or our third-party payment processors will process the value of your Transaction(s) charges against your selected payment method on commencement of your Transaction.</p>
        <p>We will inform you if all or any portion of your Transaction is cancelled or if additional or different information is required to complete your Transaction and, on the Site, we will explain the difficulty which needs to be resolved. We will endeavour to find solutions but, we will not compromise your or our security in the payment process.</p>
        <p><strong>CONFIRMATION AND ELECTRONIC&nbsp;ISSUE OF PRODUCT/SERVICE TO YOUR SELECTED PRODUCT(S)</strong></p>
        <p>On receipt by us of your payment of the value of your Transaction(s) made on the Site, we will, on the Site, confirm the successful completion of your order(s) of your selected Product/Service. Your order confirmation will be made available to your designated email address for you to print or, if requested by you, will be mailed to you to the address you will have provided.</p>
        <p>All Products/Services require a confirmation reference number to be given by a Supplier.</p>
        <p><strong>SERVICE/PRODUCT DELIVERY CONDITIONS</strong></p>
        <p>Technical or operational problems may, from time to time, delay or prevent delivery to you of one or more Product/Services by the Supplier. The Site is not responsible for such delay or failure by the Supplier. This will be a matter between you and the Supplier.</p>
        <p><strong>NO CANCELLATIONS, AMENDMENTS OR REFUNDS</strong></p>
        <p>All payments made through the Site and all Transactions commenced through the Site cannot be changed or amended or cancelled by us and all payments made by you relating to your Transaction(s) on the Site are not refundable by the Site.</p>
        <p>Transactions are completed and performed by the relevant Supplier. Any requests to change or amend or cancel a Transaction must be directed to the relevant Supplier.</p>
        <p><strong>IDENTIFIABLE PERSONAL INFORMATION PROVIDED BY YOU</strong></p>
        <p>Information which you provide to us through the Site is governed by our Privacy Policy. You agree that all information you provide to us is true, accurate and complete, and you will ensure that such personal information is updated if required. Should you decide to make any of your personally identifiable information publicly available on the Site, you will do so at your own risk.</p>
        <p><strong>USE OF YOUR INFORMATION</strong></p>
        <p>By your provision and submission of information to us, you give us the right to use the information in accordance with our Privacy Policy, including, without limitation, by providing the information to selected third parties who will be engaged in the completion of any and all Transactions completed by you or on your behalf and in the provision of the Products which you have purchased in your Transaction(s). Verification of any and all information which you submit may be required by us prior to our acknowledgment or completion of any Transaction initiated by you on the Site.&nbsp;</p>
        <p><strong>THIRD-PARTY APPLICATIONS</strong></p>
        <p>The Site includes and can be used by you to access certain Third-Party Applications. Your access to or use of these Third-Party Applications may be governed by additional terms and conditions.</p>
        <p><strong>CONTACT US</strong></p>
        <p>Full contact details are available&nbsp;<a href="https://www.fetch.je/contact-us">here</a>. If you have any questions regarding the meaning or application of the Terms of this Agreement, please direct such questions to&nbsp;[info@fetch.je]. Please do not include Credit Card, Debit Card, other payment instrument information or other sensitive information in any correspondence. Please include your Transaction reference if known.</p>
        <p><strong>ERRORS, OMISSIONS, LIMITS OF LIABILITY</strong></p>
        <p>We are not responsible for examining or evaluating, and we do not warrant the offerings of, any Suppliers or the content of their websites or their Products/Services. We do not assume any responsibility or liability for the actions, Product/Services or content of all of these or any other third parties. We may share your information related to Transactions with specific Suppliers. You should carefully review their privacy statements and other conditions of use including Supplier T&amp;C.</p>
        <p>We are not and will not be liable for any errors or acts or omissions by any Supplier or their respective employees, agents, servants, employees or delegates.</p>
        <p><strong>JURISDICTION</strong></p>
        <p>This Agreement shall be governed by and construed in accordance with the laws of the Island of Jersey and the parties hereby irrevocably agree that the courts of the Island of Jersey are to have jurisdiction to settle any disputes which arise out of or in connection with this Agreement and that accordingly any suit, action or proceeding arising out of or in connection with this Agreement may be brought in such court.</p>

      </div>

    </div>
  </div>
</section>
