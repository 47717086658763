import {Component, Input, OnInit} from '@angular/core';
import {Vendor} from '../../models/Vendor';
import {Cart} from '../../models/Cart';
import {CartService} from '../../services/Cart.service';
import {SearchService} from '../../services/Search.service';
import {ProductOption} from '../../models/ProductOption';
import {CheckoutService} from '../../services/Checkout.service';
import {CustomerAddressUpdate} from '../../models/CustomerAddressUpdate';
import {Order} from '../../models/Order';

@Component({
  selector: 'app-cart-order',
  templateUrl: './cart-order.component.html',
  styleUrls: ['./cart-order.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class CartOrderComponent implements OnInit {

  @Input() order: Order;


  constructor() { }

  ngOnInit(): void {


  }

  getProductOptions(options: ProductOption[]){

    let optionString = '';

    let index = 0;
    if (options){
      for (const option of options){

        if (option.type){
          for (const optionValue of option.options){
            if (optionValue.selected){
              if (index){
                optionString += ', ' + optionValue.title;
                index++;
              }else{
                optionString += optionValue.title;
                index++;
              }
            }
          }
        }

      }
    }


    return optionString;

  }

  calculateDiscountTotal(){

    let discountTotal = 0;

    // for (const discount of this.order.discounts){
    //   discountTotal += discount.calculated_saving;
    // }

    return discountTotal;

  }

}
