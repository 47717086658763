
<div *ngIf="showBigFoot" class="section-padding footer-big">

  <div class="container">

    <div class="row">

      <div class="col-sm-12">

        <div class="footer-subscribe-row d-flex align-items-center">

            <!-- <div class="title-subscribe">
              <div class="d-flex align-items-end flex-grow-1">
                <h2 class="mb-0 mr-3">Don't </h2>
                <img src="assets/icons/mail.svg" alt="Subscribe">
              </div>
              <h2 class="mt-2 flex-grow-1 ml-lg-0 ml-3">miss out</h2>
            </div>

          <div class="vertical-separator">

          </div>

          <div class="subtitle-subscribe">
            <h4>Sign up to get the latest offers.</h4>
          </div>

          <form [formGroup]="signUpForm" (submit)="signUpNewsletter()">
            <div class="new-subscribe-form d-flex">

              <input type="text" formControlName="email" class="subscribe-input">
              <button>Sign Up</button>

            </div>
          </form>

        </div>

        <p class="text-center" *ngIf="signupMessage"><strong>{{signupMessage}}</strong></p> -->

        <!-- <p class="text-center">If you have a question, try looking at our <a class="font-weight-bold text-primary" routerLink="/about-fetch/faqs">FAQ section.</a></p> -->




      </div>

    </div>

  </div>

</div>

<footer class="main-footer u-line bg-gray" [class.pt-5]="!showBigFoot" style="padding-top:20px">

  <div class="container">

    <div class="px-3">

    </div>

  </div>

  <div class="container">
    <div class="row">

      <div class="col-sm-12">
        <a routerLink="/"><img alt="Logo" class="footer-brand" src="assets/img/logo3.svg"></a>
      </div>

    </div>

    <div class="row mt-3">

      <div class="col-md-6">

        <h6 class="slogan">Connecting you with local companies</h6>
        <ul class="d-flex">
          <li class="fw-600 mr-2"><a target="_blank" href="https://www.facebook.com/fetchjersey"><i class="fab fa-2x fa-facebook"></i>
          </a></li>
          <li class="fw-600 mr-2"><a target="_blank" href="https://twitter.com/fetch_je"><i class="fab fa-2x fa-twitter-square"></i></a></li>
          <li class="fw-600 mr-2"><a target="_blank" href="https://www.linkedin.com/company/42889986/admin/"><i class="fab fa-2x fa-linkedin-square"></i></a></li>
          <li class="fw-600 mr-2"><a target="_blank" href="https://www.instagram.com/fetchjersey/"><i class="fab fa-2x fa-instagram"></i></a></li>
        </ul>
      </div>

      <div class="col-md-3 col-6 mt-4 mt-md-0">

        <div class="footer-links">
          <h4 class="footer-link-header">About Fetch</h4>
          <ul>
            <li><a class="text-white" routerLink="/about-fetch">About Us</a>
            </li>
            <li><a class="text-white"  routerLink="/gift-cards">Vouchers</a>
            </li>
            <li><a class="text-white" routerLink="/about-fetch/faqs">FAQs</a>
            </li>
            <li><a class="text-white" routerLink="/contact">Contact Us</a>
            </li>
          </ul>
        </div>

      </div>
      <div class="col-md-3 col-6 mt-4 mt-md-0">

        <div class="footer-links">
          <h4 class="footer-link-header">Sell on Fetch</h4>
          <ul>
            <li><a class="text-white"  routerLink="/selling-on-fetch">Selling on Fetch</a>
            </li>
            <li><a class="text-white"  routerLink="/selling-on-fetch/delivery">Delivery By Jersey Post</a>
            </li>
            <li><a class="text-white"  href="https://merchant.fetch.je" target="_blank">Merchant Login</a>
            </li>
          </ul>
        </div>

      </div>

    </div>

  </div>
</footer>
<div class="copyright bg-gray">
  <div class="container">
    <div class="row footer-separator">
      <div class="col-lg-4 d-flex align-items-center">
        <a class="text-white fw-600 mr-4" routerLink="/terms-and-conditions">Terms & Conditions</a>
        <a class="text-white fw-600" routerLink="/privacy-policy">Privacy Policy</a>
      </div>
      <div class="col-lg-4 col-10 text-center medewithlove align-self-center">
        <img src="assets/img/JPG-black.svg" style="height:35px;">
      </div>
      <div class="col-lg-4">
        <div class="copyright-text"><span class="text-white">© <a class="text-white" href="">Jersey Post</a> - 2021 | All Right Reserved</span>
        </div>

      </div>
    </div>
  </div>
</div>
<!-- footer -->
