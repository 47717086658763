import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import {AuthService} from './Auth.service';
import { CustomerAddress } from '../models/CustomerAddress';

// @ts-ignore
@Injectable({
  providedIn: 'root',
})
// @ts-ignore
export class ApiService {

  hmac: string;
  code: string;
  shop: string;
  apikey: string;
  httpOptions: any;
  uploadOptions: any;

  constructor(
    public router: Router,
    public auth: AuthService,
    public http: HttpClient
  ) {

      this.auth.tokenObservable().subscribe(data => {
          this.apikey = data;

          this.httpOptions = {
              headers: new HttpHeaders({
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + this.apikey,
              }),
          };

          this.uploadOptions = {
              headers: new HttpHeaders({
                  Authorization: 'Bearer ' + this.apikey,
              }),
          };

      });

  }

  saveAddress(address:CustomerAddress) {
    return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'api/core/customer/addresses', {
          address: address
        }, this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });
  }

  apiGet(url): any{

      return new Promise((resolve, reject) => {
      this.http
        .get(environment.apiURL + 'api/' + url,  this.httpOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  getInstagram(): any{

      return new Promise((resolve, reject) => {
      this.http
        .get('https://fetch-cms-wp.azurewebsites.net/wp-json/wp/v2/instagram_image',  {})
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }

  apiPost(url, data): any{

      return new Promise((resolve, reject) => {
      this.http
        .post(environment.apiURL + 'api/' + url, data, this.httpOptions)
        .subscribe(
          res => resolve(res),
          err => reject(err)
        );
    });

  }

  upload(url, data: File): any{

      return new Promise((resolve, reject) => {

        const formData = new FormData();

        formData.append('file', data, data.name);

        this.http
        .post(environment.apiURL + 'api/' + url, formData, this.uploadOptions)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }



  login(data): any{

    data.client_id = 'website';
    data.client_secret = 'zAJyqYTUvQmuK9icLWOydCFvRJaaZWKN';
    data.grant_type = 'password';

    return new Promise((resolve, reject) => {
      this.http
        .post('https://' + environment.apiURL + 'oAuth/login', data)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          },
        );
    });

  }


}
