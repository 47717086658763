import { Injectable } from '@angular/core';
import {Search} from '../models/Search';
import {BehaviorSubject, Observable} from 'rxjs';
import algoliasearch from 'algoliasearch';

const searchClient = algoliasearch(
  '2V0CSY6NNV',
  '63b1c828601ad6724616e02c44b545cb'
);

// @ts-ignore
@Injectable({
  providedIn: 'root',
})
// @ts-ignore
export class SearchService {

  productSearchconfig = {
    indexName: 'products_query_suggestions',
    searchClient
  };

  merchantSearchconfig = {
    indexName: 'merchants',
    searchClient
  };

  searchSubject = new BehaviorSubject<any>(false);

  vendors: {
    Open: any[],
    Offline: any[]
  };

  searchCriteria: Search;

  public get search(){
    return this.searchCriteria;
  }

  public set search(value: Search){
    // tslint:disable-next-line:no-debugger
    debugger;
    this.searchCriteria = value;
  }

  constructor(){

    const checkSearchCriteria = sessionStorage.getItem('fetch-search');

    if (checkSearchCriteria){
      this.searchCriteria = JSON.parse(checkSearchCriteria);
    }else{
      this.searchCriteria = new Search();
    }

  }


  searchObservable(): Observable<any> {
    return this.searchSubject.asObservable();
  }

  update(){

    sessionStorage.setItem('fetch-search', JSON.stringify(this.searchCriteria));
    this.searchSubject.next(true);
  }

}
