
<!-- slider -->

    <!-- slider -->
    <!-- about us -->
    <section class="aboutus section-padding">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-12">
                    <div class="history-title mb-md-40">
                        <h2 class="text-light-black text-center"> <span class="text-light-green">Privacy Policy</span></h2>
                    </div>
                </div>

              <div class="col-lg-12">

                <p><strong>Fetch</strong>, (hereinafter referred to as &ldquo;we&rdquo;, &ldquo;us&rdquo; or &ldquo;our&rdquo;) are committed to protecting the privacy and security of any and all personally identifiable information (&ldquo;your information&rdquo;) which you provide to us and which we collect when you visit and use our&nbsp;<strong>Fetch</strong>&nbsp;Website (&ldquo;our Site&rdquo;). This Privacy Policy explains how we collect, store, protect and may use your information, how we protect your privacy and how you can choose ways in which your information is processed and managed securely.</p>
                <p>By accessing and using our Site, and by providing your information, you confirm your consent to our Privacy Policy.</p>
                <p><strong>WHO WE ARE AND WHO IS COLLECTING YOUR INFORMATION</strong></p>
                <p>The personal information which you provide to us when visiting and using our Site, is provided to and is collected by OMT (Jersey) Limited, a Company (Number 126512) registered in Jersey, Channel Islands at the address Postal Headquarters, La Rue Grellier, La Rue Des Pres Trading Estate, St Saviour, Jersey JE2 7QS.</p>
                <p><strong>SECURITY TO PROTECT YOUR INFORMATION AND THE PERSONAL INFORMATION OF OTHERS INCLUDING CHILDREN</strong></p>
                <p>The applicable Law: In accordance with the requirements the DATA PROTECTION (JERSEY) LAW 2018, which brings equivalence to the principles of the European General Data Protection Regulations (GDPR), and sets out the rights of individuals in respect of their personal data as well as the obligations and conditions organisations must follow to process it, we have established and implemented secure Data Management procedures to ensure your information is correctly stored, maintained and protected, including prevention of unauthorized access to areas of our Site, our Systems or our premises, where your information has been collected and securely stored by us.</p>
                <p>Personal Information of others including Children: When you visit and use our Site you are, as part of your use, asked to provide personal information,&nbsp;when and only for the purposes of completing a transaction or for opted-in services. The Data Protection (Jersey) Law 2018 strengthens rights of children around their data. Children have the same rights as adults over their personal data. Children need particular protection in place when their data is being processed because they may be less aware of the risks involved.</p>
                <p>Parental consent: You must ensure that parental consent is sought when collecting and providing the data of children for online services on our Site. We rely on you&nbsp;obtaining consent, to make sure that children know what they are consenting to. Privacy notices must be written in clear language and children, on whose behalf you are acting, must be informed, in age appropriate words, which they can understand, when their data is being collected and provided to us on our Site.</p>
                <p><strong>WAYS IN WHICH WE COLLECT INFORMATION AND WHAT INFORMATION WE COLLECT</strong></p>
                <p>Systems Collection: It is possible that you visit our Site without telling us who you are and you can choose to not provide us with any of your personal information. You should be aware, however, that our Systems may identify and record information such as details of your ISP, your IP address, which pages of our Site you accessed, the date(s) and time(s) of your visit(s) and other technical information relating to your visit to our Site.</p>
                <p>Your Visit(s) to our Site: We also, collect your personal information in instances where you visit our Site and choose to opt-in to receiving information from us, purchase any of our Products or services, take part in online competitions, surveys or if you simply contact us with a query or complaint. If you contact us by phone or write to us by mail or on email we keep a record of your contact details so that we can respond and maintain a conversation with you.</p>
                <p>Use of Cookies: On our Site, we use cookies which have a 30 day expiration, and establish a user 'session' cookie. Session cookies are necessary to collate your transaction and are automatically deleted once you close your browser. Cookies are text files containing small amounts of information which are downloaded to your device when you visit our Site. The Cookies are then sent back from your device to our Site on each subsequent visit which you make to our Site. For further information on Cookies you can visit www.allaboutcookies.org</p>
                <p>Your Control of Cookies &amp; Web Beacons: You can use a variety of tools to control Cookies, web beacons and similar technologies, including browser controls to block and delete cookies and controls from some third-party analytics service providers to opt out of data collection through web beacons and similar technologies. Your browser and other choices may impact your experiences when using our Site.</p>
                <p><strong>WHAT USE DO WE MAKE OF YOUR PERSONAL INFORMATION</strong></p>
                <p>Your personal information will be used by us in a variety of ways, including but not limited to:</p>
                <p>Responding to you when you make contact with us; Advising you of and promoting our services, such as special offers or promotions; Completing transaction(s) which you make on our Site, such as purchasing Products, services or&nbsp;Gift Vouchers; Delivering the products and services which you have purchased on our Site; Meeting our obligations to manage your order(s); Addressing matters arising from Supplier(s)&nbsp;which you have purchased on our Site; Managing your Subscription to our Newsletter, should you have subscribed; Providing you with information if you have opted-in to our messaging / marketing service; Asking you to provide a review of the service you received when using our services; Responding to a legal requirement to provide your data to a legally authorised authority. Before providing such information, we will advise you of our requirement to disclose such information; Share your information with third parties who assist and enable us to help, operate, provide, improve, integrate, customize, support and market our services to you; Satisfy a legitimate interest (which is not overridden by your data protection interests), such as for research and development; and Act on your behalf, with your consent, to enable us do so for a specific purpose.</p>
                <p><strong>WHO WILL, MAY OR CAN GAIN ACCESS TO YOUR PERSONAL INFORMATION</strong></p>
                <p>Third Party Service Providers: We work with third-party service providers who provide website and application development, hosting, maintenance, backup, storage, virtual infrastructure, payment processing, analysis and other services for us, which may require them to access or use information about you. If a service provider has a need to access your information to perform services on your and on our behalf, they do so under close instruction from us, including, where and when possible, contractual obligation, to abide by the Privacy Policy set out in this document.</p>
                <p>Our Directors, Shareholders, Executives and Employees: All personnel employed within our Company and / or personnel employed within Companies in which we have an ownership, &ldquo;Associated Companies&rdquo; are bound by the Privacy Policy set out in this document even though the access to your information is strictly contained to access being granted on a &ldquo;need to know&rdquo; basis which enables each person to perform the services of her or his role in the provision of our services to you. Key management will have outline, but not detailed, access to your information by way of financial and business performance reporting.</p>
                <p>Sharing with Associate Companies: We share &lsquo;restricted&rsquo; information we collect with our Associated Companies. The protections of this Privacy Policy apply to the information we share in these circumstances. Where personal information does not need to be shared, identifiable personal data is removed.</p>
                <p>Visiting other Websites when using our Site: You may, when visiting and using our Site, make access to another site. When accessing other sites the information which you might provide to them will be processed under their Privacy Policy which may not be the same as our Privacy Policy. We will not be responsible or held to be liable for the Privacy Policies and / or Data protection procedures of any other Site or organisation to which you choose to provide your personal information.</p>
                <p><strong>THIRD PARTY PAYMENT PROCESSORS</strong></p>
                <p>We require a certain personal information to enable us to process an order which you make on our Site. Information we require may include your name, address, telephone number and email address, your credit or debit card number, other related cardholder details and similar information to process other payment instruments. We do not directly collect, process or store your card or other payment instrument details for online transactions. These details will be on a secure page protected by 256 bit SSL Encryption Certificate issued by COMODO. This page both ensures that the information is encrypted and passes your personal data to the Payment Processing company which issued it, for authorization and collection to our Account. There are exceptions to these procedures if a telephone order is approved and your information is processed via offline methods. For information security reasons, we will normally direct you where possible to use our preferred online payment systems.</p>
                <p>We use information we collect from you to process your order. We normally retain this information for a period of up to 10 years, or until such time as you may provide a written request to&nbsp;info@fetch.je&nbsp;instructing us to remove your personal information from our records. All other details form part of our company records and will be retained in accordance with our Legal obligations for Company records.</p>
                <p><strong>MANAGEMENT AND RETENTION OF YOUR PERSONAL INFORMATION</strong></p>
                <p>Account information: We retain your account information for as long as your account is active and a reasonable period thereafter which can be up to 10 years, in case you should decide to re-activate your Account and the Services. We also retain some of your information as necessary to comply with our legal obligations, to resolve disputes, to enforce our agreements, to support business operations, and to continue to develop and improve our Services. Where we retain information for Services improvement and development, we take steps to provide information that does not identify you, and we use the information only to uncover collective insights about the use of our Services, not to specifically analyse personal characteristics about individuals.</p>
                <p>How long we keep information: The time period during which we keep your personal information depends on the types of information, as described in further detail below. After an appropriate time, we will either delete or anonymize your information or, if this is not possible (for example, because the information has been stored in backup archives), we will securely store your personal information and isolate it from further use until deletion is made possible.</p>
                <p>Information you share on our Site: If your Account is deactivated or disabled, some of your information and the content you have provided to us will be stored in order to allow our employees to make use of the Services information. For example, we will continue to retain messages you sent to us and we will continue to retain any actions/content you provided to us.</p>
                <p>Marketing information: You may choose to receive or to not receive direct marketing or other information from us. Please ensure you tick the correct box indicating your preference. If you have elected to receive marketing material from us, we retain information about your marketing preferences for a reasonable period of time from the date you last expressed interest in our Services, such as when you last opened an email from us. We retain information derived from cookies and other tracking technologies for a reasonable period of time from the date such information was created. If, at any time, you no longer wish to receive marketing or other information from us, you can unsubscribe by clicking on the unsubscribe link included on our Site.</p>
                <p><strong>DISCLOSURE TO THIRD PARTIES</strong><br />We will not sell, trade, or rent your personal information to others. We will not disclose information about you to any other person or organisation without your prior consent, save in the case of fraud or other criminal activity, to meet any requirements of a Supplier of Services to you, or in order to enforce our legal rights. For the avoidance of doubt, we reserve the right to pass your information on to third party PCI organisations in the interests of protecting you and/or us against potential fraudulent use of a credit or debit card or other payment instrument, or other fraudulent act, or if acting on your behalf in pursuance of a complaint with one of our Suppliers.</p>
                <p><strong>YOUR ENTITLEMENT TO GAIN ACCESS TO, UPDATE OR DELETE YOUR INFORMATION</strong></p>
                <p>Change of mind: If you have consented to our use of your personal information for a specific purpose, you have the right, at any time of your selection, to change your mind. Your change of mind and our acting on your instruction to make the change, will not have the effect of changing any of your personal information processing which will have already taken place. Where we are using your information because we or a third party service provider have a legitimate interest to do so, you have the right to object to that use though, in some cases, this may mean no longer being able to avail of our services.</p>
                <p>Access to and request to make changes to your personal information: You are entitled, subject to certain restrictions prescribed by law, to ask us to give you access to and to change, delete, or alter how we process your personal information.</p>
                <p>Corrections: You can ask us to correct any inaccuracies which you might identify in your personal information which we hold.</p>
                <p>Complaint and / or assistance: Should you be dissatisfied with any element of how we hold, manage or process your personal information you should, in the first instance, bring it to the attention of our Data Protection Manager at&nbsp;info@fetch.je&nbsp;(please reference &ldquo;Data Protection Complaint&rdquo; as the &ldquo;Subject&rdquo; or if you choose you have the right to complain to or request assistance from the Office of the Information Commissioner, Jersey at&nbsp;<a href="http://enquiries@oicjersey.org/">enquiries@oicjersey.org.</a></p>
                <p><strong>REVIEW AND RIGHT TO CHANGE OR AMEND OUR PRIVACY POLICY</strong></p>
                <p>Review: We constantly review and update our Privacy Policy.</p>
                <p>Causes of change: Changes in Law, changes or improvements in technology, improvements in the security practises of processing personal information, or other significant changes governing Data management, will require us to amend our Privacy Policy.</p>
                <p>Advice on changes: Any material change to our Privacy policy will be posted on our Site.</p>
                <p>Rights to change: We reserve the absolute rights to change or amend our Privacy Policy at any time we decide change or amendment to be necessary.</p>
                <p><strong>KEY CHANGES</strong></p>
                <p>Better navigation and user-friendly language. To make the policy easier to understand, we use clear, plain language and examples that illustrate our activities. We reformatted our privacy policy page, so you can quickly find the information that matters most to you.</p>

              </div>

            </div>
        </div>
    </section>
