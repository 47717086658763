<div class="checkout">

  <section>
    <div class="container large-container pt-4">
      <div class="row">
        <div class="col-lg-8">

          <div class="heading padding-tb-10 pb-4">
            <div>
              <h3 class="text-light-black title fw-400 no-margin coustard">Checkout</h3>
              <p class="text-light-black sub-title no-margin">Finalise and pay for your order.</p>
            </div>
          </div>

          <div *ngIf="state == 'payment'">

            <div class="lockFormCover" *ngIf="processingPayment">

              <div class="form-content">
                <img src="assets/img/tail-spin.svg" alt="">
                <h5 class="text-white mt-3" id="processing-anchor">
                  Payment processing...
                </h5>
                <p class="text-white">Please don't close or refresh this window.</p>
              </div>

            </div>

            <div class="checkout-primary-address" *ngIf="address && cartService.hasDelivery()">
              <div class="checkout-primary-address-title">Delivery Address</div>
              <div class="checkout-primary-address-name">{{address.recipient}}</div>
              <div class="checkout-primary-address-line">{{address.address_1}}, {{address.address_2}}, {{address.town_parish}}, {{address.post_code}}</div>
              <div class="checkout-primary-address-tel"><span>T.</span> {{address.telephone}}</div>
              <button class="btn btn-change btn-sm" (click)="state = 'address'">Change</button>
            </div>
            <div class="checkout-primary-address" *ngIf="cartService.collectionOnly()">
              <div class="checkout-primary-address-title">Collection</div>
              <div class="checkout-primary-address-line">You have selected "Collection" for all items in your order.</div>
            </div>

            <div class="checkout-gifts" *ngIf="addresses && cartService.loaded && cartService.giftItems(addresses).length > 0">
              <div class="checkout-gifts-title">Gift Deliveries</div>
              <div class="checkout-gifts-p">Set a delivery address for each gift in your order. An invoice will <span>not</span> be included with items marked as "gifts".</div>

              <div class="checkout-gift" [ngClass]="{'checkout-gift-first' : isFirst}" *ngFor="let item of cartService.giftItems(addresses); first as isFirst">
                <div class="row">
                  <div class="col-lg-10">
                    <div class="checkout-gift-header">
                      {{item.product.product_name}} x {{item.quantity}} <span>- from {{item.vendor.vendor.trading_name}}</span>
                    </div>
                    <div class="checkout-gift-address">
                      <nz-select [(ngModel)]="item.address" nzSize="large" (ngModelChange)="giftAddressChanged($event, item)">
                        <nz-option *ngFor="let a of addresses" [nzValue]="a" [nzLabel]="a.recipient + ', ' + a.address_1 + ', ' + a.address_2 + ', ' + a.town_parish + ', ' + a.post_code"></nz-option>
                      </nz-select>
                      <a (click)="addAddress(item)">+ Add an Address</a>
                    </div>
                    <div class="checkout-gift-message">
                      <div class="checkout-gift-header">
                        Message
                      </div>
                      <textarea class="form-control" placeholder="Gift message e.g. Happy Birthday" [(ngModel)]="item.gift_note"></textarea>
                    </div>
                  </div>
                  <div class="col-lg-2 checkout-gift-charge">
                    <div class="checkout-gift-header">
                      Charge
                    </div>
                    <div class="checkout-gift-charge-cost">
                      {{cartService.calculateItemDelivery(item.vendor, item) | currency: 'GBP'}}
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div class="checkout-payment" *ngIf="addresses && cartService.loaded">
              <div class="checkout-payment-title">Payment</div>
              <div class="checkout-payment-info" *ngIf="cartService.calculateBasketTotalAfterVouchers()"><i class="fas fa-exclamation-triangle"></i> Your card details are encrypted and securely stored by our payments provider using bank grade security.</div>
              <div class="checkout-payment-info" *ngIf="!cartService.calculateBasketTotalAfterVouchers()"><i class="fas fa-exclamation-triangle"></i> Please note as you are choosing to pay using a voucher, any remaining voucher balance will be automatically updated and can be used on your next order.</div>
              <app-checkout-payments (paymentInProcess)="paymentInProgress($event)" [paymentMethods]="savedPaymentMethods"></app-checkout-payments>
            </div>

          </div>
          <div *ngIf="state == 'address'">
            <app-checkout-address (addressSelected)="addressSelected($event)" [addresses]="addresses" [address]="address"></app-checkout-address>
          </div>
          <div *ngIf="state == 'gift-address'">
            <app-checkout-address (giftAddressSelected)="giftAddressSelected($event)" [addresses]="addresses" gift="true"></app-checkout-address>
          </div>
          <div *ngIf="state == 'collection-address'">
            <app-checkout-address (addressSelected)="addressSelected($event)" [addresses]="addresses" [address]="address" collection="true"></app-checkout-address>
          </div>
        </div>

        <div class="col-lg-4 pb-md-4" *ngIf="!processingPayment">
          <div class="sticky-container">
            <div class="inner-cart-container">
              <app-cart [checkoutPage]="true"></app-cart>
            </div>
          </div>
        </div>

      </div>
    </div>
  </section>

</div>