<app-page-loader *ngIf="!loaded"></app-page-loader>

<div class="green-header">

  <div class="container">

    <div class="row">

      <div class="col-12">
        <div>

          <h3 class="mb-2 text-white">Your Previous Orders</h3>
        </div>

      </div>

    </div>

  </div>


</div>

<div class="main-section">

  <div class="container">

    <div class="row">

      <div class="col-md-8">
        <div class="order-list">

          <div class="empty-state" *ngIf="!orderList && loaded">

              <h3>You haven't made any orders yet.</h3>
              <p>Start browsing today and make an order.</p>
              <button routerLink="/" class="btn btn-primary">Browse Fetch</button>

          </div>

          <div class="order-list-inner" *ngIf="loaded && orderList">

            <div class="single-order" *ngFor="let order of orderList" [routerLink]="order.id.toString()">

              <div class="row">

                <div class="col-md-8">
                  <div *ngFor="let orderVendor of order.vendors">
                    <h6 class="mb-0">{{orderVendor.vendor.trading_name}} - <strong>{{vendorTotal(orderVendor) | currency: 'GBP'}}</strong></h6>
                  </div>
                 </div>

                <div class="col-md-4 text-md-right mt-md-0 mt-3">

                  <span class="status" [class.bg-dark]="order.status === 'Processing'">{{order.status}}</span>
                  <p class="mb-0"><small>{{order.order_fulfill_time | date:'medium'}}</small></p>

                </div>

              </div>

            </div>

          </div>

        </div>
      </div>

      <div class="col-md-4">

        <div class="order-list" *ngIf="orderList && loaded">

          <h5>Why not start a new order?</h5>
          <p>We are always adding new products and welcoming new stores.</p>
          <button class="btn btn-primary" routerLink="/">New Order</button>

        </div>

      </div>

    </div>

  </div>

</div>
