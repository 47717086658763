import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from '../../services/Api.service';
import { CartService } from '../../services/Cart.service';
import { Router } from '@angular/router';
import { Vendor } from '../../models/Vendor';
import { Cart } from '../../models/Cart';
import { CustomerAddressUpdate } from '../../models/CustomerAddressUpdate';
import { PaymentMethod } from '../../models/PaymentMethod';
import { CheckoutService } from '../../services/Checkout.service';
import { Gtag } from 'angular-gtag';
import Timeout = NodeJS.Timeout;
import { GiftInfo } from '../../models/GiftInfo';
import { CustomerAddress } from 'src/app/models/CustomerAddress';
import { CartVendorItem } from 'src/app/models/CartVendorItem';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {

  loaded = false;
  state = 'payment';
  address: CustomerAddress;
  addresses;

  giftItem: CartVendorItem;

  processingPayment = false;

  customer;
  savedPaymentMethods: PaymentMethod[];
  savedAddresses;

  showError = false;
  showExpiredError = false;

  vendor: Vendor;
  missingFields: string[] = [];
  countdownTime: Date;

  orderError = false;
  cart: Cart;
  interval: Timeout;

  countdownTimeRemaining: any;

  constructor(public api: ApiService, public cartService: CartService, public router: Router, public checkoutService: CheckoutService, public gtag: Gtag) { }

  ngOnInit(): void {

    const promiseArray = [
      this.api.apiGet('core/customer'),
      this.api.apiGet('core/customer/savedCards'),
      this.api.apiGet('core/customer/addresses')
    ];

    Promise.all(promiseArray).then((res) => {

      this.customer = res[0];
      this.savedPaymentMethods = res[1];
      this.addresses = res[2];
      this.savedAddresses = res[2];
      if (this.addresses && this.addresses.length > 0) {

        if (this.cartService.cart.address_id) {
          this.address = this.addresses.find(a => a.id == this.cartService.cart.address_id);
        }
        else {
          this.address = this.addresses[0];
          this.cartService.updateCartAddress(this.address);
        }
      }
      else {
        if (this.cartService.collectionOnly()) {
          this.state = 'collection-address';
        }
        else {
          this.state = 'address';
        }
      }

      this.loaded = true;

      // if (!this.checkoutService.order.order_items.length){
      //   this.showExpiredError = true;
      //   this.cartService.hasCart = false;
      //   this.cartService.cart = null;
      //   this.cartService.vendorPreferences = null;
      //   this.cartService.cartId = null;
      //   sessionStorage.removeItem('fetch-cart');
      //   sessionStorage.removeItem('fetch-cart-vendor');
      // }else{
      //   this.calculateStopSaleCountdown();
      // }
    }).catch((error) => {
    });
  }

  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  addressSelected(address) {
    this.address = address;
    this.state = 'payment'
    this.cartService.updateCartAddress(this.address);
  }

  addAddress(basketVendorItem) {
    this.giftItem = basketVendorItem;
    this.state = 'gift-address';
  }

  giftAddressSelected(address) {
    this.giftItem.address_id = address.id;
    this.cartService.updateGiftItemAddress(this.giftItem.id, address.town_parish, address.id);
    this.state = 'payment'
  }

  giftAddressChanged(args, item) {
    this.cartService.updateGiftItemAddress(item.id, args.town_parish, args.id);
  }

  // willDeliver(area: string) {

  //   if (this.checkoutService.order.vendor.deliver_exclusions) {
  //     if (!this.checkoutService.order.vendor.deliver_exclusions.length) {
  //       return true;
  //     } else {
  //       const search = this.checkoutService.order.vendor.deliver_exclusions.findIndex((areaToCheck) => areaToCheck.area === area);
  //       return search < 0;
  //     }
  //   }

  // }




  paymentError(error) {

    console.log(error);
    this.processingPayment = false;
    this.showError = true;

  }

  // calculateStopSaleCountdown() {

  //   const timesToConsider = [];

  //   for (const item of this.checkoutService.order.order_items) {
  //     if (item.stop_sale_expiry) {
  //       timesToConsider.push(new Date(item.stop_sale_expiry));
  //     }
  //   }

  //   const ordredTimes = this.sortByStartDate(timesToConsider);

  //   this.countdownTime = ordredTimes[0];

  //   this.interval = setInterval(() => {

  //     if (this.countdownTime) {
  //       this.countdownTimeRemaining = {};

  //       // Get today's date and time
  //       const now = new Date().getTime();

  //       // Find the distance between now and the count down date
  //       const distance = this.countdownTime.getTime() - now;

  //       // Time calculations for days, hours, minutes and seconds
  //       this.countdownTimeRemaining.days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //       this.countdownTimeRemaining.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  //       this.countdownTimeRemaining.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //       this.countdownTimeRemaining.seconds = Math.floor((distance % (1000 * 60)) / 1000);

  //       // If the count down is finished, Reload the session.
  //       if (distance < 0) {
  //         clearInterval(this.interval);
  //         location.reload();
  //       }
  //     }

  //   }, 1000);

  // }

  private getTime(date?: Date) {
    return date != null ? new Date(date).getTime() : 0;
  }

  public sortByStartDate(array: Date[]): Date[] {
    return array.sort((a: Date, b: Date) => {
      return this.getTime(a) - this.getTime(b);
    });
  }

  // initialiseCheckout() {

  //   const promiseArray = [
  //     this.api.apiGet('core/customer'),
  //     this.api.apiGet('core/customer/savedCards'),
  //     this.api.apiGet('core/customer/addresses'),
  //     this.checkoutService.initializeCheckout()
  //   ];

  //   Promise.all(promiseArray).then((res) => {

  //     this.customer = res[0];
  //     this.savedPaymentMethods = res[1];
  //     this.savedAddresses = res[2];
  //     this.checkoutService.giftInfo = new GiftInfo();
  //     this.loaded = true;

  //     if (!this.checkoutService.order.order_items.length) {
  //       this.showExpiredError = true;
  //       this.cartService.hasCart = false;
  //       this.cartService.cart = null;
  //       this.cartService.vendorPreferences = null;
  //       this.cartService.cartId = null;
  //       sessionStorage.removeItem('fetch-cart');
  //       sessionStorage.removeItem('fetch-cart-vendor');
  //     } else {
  //       this.calculateStopSaleCountdown();
  //     }

  //     console.log(this.checkoutService.order);
  //   }).catch((error) => {
  //     // TODO ERROR STATE
  //     // TODO TRY TO BREAK IT
  //     console.log(error);
  //   });

  // }

  updateDeliveryMethod(method) {
    this.checkoutService.deliverTo = method;
  }

  paymentInProgress(inProgress) {

    this.processingPayment = inProgress;

  }

  paymentSuccess(payment) {
    
  }
  // paymentSuccess(payment) {

  //   if (!this.checkoutService.order.is_collection) {
  //     payment.deliveryMethod = this.checkoutService.deliverTo;
  //   }

  //   this.checkoutService.processSuccessfulPayment(payment).then((res) => {

  //     this.gtag.event('purchase', {
  //       transaction_id: this.checkoutService.order.id,
  //       event_category: 'Fetch.je Website',
  //       value: this.checkoutService.order.calculated_order_total,
  //       tax: 0,
  //       currency: 'GBP',
  //       shipping: this.checkoutService.order.delivery_charge,
  //       items: this.checkoutService.arrangeItemsForTagManager()
  //     });

  //     sessionStorage.clear();
  //     sessionStorage.setItem('trackingOrder', res.Order.id);

  //     // CLEAR EVERYTHING DOWN FOR A NEW ORDER
  //     this.checkoutService.cartId = null;
  //     this.cartService.hasCart = false;
  //     this.cartService.cartId = null;
  //     this.cartService.cart = null;

  //     // TAH MANAGER STUFF

  //     this.router.navigateByUrl('/order-complete');

  //   }).catch((err) => {

  //     this.orderError = true;

  //   });

  // }

  // checkValid() {



  // }

}
