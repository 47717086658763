import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-selling-on-fetch',
  templateUrl: './selling-on-fetch.component.html',
  styleUrls: ['./selling-on-fetch.component.css']
})
export class SellingOnFetchComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
