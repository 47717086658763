export class GiftRequest{

  id:number;
  created_at: Date;
  updated_at: Date;
  recipient_email: string;
  sender_email: string;
  stripe_payment_id: string;
  recipient_name: string;
  voucher_amount: number;
  sender_name: string;
  message: string;
  status: string;
  is_business: boolean;
  voucher_data_new: boolean;

}
