import { Component, OnInit } from '@angular/core';
import {GiftRequest} from '../../models/GiftRequest';
import {Router} from '@angular/router';

@Component({
  selector: 'app-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.scss']
})
export class VouchersComponent implements OnInit {

  constructor(public router: Router) { }

  voucherAmount: number;
  voucherType: string;

  ngOnInit(): void {
  }

  selectVoucher(amount: number){
    this.voucherType = 'Fixed';
    this.voucherAmount = amount;
  }

  generateVoucherPurchase(){

    const voucherPurchase = new GiftRequest();
    voucherPurchase.voucher_amount = this.voucherAmount;

    sessionStorage.setItem('voucher-purchase', JSON.stringify(voucherPurchase));

    this.router.navigateByUrl('/gift-card-checkout');

  }

}
