export class CustomerAddress{

  id: number;
  customerID: number;
  building_name: string;
  house_number: string;
  address_1: string;
  address_2: string;
  town_parish: string;
  country: string;
  instructions: string;
  post_code: string;
  recipient: string;
  telephone: string;
  what3: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;

}
