import { Component, OnInit } from '@angular/core';
import {OrderService} from '../../services/Order.service';
import {Order} from '../../models/Order';
import {ActivatedRoute, Router} from '@angular/router';
import { ProductOption } from 'src/app/models/ProductOption';
import { OrderVendorItem } from 'src/app/models/OrderVendorItem';
import * as moment from 'moment';
import { OrderVendor } from 'src/app/models/OrderVendor';
import { ApiService } from 'src/app/services/Api.service';

@Component({
  selector: 'app-old-order',
  templateUrl: './old-order.component.html',
  styleUrls: ['./old-order.component.scss']
})
export class OldOrderComponent implements OnInit {

  loaded = false;
  order: Order;
  email: string;
  reviewRating: number;
  reviewContent: number;
  noPermission = false;

  constructor(public orderTracker: OrderService, public router: Router, public route: ActivatedRoute, public api: ApiService) { }

  ngOnInit(): void {

    this.orderTracker.getSingleOrder(this.route.snapshot.params.id).then((res) => {
      this.order = res.Order;
      this.email = res.Email;
      this.loaded = true;
    }).catch((err) => {

      this.noPermission = true;

    });
  }

  productOptions(options: ProductOption[]) {
    let optionString = '';
    let index = 0;
    if (options) {
      for (const option of options) {
        if (option.type) {
          for (const optionValue of option.options) {
            if (optionValue.selected) {
              if (index) {
                optionString += ', ' + optionValue.title;
                index++;
              } else {
                optionString += optionValue.title;
                index++;
              }
            }
          }
        }
      }
    }
    return optionString;
  }

  fulfillmentDate(orderVendorItem: OrderVendorItem) {
    let date = moment(orderVendorItem.fulfillment_date);
    if (orderVendorItem.fulfillment_method == 'Delivery') {
      if (orderVendorItem.is_asap) {
        return 'ASAP';
      }
      else if (date.format('HH:mm') == '00:00') {
        return date.format('DD MMMM');
      }
      else {
        return date.format('DD MMMM') + ' at <strong>' + date.format("HH:mm") + '</strong>';
      }
    }
    else {  // Collection
      if (date.format('HH:mm') == '00:00') {
        return date.format('DD MMMM');
      }
      else {
        return date.format('DD MMMM') + ' at <strong>' + date.format("HH:mm") + '</strong>';
      }
    }
  }

  fulfillmentAddress(orderVendor: OrderVendor, orderVendorItem: OrderVendorItem) {
    if (orderVendorItem.fulfillment_method == 'Collection') {
      return orderVendor.vendor.address_1 + ", " + orderVendor.vendor.address_2 + ", " + orderVendor.vendor.town_parish + ", " + orderVendor.vendor.post_code;
    }
    else {
      return orderVendorItem.recipient + ", " + orderVendorItem.address;  
    }
  }

  orderTotal() {
    let total = 0.0;
    for (let orderVendor of this.order.vendors) { 
     total += Number(orderVendor.vendor_total);
      total += Number(orderVendor.delivery_charge);  
    }
    return total;
  }
  
  discountTotal() {
    let total = 0.0;
    for (let orderVendor of this.order.vendors) { 
      if(orderVendor.discount) {
        total += Number(orderVendor.discount.calculated_saving);
      }
    }
    return total;
  }

  vouchersTotal() {
    let total = 0.0;
    for (let orderVoucher of this.order.vouchers) { 
      total += Number(orderVoucher.amount_used);
    }
    return total;
  }

  vocabForDelivery(isCollection){

    if (isCollection){
      return 'collection';
    }else{
      return 'delivery';
    }

  }

  leaveReview(){

    // this.api.apiPost('core/customer/orders/' + this.route.snapshot.params.id + '/review', {
    //   description: this.reviewContent,
    //   rating: this.reviewRating
    // }).then((res) => {
    //   this.order.review.push(res);
    // });

  }

}
