<div class="order">

  <section>
    <div class="container large-container pt-4" *ngIf="order">
      <div class="row">
        <div class="col-12">

          <div class="order-header">
            <h3 class="coustard fw-400">Order #{{order.id}}</h3>
            <p>Thank you for placing an order with Fetch.je. We have notified the local merchants of your order, it has been accepted and is being processed. Your order number is <strong>{{order.id}}</strong>.</p>
          </div>

          <div class="order-details">
            <div class="order-details-header">
              Your Order
            </div>
            <div class="order-details-customer">
              <div class="row">
                <div class="col-sm-12 col-md-6">
                  <div><strong>Customer</strong></div>
                  <div>{{order.customer.first_name}} {{order.customer.last_name}}</div>
                  <div>{{email}}</div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div><strong>Order</strong></div>
                  <div>{{order.id}}</div>
                  <div>{{orderTotal() - vouchersTotal() | currency: 'GBP'}} PAID</div>
                </div>
              </div>
            </div>

            <div class="order-details-vendor" *ngFor="let vendor of order.vendors">
              <div class="row">
                <div class="col-sm-12 col-md-8">
                  <div class="order-details-vendor-merchant">
                    <span>Merchant</span> {{vendor.vendor.trading_name}}
                  </div>
                </div>
                <div class="col-sm-12 col-md-4 order-details-right">
                  {{vendor.vendor.phone_number}}
                </div>
              </div>
              <div class="order-details-vendor-item" *ngFor="let item of vendor.items">
                <div class="row mb-2">
                  <div class="col-sm-12 col-md-9">
                    <div class="order-details-vendor-item-details">
                      {{item.product_name}}<br />Quantity: {{item.quantity}}<span *ngIf="productOptions(item.options)" style="margin-left:0">, Options: {{productOptions(item.options)}}</span>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-3 order-details-right">
                    <strong>{{item.total_cost | currency: 'GBP'}}</strong>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-9">
                    <div class="order-details-vendor-item-details">
                      <strong>{{item.fulfillment_method}}</strong><span [innerHTML]="fulfillmentDate(item)"></span>
                      <div>{{fulfillmentAddress(vendor, item)}}</div>
                      <div *ngIf="item.gift"><strong>THIS IS A GIFT</strong></div>
                      <div *ngIf="item.gift_note">Gift message: <em>{{item.gift_note}}</em></div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-3 order-details-right">
                    <strong *ngIf="item.fulfillment_method == 'Delivery'">{{item.delivery_charge | currency: 'GBP'}}</strong>
                  </div>
                </div>
              </div>
            </div>

            <div class="order-details-totals">
              <div class="row mb-2" *ngIf="discountTotal()">
                <div class="col-sm-12 col-md-9">
                  <div class="order-details-vendor-item-details">
                    <strong>Discounts</strong>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3 text-right">
                  -{{discountTotal() | currency: 'GBP'}}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-9">
                  <div class="order-details-vendor-item-details" style="font-size:16px;">
                    <strong>ORDER TOTAL</strong>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3 text-right" style="font-size:16px;">
                  <strong>{{orderTotal() | currency: 'GBP'}}</strong>
                </div>
              </div>
              <div class="order-details-vouchers">
              <div class="row mb-2" *ngFor="let orderVoucher of order.vouchers">
                <div class="col-sm-12 col-md-9">
                  <div class="order-details-vendor-item-details">
                    <strong>Voucher - <em>{{orderVoucher.voucher.code}}</em></strong>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3 text-right">
                  -{{orderVoucher.amount_used | currency: 'GBP'}}
                </div>
              </div>
              <div class="row" *ngIf="vouchersTotal()">
                <div class="col-sm-12 col-md-9">
                  <div class="order-details-vendor-item-details" style="font-size:16px;">
                    <strong>AMOUNT PAID</strong>
                  </div>
                </div>
                <div class="col-sm-12 col-md-3 text-right" style="font-size:16px;">
                  <strong>{{orderTotal() - vouchersTotal() | currency: 'GBP'}}</strong>
                </div>
              </div>
              </div>
            </div>


          </div>
        </div>

      </div>
    </div>
  </section>

</div>
<!-- 

<div class="checkout-header">

  <div class="container">

    <div class="row">

      <div class="col-12">
        <div *ngIf="loaded">

          <h2 class="mb-0">Order No: {{order.id}}</h2>
          <h5>{{order.vendor.trading_name}}</h5>

        </div>

      </div>

    </div>

  </div>

</div>

<div class="checkout-body bg-light" *ngIf="loaded">

  <div class="custom-shape-divider-top-1607353413">
    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
      <path d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z" opacity=".25" class="shape-fill"></path>
      <path d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z" opacity=".5" class="shape-fill"></path>
      <path d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z" class="shape-fill"></path>
    </svg>
  </div>

  <div class="tracker-main">

    <div class="container">


      <div class="row mt-5">

        <div class="col-sm-7">

          <div class="d-flex mb-5">
            <div class="icon">
              <img src="assets/img/correct.svg" alt="">
            </div>
            <div class="text">
              <h2 class="mb-2" *ngIf="order.status === 'Processing'">Order Confirmed</h2>
              <h2 class="mb-2" *ngIf="order.status === 'Completed'">Order Completed</h2>
              <h6 class="mb-2">Thank you for your order from {{order.vendor.trading_name}}.</h6>
              <h6 *ngIf="order.vendor.rapid_delivery">Estimated {{vocabForDelivery(order.is_collection) | titlecase}} Time: {{order.order_fulfill_time | date:'short'}}</h6>
            </div>
          </div>

          <nz-steps class="order-track" [nzCurrent]="getOrderStep()" nzDirection="vertical" size="normal">
              <nz-step nzTitle="Order Confirmed" nzDescription="Your order has been paid for and received by {{order.vendor.trading_name}}"></nz-step>
              <nz-step [nzIcon]="''" nzTitle="Processing" nzDescription="{{order.vendor.trading_name}} is preparing your order for {{vocabForDelivery(order.is_collection)}}"></nz-step>
              <nz-step [nzStatus]="getStatus()" nzTitle="Complete" nzDescription="Your order has been {{vocabForDeliveryDone(order.is_collection)}}."></nz-step>
            </nz-steps>

        </div>

        <div class="col-sm-5">

          <app-cart-order [order]="order"></app-cart-order>

          <div class="checkout-card mb-5" *ngIf="!order.is_gift">

            <h4 class="mb-4" *ngIf="!order.is_collection">{{vocabForDelivery(order.is_collection) | titlecase}} To</h4>
            <h4 class="mb-4" *ngIf="order.is_collection">{{vocabForDelivery(order.is_collection) | titlecase}} From</h4>
            <hr>
            <div class="single-address" *ngIf="order.is_collection">
              <address>
                {{order.vendor.address_1}} {{order.vendor.address_2}},<br/>
                {{order.vendor.town_parish}},<br/>
                {{order.vendor.post_code | uppercase}}
              </address>
            </div>

            <div class="single-address" *ngIf="!order.is_collection">
              <address class="mb-0 fs-14">
                {{order.delivery_address.building_name | titlecase}} {{order.delivery_address.address_1 | titlecase}}<span *ngIf="order.delivery_address.address_2"> {{order.delivery_address.address_2 | titlecase}}</span>,<br/>
                {{order.delivery_address.town_parish}},<br/>
                {{order.delivery_address.post_code | uppercase}}
              </address>

              <div *ngIf="order.delivery_address.instructions" class="mt-2">
                <hr>
                <h6>Delivery Instructions:</h6>
                <p class="mb-0">{{order.delivery_address.instructions}}</p>
              </div>
              <div *ngIf="order.orderNotes" class="mt-2">
                <hr>
                <h6>Order Notes:</h6>
                <p class="mb-0">{{order.orderNotes}}</p>
              </div>
            </div>

          </div>

          <div class="checkout-card mb-5" *ngIf="order.is_gift">

            <h4 class="mb-4">Gift for {{order.gift_info.recipient_name}}</h4>
            <hr>
            <div class="single-address" *ngIf="order.is_collection">
              <h6>Collect From:</h6>
              <address>
                {{order.vendor.address_1}} {{order.vendor.address_2}},<br/>
                {{order.vendor.town_parish}},<br/>
                {{order.vendor.post_code | uppercase}}
              </address>
            </div>

            <div class="single-address" *ngIf="!order.is_collection">
              <h6>Deliver To:</h6>
              <address class="mb-0 fs-14">
                {{order.gift_info.building_name | titlecase}} {{order.gift_info.address_1 | titlecase}}<span *ngIf="order.gift_info.address_2"> {{order.delivery_address.address_2 | titlecase}}</span>,<br/>
                {{order.gift_info.town_parish}},<br/>
                {{order.gift_info.post_code | uppercase}}
              </address>

              <div *ngIf="order.gift_info.instructions" class="mt-2">
                <hr>
                <h6>Delivery Instructions:</h6>
                <p class="mb-0">{{order.gift_info.instructions}}</p>
              </div>
            </div>

            <div *ngIf="order.gift_info.message" class="mt-2">
              <hr>
              <h6>Gift Message:</h6>
              <p class="mb-0">{{order.gift_info.message}}</p>
            </div>

          </div>

        </div>

      </div>

    </div>

  </div>

</div> -->