import { Component, OnInit } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ApiService} from '../../services/Api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  message: string;

  constructor(public fb: FormBuilder, public router: Router, public api: ApiService) {
    this.contactForm = fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      phone: [''],
      subject: ['', Validators.required],
      cntnt: ['', Validators.required]
    });
  }

  public sendEmail(data) {

    if(this.contactForm.valid){
      this.api.apiPost('public/contact-form', data).then((res) => {
        this.message = 'Thank you for your e-mail, it has been forwarded to the team. Someone will come back to you shortly.';
      }).catch((err) => {
        console.log(err);
        this.message = 'There has been an error sending this e-mail, please try again.';

      });
    }else{
      console.log(this.contactForm)
      this.message = 'Please complete all the fields.';
    }

  }

  ngOnInit(): void {
  }

}
