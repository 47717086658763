import {Component, OnInit, ViewChild} from '@angular/core';
import {AuthService} from '../../services/Auth.service';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {FacebookLoginProvider, SocialAuthService} from 'angularx-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  error = '';
  returnUrl: string;
  @ViewChild('passwordField') passwordInput;

  constructor(public auth: AuthService, public fb: FormBuilder, public router: Router, private route: ActivatedRoute, public socialLogin: SocialAuthService) {

    this.loginForm = fb.group({
      email: ['', [Validators.required, Validators.minLength(4)]],
      password: ['', [Validators.required]],
    });

  }

  get email(): AbstractControl {
    return this.loginForm.controls.email;
  }
  get password(): AbstractControl {
    return this.loginForm.controls.password;
  }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams.returnUrl;
  }

  submitForm(): void {
    if (this.email.invalid || this.password.invalid) {
      this.error = 'Please complete both fields';
      return;
    }
    this.auth.doLogin(this.loginForm.value).then((res) => {
      if(res.user.vendorID){
        this.error = 'You cannot log in with a merchant account';
      }else{
        this.auth.desktopAuthStore(res).then((res2) => {
          if (this.returnUrl){
            // RETURN TO PAGE THAT THEY WERE TRYING TO ACCESS
            this.router.navigateByUrl(this.returnUrl);
          }else{
            // THEY MANUALLY CLICKED LOG IN SO JUST TAKE THEM TO HOME PAGE
            this.router.navigateByUrl('/');
          }
        });
      }
    }).catch((err) => {
      this.error = err.error.Error;
    });
  }

  loginWithFacebook(){

    this.socialLogin.signIn(FacebookLoginProvider.PROVIDER_ID).then((res) => {
      this.auth.facebookLogin(res).then((res2) => {
        this.auth.desktopAuthStore(res2).then((res3) => {
          if (this.returnUrl){
            // RETURN TO PAGE THAT THEY WERE TRYING TO ACCESS
            this.router.navigateByUrl(this.returnUrl);
          }else{
            // THEY MANUALLY CLICKED LOG IN SO JUST TAKE THEM TO HOME PAGE
            this.router.navigateByUrl('/');
          }
        });
      });
    });

  }

  goToRegister(){

    this.router.navigate(['register'], {queryParams: {returnUrl: this.returnUrl}});

  }

  togglePasswordView(){

    if (this.passwordInput.nativeElement.getAttribute('type') === 'password'){
      this.passwordInput.nativeElement.setAttribute('type', 'text');
    }else{
      this.passwordInput.nativeElement.setAttribute('type', 'password');
    }

  }

}
