import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Component({
  selector: 'app-generic-dynamic-page',
  templateUrl: './generic-dynamic-page.component.html',
  styleUrls: ['./generic-dynamic-page.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class GenericDynamicPageComponent implements OnInit {

  content: any;
  html: SafeHtml;

  constructor(public route: ActivatedRoute, private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.route.data.subscribe((data) => {
      this.content = data['content'];
      this.html = this.sanitizer.bypassSecurityTrustHtml(this.content[0].special_content.rendered) ;
      this.loadStyles(this.content[0].slug);
    });
  }


  loadStyles(slug) {
    const head = document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.type = 'text/css';
    style.id = 'style-' + slug;
    style.appendChild(document.createTextNode(this.content[0].special_content.css));
    head.appendChild(style);
  }

}
