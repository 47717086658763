import {Category} from './Category';

export class Search{

  mustBe: string[];
  rating: number;
  sort: string;
  price: number;
  categories: Category[];
  orderType: string;
  location: string;
  is_asap: boolean;
  when_label: string;
  scheduled_date: Date;

  constructor(){
    this.mustBe = [];
    this.rating = null;
    this.sort = 'Alphabetical';
    this.price = null;
    this.is_asap = true;
    this.when_label = 'ASAP';
    this.orderType = 'Everything';
    this.location = 'Everywhere';
    this.categories = [];
  }

}
