import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/Auth.service';
import {SearchService} from '../../services/Search.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-navbar-home',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavBarHomeComponent implements OnInit {

  authState = 'guest';
  user: any;
  isOpen: boolean;

  constructor(public auth: AuthService, public search: SearchService, public router: Router){
    this.auth.userObservable().subscribe((data) => {
      this.updateAuthState(data);
    });

  }

  ngOnInit(): void {
  }

  toggleIsOpen(){

    this.isOpen = !this.isOpen;

  }

  updateAuthState(userData): void{

    if(!userData){
      this.authState = 'guest';
    }else{
      this.authState = 'loggedIn';
      this.user = userData;
    }
    console.log(userData);

  }

  delivery30(){
    this.search.searchCriteria.categories = [];
    this.search.searchCriteria.mustBe = [];
    this.search.searchCriteria.orderType = 'Delivery';
    this.search.searchCriteria.mustBe.push('Under 30 Min');
    this.search.update();
    this.router.navigateByUrl('/browse');
  }

  deliverHour(){
    this.isOpen = false;
    this.search.searchCriteria.categories = [];
    this.search.searchCriteria.mustBe = [];
    this.search.searchCriteria.orderType = 'Delivery';
    this.search.searchCriteria.mustBe.push('Under 60 Min');
    this.search.update();
    this.router.navigateByUrl('/browse');

  }

  homeWares(){
    this.isOpen = false;

    this.search.searchCriteria.categories = [];
    this.search.searchCriteria.mustBe = [];

    this.search.searchCriteria.categories.push({
      category_name: 'Crafted Goods',
      id: 9
    });

    this.search.searchCriteria.categories.push({
      category_name: 'Home & Garden',
      id: 17
    });

    this.search.update();
    this.router.navigateByUrl('/browse');

  }

  genuineJersey(){
    this.isOpen = false;

    this.search.searchCriteria.categories = [];
    this.search.searchCriteria.mustBe = [];
    this.search.searchCriteria.mustBe.push('Genuine Jersey');
    this.search.update();
    this.router.navigateByUrl('/browse');

  }

  pharmacy(){
    this.isOpen = false;

    this.search.searchCriteria.categories = [];
    this.search.searchCriteria.mustBe = [];

    this.search.searchCriteria.categories.push({
      category_name: 'Pharmacy',
      id: 21
    });

    this.search.searchCriteria.categories.push({
      category_name: 'Health & Wellness',
      id: 19
    });

    this.search.update();
    this.router.navigateByUrl('/browse');

  }

  newsagents(){
    this.isOpen = false;

    this.search.searchCriteria.categories = [];
    this.search.searchCriteria.mustBe = [];

    this.search.searchCriteria.categories.push({
      category_name: 'Groceries',
      id: 6
    });
    this.search.update();
    this.router.navigateByUrl('/browse');


  }

  alcohol(){
    this.isOpen = false;

    this.search.searchCriteria.categories = [];
    this.search.searchCriteria.mustBe = [];

    this.search.searchCriteria.categories.push({
      category_name: 'Alcohol',
      id: 7
    });
    this.search.update();
    this.router.navigateByUrl('/browse');

  }


  everything(){
    this.isOpen = false;

    this.search.searchCriteria.categories = [];
    this.search.searchCriteria.mustBe = [];
    this.search.update();
    this.router.navigateByUrl('/browse');
  }

  essentials(){
    this.isOpen = false;

    this.search.searchCriteria.categories = [];
    this.search.searchCriteria.mustBe = [];

    this.search.searchCriteria.categories.push({
      category_name: 'Groceries',
      id: 6
    });

    this.search.searchCriteria.categories.push({
      category_name: 'Bakery',
      id: 8
    });

    this.search.searchCriteria.categories.push({
      category_name: 'Fishmonger',
      id: 24
    });

    this.search.searchCriteria.categories.push({
      category_name: 'Butcher',
      id: 22
    });

    this.search.update();
    this.router.navigateByUrl('/browse');

  }

  giftCards(){
    this.isOpen = false;
    this.router.navigateByUrl('/gift-cards');
  }

  fineDining(){

    this.search.searchCriteria.mustBe = [];
    this.search.searchCriteria.categories = [];
    this.isOpen = false;

    this.search.searchCriteria.categories.push({
      category_name: 'Fine Dining',
      id: 2
    });

    this.search.searchCriteria.categories.push({
      category_name: 'Restaurant',
      id: 1
    });

    this.search.update();
    this.router.navigateByUrl('/browse');
  }

  allTakeAways(){

    this.search.searchCriteria.mustBe = [];
    this.search.searchCriteria.categories = [];
    this.isOpen = false;
    this.search.searchCriteria.categories.push({
      category_name: 'Fine Dining',
      id: 2
    });

    this.search.searchCriteria.categories.push({
      category_name: 'Restaurant',
      id: 1
    });

    this.search.searchCriteria.categories.push({
      category_name: 'Indian',
      id: 3
    });

    this.search.searchCriteria.categories.push({
      category_name: 'Chinese',
      id: 4
    });

    this.search.searchCriteria.categories.push({
      category_name: 'Mexican',
      id: 5
    });

    this.search.searchCriteria.categories.push({
      category_name: 'Bakery',
      id: 8
    });

    this.search.searchCriteria.categories.push({
      category_name: 'Chip Shop',
      id: 10
    });

    this.search.update();
    this.router.navigateByUrl('/browse');

  }

  logout(){

    this.auth.logOut();

  }

}
