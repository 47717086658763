<!-- Navigation -->
<div class="header">
  <header class="full-width">
    <div class="container large-container">
      <div class="row px-lg-5">
        <div class="col-12 mainNavCol">

          <!-- logo -->
          <div class="logo logo-container mainNavCol">
            <a routerLink="/"><img alt="Logo" class="img-fluid" src="assets/img/logo2.svg"></a>
          </div>
          <!-- logo -->
          <div class="main-search">
            <form class="main-search search-form">
                   <app-search-bar class="d-sm-block"></app-search-bar>
            </form>
          </div>
          <div class="right-side fw-700 login-link">
            <a *ngIf="authState !== 'loggedIn'" routerLink="/login">My Account
            </a>
            <a *ngIf="authState === 'loggedIn'" nz-dropdown [nzDropdownMenu]="menu">My Account
            </a>
            <a routerLink="/basket" class="nav-basket"><i class="fa fa-shopping-basket"></i><span *ngIf="cartService.cart">{{cartService.itemsInBasket()}}</span></a>

            <nz-dropdown-menu #menu="nzDropdownMenu">
              <ul nz-menu nzSelectable>
                <li nz-menu-item routerLink="/my-account">My Account</li>
                <li nz-menu-item routerLink="/previous-orders">My Orders</li>
                <li nz-menu-item nzDanger (click)="logout()">Logout</li>
              </ul>
            </nz-dropdown-menu>

          </div>
        </div>

      </div>
    </div>
  </header>
</div>
<div class="mobile-menu-button">
  <h4>Shop Categories</h4>
  <button class="menu" [class.opened]="isOpen" (click)="toggleIsOpen()" aria-label="Main Menu">
    <svg width="50" height="50" viewBox="0 0 100 100">
      <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
      <path class="line line2" d="M 20,50 H 80" />
      <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
    </svg>
  </button>
</div>
<div class="sub-header swing-in-top-fwd" [class.show-nav]="isOpen">
  <header class="full-width nav-header">
   <div class="container p-0 d-flex align-items-center align-content-center">

         <ul class="d-flex justify-content-center w-100">

           <li *ngFor="let item of menuSections" (click)="visitSection(item)"><a class="text-light-white fw-500">{{item.section_name | titlecase}}</a></li>
           <li><a class="text-light-white fw-500" routerLink="/browse/merchants">All Merchants</a></li>
           <!-- <li><a class="text-light-white fw-500" (click)="giftCards()">Vouchers</a></li> -->
         </ul>

   </div>
  </header>
</div>
