import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/Api.service';
import {CartService} from '../../services/Cart.service';
import {Router} from '@angular/router';
import {Vendor} from '../../models/Vendor';
import {Cart} from '../../models/Cart';
import {CustomerAddressUpdate} from '../../models/CustomerAddressUpdate';
import {PaymentMethod} from '../../models/PaymentMethod';
import {CheckoutService} from '../../services/Checkout.service';
import {VoucherCheckoutService} from '../../services/VoucherCheckout.service';
import {CartVoucherService} from '../../services/CartVoucher';

@Component({
  selector: 'app-checkout-voucher',
  templateUrl: './checkout-voucher.component.html',
  styleUrls: ['./checkout-voucher.component.scss']
})
export class CheckoutVoucherComponent implements OnInit {

  loaded = false;
  paymentProcessing = false;
  missingFields: string[] = [];
  orderError = false;

  constructor(public api: ApiService, public router: Router, public voucherCheckout: VoucherCheckoutService, public  cart: CartVoucherService) { }

  ngOnInit(): void {

    this.initialiseCheckout();

  }

  paymentError(error){

    console.log(error);
    this.paymentProcessing = false;

  }

  initialiseCheckout(){

    this.voucherCheckout.initializeCheckout();
    console.log(this.voucherCheckout.voucherRequest);
    this.loaded = true;

  }

  paymentInProgress(){

    this.paymentProcessing = true;

  }

  paymentSuccess(payment){

    this.voucherCheckout.processSuccessfulPayment(payment, this.cart.cart.unique_basket_id).then((res) => {

      sessionStorage.clear();

      this.router.navigateByUrl('/voucher-order-complete');

    }).catch((err) => {

      this.orderError = true;

    });

  }

  checkValid(){



  }

}
