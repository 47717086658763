import {Component, Input, OnInit} from '@angular/core';
import {SearchService} from '../../services/Search.service';
import {Category} from '../../models/Category';

@Component({
  selector: 'app-search-sidebar',
  templateUrl: './search-sidebar.component.html',
  styleUrls: ['./search-sidebar.component.scss']
})
export class SearchSidebarComponent implements OnInit {

  @Input() categories: Category[];

  constructor(public searchService: SearchService) { }

  ngOnInit(): void {
  }

  changeOrderType(value){

    if (value === 'Delivery' && (this.searchService.search.location === 'Everywhere' || this.searchService.search.location === null)){
        this.searchService.search.location = 'St Helier';
    }

    if (value === 'Everything'){
      this.searchService.search.location = null;
    }

    this.searchService.search.orderType = value;
    this.searchService.update();
  }

  addCategoryToFilter(category){

    this.searchService.search.categories.push(category);
    this.searchService.update();

  }

  addMustToFilter(must){

    this.searchService.search.mustBe.push(must);
    this.searchService.update();

  }

  removeCategoryFromFilter(category){

    const categoryIndex = this.searchService.search.categories.findIndex((cat) => cat.id === category.id);
    this.searchService.search.categories.splice(categoryIndex, 1);
    this.searchService.update();

  }

  removeMustFromFilter(must){

    const categoryIndex = this.searchService.search.mustBe.findIndex((mustBe) => mustBe === must);
    this.searchService.search.mustBe.splice(categoryIndex, 1);
    this.searchService.update();

  }

  updateSearchType(){



  }

  checkCategory(category, state){

    if (state){
      this.addCategoryToFilter(category);
    }else{
      this.removeCategoryFromFilter(category);
    }

  }

  checkMust(category, state){

    if (state){
      this.addMustToFilter(category);
    }else{
      this.removeMustFromFilter(category);
    }

  }

  isMust(item){

    return this.searchService.search.mustBe.findIndex((cat) => cat === item) > -1;

  }

  isChecked(category){

    return this.searchService.search.categories.findIndex((cat) => cat.id === category.id) > -1;

  }

}
