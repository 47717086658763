<section class="vc_section dark-overlay vc_custom_1684253832677 vc_section-has-fill">
        <div class="container">
            <div class="row">
                <div class="col-sm-12">
                    <div class="vc_column-inner">
                        <div class="wpb_wrapper">
                            <div class="wpb_text_column wpb_content_element primary-underline text-white hero-header-text">
                                <div class="wpb_wrapper">
                                    <p style="text-align: center;">Fetch.je is closing on the 18th October.</p>

                                </div>
                            </div>
                            <h5 style="color: #ffffff;text-align: center" class="vc_custom_heading vc_custom_1690530921711"><a href="/browse/merchants">Please continue to support and shop local, directly with retailers.</a></h5>
                            <div class="wpb_raw_code wpb_content_element wpb_raw_html">
                                <div class="wpb_wrapper">
                                    <style>
                                        .image-link-box .image-box {
                                            background-position: center;
                                        }
                                    </style>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="vc_row-full-width vc_clearfix"></div>
    </section>
    <div class="container large-container">
        <div class="vc_custom_1632362783509 row">
            <div class="col-6 col-sm-3">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper"><a href="https://fetch.je/curations/24" class="image-link-box">
                            <div style="background-image: url(https://fetch-cms-wp.azurewebsites.net/wp-content/uploads/2023/05/WORDPRESS-HOMEPAGE.png)" class="image-box"></div>
                            <div class="text-section">Click &amp; Collect</div>
                        </a></div>
                </div>
            </div>
            <div class="col-6 col-sm-3">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper"><a href="https://fetch.je/curations/22" class="image-link-box">
                            <div style="background-image: url(https://fetch-cms-wp.azurewebsites.net/wp-content/uploads/2023/04/WORDPRESS-HOMEPAGE-9.png)" class="image-box"></div>
                            <div class="text-section">Popular Gifts</div>
                        </a></div>
                </div>
            </div>
            <div class="col-6 col-sm-3">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper"><a href="https://fetch.je/curations/14" class="image-link-box">
                            <div style="background-image: url(https://fetch-cms-wp.azurewebsites.net/wp-content/uploads/2024/09/WORDPRESS-HOMEPAGE-7.png)" class="image-box"></div>
                            <div class="text-section">Back to school</div>
                        </a></div>
                </div>
            </div>
            <div class="col-6 col-sm-3">
                <div class="vc_column-inner">
                    <div class="wpb_wrapper"><a href="https://fetch.je/browse/products?sort=relevance&amp;page=1&amp;collection=1&amp;delivery=1&amp;genuine_jersey=0&amp;special_offer=0&amp;term=gift%20for%20him&amp;categories=" class="image-link-box">
                            <div style="background-image: url(https://fetch-cms-wp.azurewebsites.net/wp-content/uploads/2023/07/WORDPRESS-HOMEPAGE-10.png)" class="image-box"></div>
                            <div class="text-section">Gifts for him</div>
                        </a></div>
                </div>
            </div>
        </div>
    </div>
    <div class="vc_row-full-width vc_clearfix"></div>
    <div class="vc_row-full-width vc_clearfix"></div>
    <div class="vc_row-full-width vc_clearfix"></div>
    <div class="vc_row-full-width vc_clearfix"></div>
    <div class="vc_row-full-width vc_clearfix"></div>
    <div class="container">

    </div>
    <div class="vc_row-full-width vc_clearfix" style="padding-bottom: 20px;"></div>
<!-- <ngx-dynamic-hooks *ngIf="content" [options]="{sanitize: false}" [content]="content[0].special_content.rendered"></ngx-dynamic-hooks> -->