import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute, CanActivateChild} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './Auth.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})

export class AuthCheck implements CanActivateChild {
  constructor(
    private auth: AuthService,
    private router: Router,
    public route: ActivatedRoute) {}

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {

    return new Promise(((resolve, reject) => {

        console.log('AUTH CHECK READY');

        const promiseArray = [
           this.auth.getToken(),
           this.auth.getExpiry(),
           this.auth.getUser()
       ];

        Promise.all(promiseArray).then((res) => {
          console.log('AUTH CHECK DONE');

          if (this.checkExpiry(res[1])){
            this.auth.token.next(res[0]);
            this.auth.user.next(res[2]);

            resolve(true);
          }else{
            this.auth.token.next(false);
            this.auth.user.next(false);
            console.log('EXPIRED');
            resolve(true);
          }


       }).catch((err) => {
          this.auth.token.next(false);
          this.auth.user.next(false);
          console.log('NO TOKEN FOUND');
          resolve(true);
        });

    })); // END PROMISE

  }

  checkExpiry(date): boolean{

    const now = moment();
    const expires = moment(date);
    return moment(now).isBefore(expires);

  }

}
