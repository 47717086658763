<div class="basket-vendor">
  <div class="basket-vendor-header d-flex">
    <div class="basket-vendor-logo" style="background-image: url('{{basketVendor.vendor.vendor_logo}}');" (click)="goToVendor()"></div>
    <div class="basket-vendor-details align-self-center">
      <div class="basket-vendor-details-name" (click)="goToVendor()" >{{basketVendor.vendor.trading_name}} Jersey</div>
      <div class="basket-vendor-details-address">{{basketVendor.vendor.address_1}}, {{basketVendor.vendor.address_2}}, {{basketVendor.vendor.town_parish}}, {{basketVendor.vendor.post_code}}</div>
    </div>
    <div class="basket-vendor-info align-self-center text-primary" (click)="showModal = true" *ngIf="basketVendor.vendor.store_message">Delivery & Collection <i class="fa fa-info-circle"></i></div>
  </div>
  <div class="basket-vendor-order">
    <div class="basket-vendor-order-items">

      <div class="basket-vendor-item d-flex" *ngFor="let item of basketVendor.items">
        <div class="basket-vendor-item-image" style="background-image: url('{{productImage(item.product)}}')"></div>
        <div class="basket-vendor-item-details">
          <div class="basket-vendor-item-details-name">{{item.product.product_name}}</div>
          <div class="basket-vendor-item-details-option" *ngIf="item.options && item.options.length > 0">Options: {{productOptions(item.options)}}</div>
          <div class="basket-vendor-item-details-warning" *ngIf="item.stop_sale_expiry"><i class="fas fa-exclamation-triangle"></i> Unavailable after {{item.stop_sale_expiry | dateTimeFormatFilter:'HH:mm'}} for {{item.fulfillment_method}} on {{item.scheduled_date | date:'mediumDate':'en-GB'}}</div>
          <div class="basket-vendor-item-details-warning" *ngIf="minSpend(item) > 0"><i class="fas fa-exclamation-triangle"></i> You need to spend {{minSpend(item) | currency: 'GBP'}} more to qualify for delivery.</div>
          <div class="basket-vendor-item-details-mod d-flex">
            <nz-select [(ngModel)]="item.quantity" (ngModelChange)="itemChanged(item)">
              <nz-option *ngFor="let n of availableQuantity(item)" [nzValue]="n" [nzLabel]="n">
              </nz-option>
            </nz-select>
            <div class="basket-vendor-item-details-mod-vr d-none d-sm-block" ></div>
            <div class="basket-vendor-item-details-mod-remove" (click)="removeItem(item)">Remove</div>
            <label class="basket-vendor-item-details-mod-gift d-none d-sm-flex" nz-checkbox [(ngModel)]="!!item.gift" (ngModelChange)="itemChanged(item)" *ngIf="item.fulfillment_method == 'Delivery'">This item is a gift</label>
            <i class="fa fa-info-circle gift-info d-none d-sm-block" (click)="showGiftModal = true" *ngIf="item.fulfillment_method == 'Delivery'"></i>
          </div>
          <label class="basket-vendor-item-details-mod-gift d-sm-none" nz-checkbox [(ngModel)]="!!item.gift" (ngModelChange)="itemChanged(item)" *ngIf="item.fulfillment_method == 'Delivery'">This item is a gift</label>
          <i class="fa fa-info-circle gift-info d-sm-none" (click)="showGiftModal = true" *ngIf="item.fulfillment_method == 'Delivery'"></i>
          <div class="basket-vendor-item-details-fulfil">
            <div>You have selected <strong>{{item.fulfillment_method}}</strong>
              <div class="basket-vendor-item-details-fulfil-details" *ngIf="item.fulfillment_method == 'Collection'">On {{item.scheduled_date | date:'mediumDate':'en-GB'}}<span *ngIf="item.scheduled_time">, preferred time {{item.scheduled_time}}</span></div>
              <div class="basket-vendor-item-details-fulfil-details" *ngIf="item.fulfillment_method == 'Delivery' && !item.is_asap">To {{item.delivery_location}} on {{item.scheduled_date | date:'mediumDate':'en-GB'}}<span *ngIf="item.scheduled_time">, preferred time {{item.scheduled_time}}</span></div>
              <div class="basket-vendor-item-details-fulfil-details" *ngIf="item.fulfillment_method == 'Delivery' && item.is_asap">To {{item.delivery_location}} ASAP</div>
        
            </div>
          </div>
        </div>
        <div class="basket-vendor-item-cost">
          <div class="basket-vendor-item-cost-total">{{cartService.calculateItemCost(item) | currency: 'GBP'}}</div>
          <div class="basket-vendor-item-cost-delivery" *ngIf="item.fulfillment_method == 'Delivery'">Charge <strong>{{cartService.calculateItemDelivery(basketVendor, item) | currency: 'GBP'}}</strong></div>
        </div>
      </div>
    </div>
    <div class="basket-vendor-order-extra d-flex">
      <button class="btn btn-rounded btn-icon btn-add-note" (click)="showNoteInput = true" *ngIf="!showNoteInput && !basketVendor.note"><i class="fa fa-pen" aria-hidden="true"></i> Add Merchant Note</button>
      <div class="basket-vendor-order-extra-note" *ngIf="showNoteInput">
        <textarea class="form-control" placeholder="Add note for the merchant (optional)" [(ngModel)]="note"></textarea>
        <button class="btn btn-rounded btn-primary btn-sm" (click)="updateNote()" *ngIf="note != basketVendor.note">Save Note</button>
      </div>
      
      <button class="btn btn-rounded btn-icon btn-add-discount" (click)="showDiscountInput = true" *ngIf="!showDiscountInput && !basketVendor.discount_id"><i class="fa fa-plus-circle" aria-hidden="true"></i> Add Discount Code</button>
      <div class="basket-vendor-order-extra-discount" *ngIf="showDiscountInput">
        <input type="text" class="form-control" placeholder="Enter discount code" [(ngModel)]="discountCode">
        <button class="btn btn-rounded btn-primary btn-sm" (click)="addDiscount()">Submit</button>
        <span *ngIf="discountCodeError" class="basket-vendor-order-extra-discount-error">{{discountCodeError}}</span>
      </div>
      <div class="basket-vendor-order-extra-applied" *ngIf="basketVendor.discount_id" (click)="removeDiscount()">
        <i class="fa fa-minus-circle" aria-hidden="true"></i>
        <div class="basket-vendor-order-extra-applied-discount">
          Discount <span>{{basketVendor.discount.code}}</span> <strong>{{vendorDiscount()}}</strong>
        </div>
      </div>
    </div>
  </div>
</div>

<nz-modal (nzOnCancel)="showModal = false" (nzOnOk)="showModal = false" [nzVisible]="showModal" [nzFooter]="modalFooter">
  <h5 class="text-primary"><i class="fa fa-info-circle" style="margin-right:5px"></i>Delivery & Collection</h5>
  <p>{{basketVendor.vendor.store_message}}</p>
  <ng-template #modalFooter>
    <button nz-button nzType="primary" (click)="showModal = false">Close</button>
  </ng-template>
</nz-modal>

<nz-modal [nzCancelText]="null" (nzOnCancel)="showGiftModal = false" (nzOnOk)="showGiftModal = false" [nzVisible]="showGiftModal">
  <h5 class="text-primary"><i class="fa fa-info-circle" style="margin-right:5px"></i>Gift</h5>
  <p>You can send gifts to separate delivery addresses and add notes if required.</p>
</nz-modal>