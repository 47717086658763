<!-- Navigation -->
<div class="header home-header">
  <header class="full-width">
    <div class="container">
      <div class="row">
        <div class="col-12 mainNavCol">

          <div class="mobile-menu-button">
            <button class="menu" [class.opened]="isOpen" (click)="toggleIsOpen()" aria-label="Main Menu">
              <svg width="50" height="50" viewBox="0 0 100 100">
                <path class="line line1" d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058" />
                <path class="line line2" d="M 20,50 H 80" />
                <path class="line line3" d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942" />
              </svg>
            </button>
          </div>

          <!-- logo -->
          <div class="logo mainNavCol">
            <a routerLink="/"><img alt="Logo" class="img-fluid" src="assets/img/logo3.svg"></a>
          </div>
          <!-- logo -->
          <div class="main-search mainNavCol menu-nav">
            <ul [class.show-nav]="isOpen" class="swing-in-top-fwd">
              <li><a class="text-light-white fw-500" (click)="allTakeAways()">Takeaway</a></li>
              <li><a class="text-light-white fw-500" (click)="essentials()">Food & Drink</a></li>
              <li><a class="text-light-white fw-500" (click)="homeWares()">Home & Garden</a></li>
              <li><a class="text-light-white fw-500" (click)="pharmacy()">Health</a></li>
              <li><a class="text-light-white fw-500" (click)="alcohol()">Entertainment</a></li>
              <li><a class="text-light-white fw-500" (click)="genuineJersey()">Genuine Jersey</a></li>
              <!-- <li><a class="text-light-white fw-500" (click)="giftCards()">Vouchers</a></li> -->
              <li class="text-primary separator">|</li>
              <li class="text-primary">
                <button *ngIf="authState !== 'loggedIn'" class="nav-btn login-btn" routerLink="/login">Sign Up or Log In</button>
                <button *ngIf="authState === 'loggedIn'" class="nav-btn login-btn" nz-dropdown [nzDropdownMenu]="menu">My Account
              </button>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                  <ul nz-menu nzSelectable>
                    <li nz-menu-item routerLink="/my-account">My Account</li>
                    <li nz-menu-item routerLink="/previous-orders">My Orders</li>
                    <li nz-menu-item nzDanger (click)="logout()">Logout</li>
                  </ul>
                </nz-dropdown-menu>
              </li>
            </ul>
          </div>

        </div>

      </div>
    </div>
  </header>
</div>
