
                    <div class="advertisement-slider swiper-container h-auto mb-xl-20 mb-md-40">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <div class="testimonial-wrapper">
                                    <div class="testimonial-box">
                                        <div class="testimonial-img p-relative">
                                            <a routerLink="/restaurant">
                                                <img src="assets/img/blog/438x180/shop-1.jpg" class="img-fluid full-width" alt="testimonial-img">
                                            </a>
                                            <div class="overlay">
                                                <div class="brand-logo">
                                                    <img src="assets/img/user/user-3.png" class="img-fluid" alt="logo">
                                                </div>
                                                <div class="add-fav text-success">
                                                    <img src="assets/img/svg/013-heart-1.svg" alt="tag">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="testimonial-caption padding-15">
                                            <p class="text-light-white text-uppercase no-margin fs-12">Featured</p>
                                            <h5 class="fw-600"><a routerLink="/restaurant" class="text-light-black">GSA King Tomato Farm</a></h5>
                                            <div class="testimonial-user-box">
                                                <img src="assets/img/blog-details/40x40/user-1.png" class="rounded-circle" alt="#">
                                                <div class="testimonial-user-name">
                                                    <p class="text-light-black fw-600">Sarra</p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top Reviewer</span>
                                                </div>
                                            </div>
                                            <div class="ratings"> <span class="text-yellow fs-16">
                                                          <i class="fas fa-star"></i>
                                                        </span>
                                                                                <span class="text-yellow fs-16">
                                                          <i class="fas fa-star"></i>
                                                        </span>
                                                                                <span class="text-yellow fs-16">
                                                          <i class="fas fa-star"></i>
                                                        </span>
                                                                                <span class="text-yellow fs-16">
                                                          <i class="fas fa-star"></i>
                                                        </span>
                                                                                <span class="text-yellow fs-16">
                                                          <i class="fas fa-star"></i>
                                                        </span>
                                            </div>
                                            <p class="text-light-black">Delivery was fast and friendly...</p>
                                            <p class="text-light-white fw-100"><strong class="text-light-black fw-700">Local delivery: </strong> From $7.99 (4.0 mi)</p>
                                            <a routerLink="/checkout" class="btn-second btn-submit">Order Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="testimonial-wrapper">
                                    <div class="testimonial-box">
                                        <div class="testimonial-img p-relative">
                                            <a routerLink="/restaurant">
                                                <img src="assets/img/blog/438x180/shop-2.jpg" class="img-fluid full-width" alt="testimonial-img">
                                            </a>
                                            <div class="overlay">
                                                <div class="brand-logo">
                                                    <img src="assets/img/user/user-2.png" class="img-fluid" alt="logo">
                                                </div>
                                                <div class="add-fav text-success"><img src="assets/img/svg/013-heart-1.svg" alt="tag">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="testimonial-caption padding-15">
                                            <p class="text-light-white text-uppercase no-margin fs-12">Featured</p>
                                            <h5 class="fw-600"><a routerLink="/restaurant" class="text-light-black">GSA King Tomato Farm</a></h5>
                                            <div class="testimonial-user-box">
                                                <img src="assets/img/blog-details/40x40/user-2.png" class="rounded-circle" alt="#">
                                                <div class="testimonial-user-name">
                                                    <p class="text-light-black fw-600">Sarra</p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top Reviewer</span>
                                                </div>
                                            </div>
                                            <div class="ratings"> <span class="text-yellow fs-16">
                                                  <i class="fas fa-star"></i>
                                                </span>
                                                                        <span class="text-yellow fs-16">
                                                  <i class="fas fa-star"></i>
                                                </span>
                                                                        <span class="text-yellow fs-16">
                                                  <i class="fas fa-star"></i>
                                                </span>
                                                                        <span class="text-yellow fs-16">
                                                  <i class="fas fa-star"></i>
                                                </span>
                                                                        <span class="text-yellow fs-16">
                                                  <i class="fas fa-star"></i>
                                                </span>
                                            </div>
                                            <p class="text-light-black">Delivery was fast and friendly...</p>
                                            <p class="text-light-white fw-100"><strong class="text-light-black fw-700">Local delivery: </strong> From $7.99 (4.0 mi)</p>
                                            <a routerLink="/checkout" class="btn-second btn-submit">Order Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="swiper-slide">
                                <div class="testimonial-wrapper">
                                    <div class="testimonial-box">
                                        <div class="testimonial-img p-relative">
                                            <a routerLink="/restaurant">
                                                <img src="assets/img/blog/438x180/shop-2.jpg" class="img-fluid full-width" alt="testimonial-img">
                                            </a>
                                            <div class="overlay">
                                                <div class="brand-logo">
                                                    <img src="assets/img/user/user-1.png" class="img-fluid" alt="logo">
                                                </div>
                                                <div class="add-fav text-success"><img src="assets/img/svg/013-heart-1.svg" alt="tag">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="testimonial-caption padding-15">
                                            <p class="text-light-white text-uppercase no-margin fs-12">Featured</p>
                                            <h5 class="fw-600"><a routerLink="/restaurant" class="text-light-black">GSA King Tomato Farm</a></h5>
                                            <div class="testimonial-user-box">
                                                <img src="assets/img/blog-details/40x40/user-3.png" class="rounded-circle" alt="#">
                                                <div class="testimonial-user-name">
                                                    <p class="text-light-black fw-600">Sarra</p> <i class="fas fa-trophy text-black"></i><span class="text-light-black">Top Reviewer</span>
                                                </div>
                                            </div>
                                            <div class="ratings"> <span class="text-yellow fs-16">
                                              <i class="fas fa-star"></i>
                                            </span>
                                                                    <span class="text-yellow fs-16">
                                              <i class="fas fa-star"></i>
                                            </span>
                                                                    <span class="text-yellow fs-16">
                                              <i class="fas fa-star"></i>
                                            </span>
                                                                    <span class="text-yellow fs-16">
                                              <i class="fas fa-star"></i>
                                            </span>
                                                                    <span class="text-yellow fs-16">
                                              <i class="fas fa-star"></i>
                                            </span>
                                            </div>
                                            <p class="text-light-black">Delivery was fast and friendly...</p>
                                            <p class="text-light-white fw-100"><strong class="text-light-black fw-700">Local delivery: </strong> From $7.99 (4.0 mi)</p>
                                            <a routerLink="/checkout" class="btn-second btn-submit">Order Now</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Add Arrows -->
                        <div class="swiper-button-next"></div>
                        <div class="swiper-button-prev"></div>
                    </div>
                    <div class="card sidebar-card">
                        <div class="card-header no-padding">
                            <div class="offer-content">
                                <h2 class="text-custom-white fw-700">Get $12 off <small class=" fw-700">your order*</small></h2>
                                <p class="text-custom-white">As an added treat, enjoy <strong>free delivery</strong> from
                                    <br>select restaurants automatically applied at checkout</p>
                            </div>
                        </div>
                        <div class="card-body padding-15">
                            <form>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label class="text-light-white fs-14">Email</label>
                                            <input type="email" name="#" class="form-control form-control-submit" placeholder="Email I'd">
                                        </div>
                                        <div class="form-group">
                                            <label class="text-light-white fs-14">ZIP Code</label>
                                            <input type="number" name="#" class="form-control form-control-submit" placeholder="10421">
                                        </div>
                                        <div class="form-group">
                                            <button type="submit" class="btn-second btn-submit full-width">Save $12 on your first order</button>
                                        </div>

                                        <div class="text-center"> <span class="text-light-black fs-12">*Valid on first order only, for one-time use, subject to quickmunch’s verification. Additional terms may apply. By signing up, you agree to receive marketing and
                        promotional emails and communications form quickmunch</span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
               
