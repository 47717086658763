
<section class="section-padding contact-form">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="section-header-left title">
                    <h3 class="text-light-black header-title">Making Bulk Orders On Fetch</h3>
                    <p class="mt-3">
                      For large orders for your company employees, please contact us and a member of our Fetch Team will organise the order for you. Please specify the Fetch Merchant(s) and the product(s) you are interested by the quantity and the date you would like it for. We will get back to you as soon as possible.
                    </p>

                </div>
                <form (submit)="sendEmail(contactForm.value)" [formGroup]="contactForm">
                    <div class="row clearfix">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text" formControlName="name" class="form-control form-control-submit" placeholder="Name" required>
                            </div>
                            <div class="form-group">
                                <input type="email" formControlName="email" class="form-control form-control-submit" placeholder="Email" required>
                            </div>
                            <div class="form-group">
                                <input type="text" formControlName="phone" class="form-control form-control-submit" placeholder="Phone No." required>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <textarea formControlName="cntnt" class="form-control form-control-submit" placeholder="Tell us what you want to order." required></textarea>
                            </div>
                        </div>
                        <div class="col-12">
                            <button type="submit" class="btn-second btn-submit">Submit</button>
                        </div>
                      <div class="col-12">

                        <h4 *ngIf="message">{{message}}</h4>

                      </div>
                    </div>
                </form>
            </div>

        </div>
    </div>
</section>
<section class="section-padding contact-top bg-light-theme">
  <div class="container">
    <div class="row">
      <div class="col-lg-6 col-sm-6">
        <div class="contact-info-box mb-md-40">
          <i class="fas fa-map-marker-alt"></i>
          <h6 class="text-theme fw-600">Postal Headquarters, La Rue Grellier, La Rue Des Pres Trading Estate, St Saviour, Jersey JE2 7QS</h6>
        </div>
      </div>
      <div class="col-lg-6 col-sm-6">
        <div class="contact-info-box">
          <i class="fas fa-envelope"></i>
          <h6 class="text-theme fw-600"><a href="mailto:info@fetch.je" class="text-theme">info@fetch.je</a><br> online support</h6>
        </div>
      </div>
    </div>
  </div>
</section>
