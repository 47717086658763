<div class="advertisement-slider swiper-container p-relative h-auto mb-md-40">
  <div class="swiper-wrapper">
    <div class="swiper-slide">
      <div class="testimonial-wrapper">
        <div class="testimonial-box">
          <div class="testimonial-img p-relative">
            <a routerLink="/restaurant">
              <img alt="testimonial-img" class="img-fluid full-width" src="assets/img/blog/438x180/shop-1.jpg">
            </a>
            <div class="overlay">
              <div class="brand-logo">
                <img alt="logo" class="img-fluid" src="assets/img/logo-4.jpg">
              </div>
              <div class="add-fav text-success">
                <img alt="tag" src="assets/img/svg/013-heart-1.svg">
              </div>
            </div>
          </div>
          <div class="testimonial-caption padding-15">
            <p class="text-light-white text-uppercase no-margin fs-12">Featured</p>
            <h5 class="fw-600"><a class="text-light-black" routerLink="/restaurant">GSA King Tomato Farm</a>
            </h5>
            <div class="testimonial-user-box">
              <img alt="#" class="rounded-circle" src="assets/img/blog-details/40x40/user-1.png">
              <div class="testimonial-user-name">
                <p class="text-light-black fw-600">Sarra</p>
                <i class="fas fa-trophy text-black"></i><span
                class="text-light-black">Top Reviewer</span>
              </div>
            </div>
            <div class="ratings"> <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
              <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
              <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
              <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
              <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
            </div>
            <p class="text-light-black">Delivery was fast and friendly...</p>
            <p class="text-light-white fw-100"><strong class="text-light-black fw-700">Local delivery: </strong>
              From $7.99 (4.0 mi)
            </p>
            <a class="btn-second btn-submit" routerLink="/checkout">Order Now</a>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-slide">
      <div class="testimonial-wrapper">
        <div class="testimonial-box">
          <div class="testimonial-img p-relative">
            <a routerLink="/restaurant">
              <img alt="testimonial-img" class="img-fluid full-width" src="assets/img/blog/438x180/shop-2.jpg">
            </a>
            <div class="overlay">
              <div class="brand-logo">
                <img alt="logo" class="img-fluid" src="assets/img/user/user-2.png">
              </div>
              <div class="add-fav text-success"><img alt="tag" src="assets/img/svg/013-heart-1.svg">
              </div>
            </div>
          </div>
          <div class="testimonial-caption padding-15">
            <p class="text-light-white text-uppercase no-margin fs-12">Featured</p>
            <h5 class="fw-600"><a class="text-light-black" routerLink="/restaurant">GSA King Tomato Farm</a>
            </h5>
            <div class="testimonial-user-box">
              <img alt="#" class="rounded-circle" src="assets/img/blog-details/40x40/user-2.png">
              <div class="testimonial-user-name">
                <p class="text-light-black fw-600">Sarra</p>
                <i class="fas fa-trophy text-black"></i><span
                class="text-light-black">Top Reviewer</span>
              </div>
            </div>
            <div class="ratings"> <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
              <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
              <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
              <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
              <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
            </div>
            <p class="text-light-black">Delivery was fast and friendly...</p>
            <p class="text-light-white fw-100"><strong class="text-light-black fw-700">Local delivery: </strong>
              From $7.99 (4.0 mi)
            </p>
            <a class="btn-second btn-submit" routerLink="/checkout">Order Now</a>
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-slide">
      <div class="testimonial-wrapper">
        <div class="testimonial-box">
          <div class="testimonial-img p-relative">
            <a routerLink="/restaurant">
              <img alt="testimonial-img" class="img-fluid full-width" src="assets/img/blog/438x180/shop-2.jpg">
            </a>
            <div class="overlay">
              <div class="brand-logo">
                <img alt="logo" class="img-fluid" src="assets/img/user/user-1.png">
              </div>
              <div class="add-fav text-success"><img alt="tag" src="assets/img/svg/013-heart-1.svg">
              </div>
            </div>
          </div>
          <div class="testimonial-caption padding-15">
            <p class="text-light-white text-uppercase no-margin fs-12">Featured</p>
            <h5 class="fw-600"><a class="text-light-black" routerLink="/restaurant">GSA King Tomato Farm</a>
            </h5>
            <div class="testimonial-user-box">
              <img alt="#" class="rounded-circle" src="assets/img/blog-details/40x40/user-3.png">
              <div class="testimonial-user-name">
                <p class="text-light-black fw-600">Sarra</p>
                <i class="fas fa-trophy text-black"></i><span
                class="text-light-black">Top Reviewer</span>
              </div>
            </div>
            <div class="ratings"> <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
              <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
              <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
              <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
              <span class="text-yellow fs-16">
                                 <i class="fas fa-star"></i>
                                 </span>
            </div>
            <p class="text-light-black">Delivery was fast and friendly...</p>
            <p class="text-light-white fw-100"><strong class="text-light-black fw-700">Local delivery: </strong>
              From $7.99 (4.0 mi)
            </p>
            <a class="btn-second btn-submit" routerLink="/checkout">Order Now</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Add Arrows -->
  <div class="swiper-button-next"></div>
  <div class="swiper-button-prev"></div>
</div>
