import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {GiftRequest} from '../../../models/GiftRequest';
import {ApiService} from '../../../services/Api.service';
import {VoucherCheckoutService} from '../../../services/VoucherCheckout.service';

@Component({
  selector: 'app-voucher-delivery',
  templateUrl: './voucher-delivery.component.html',
  styleUrls: ['./voucher-delivery.component.scss']
})
export class VoucherDeliveryComponent implements OnInit {

  giftRequest: GiftRequest;

  loaded = true;

  constructor(public api: ApiService, public giftService: VoucherCheckoutService) { }

  ngOnInit(): void {

  }

}
