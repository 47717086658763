import { Component, OnInit } from '@angular/core';
import {ApiService} from '../../services/Api.service';
import {Router} from '@angular/router';
import {Order} from '../../models/Order';

@Component({
  selector: 'app-order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent implements OnInit {

  orderList: Order[];
  loaded = false;

  constructor(public api: ApiService, public router: Router) { }

  ngOnInit(): void {
    this.getOrderList();
  }

  getOrderList(){

    this.api.apiGet('core/customer/orders').then((res) => {
      this.orderList = res;
      this.loaded = true;
    }).catch((err) => {
      console.log(err);
    });

  }

  vendorTotal(vendor) {
    return Number(vendor.vendor_total) + Number(vendor.delivery_charge);
  }

}
