import {Component, ElementRef, OnInit, Renderer2, ViewChild} from '@angular/core';
import {Subject} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ApiService} from '../../services/Api.service';
import {Result} from '../../models/Result';
import {Router} from '@angular/router';
import {Product} from '../../models/Product';
import {Vendor} from '../../models/Vendor';
import {SearchService} from '../../services/Search.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit {

  subject: Subject<KeyboardEvent> = new Subject<KeyboardEvent>();
  searchTerm: string;
  @ViewChild('searchForm') searchForm: ElementRef;

  results: Result;
  showResults: boolean;

  constructor(public api: ApiService, public router: Router, private renderer: Renderer2, public search: SearchService) {


  }

  ngOnInit(): void {
  }

  typingSearch(eventData: KeyboardEvent){

    this.subject.next(eventData);

  }

  getBackgroundImageForVendor(vendor: Vendor){

    if (vendor.vendor_logo){
      return encodeURI(vendor.vendor_logo);
    }else{
      return 'assets/img/placeholder.png';
    }

  }

  openVendor(merchant: Vendor){
    this.showResults = false;
    this.results = null;
    this.searchTerm = null;
    this.router.navigate(['browse', merchant.id]);
  }

  openProduct(product: Product){
    this.showResults = false;
    this.results = null;
    this.searchTerm = null;
    this.router.navigate(['browse', product.vendor.id], {queryParams: {viewProduct: product.id}});

  }

  openProductResults(){
    this.showResults = false;
    this.results = null;
    this.router.navigate(['product-search'], {queryParams: {term: this.searchTerm}});

  }

}
