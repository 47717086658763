import { Component, OnInit } from '@angular/core';
import {OrderService} from '../../services/Order.service';
import {Order} from '../../models/Order';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-order-single',
  templateUrl: './single-order.component.html',
  styleUrls: ['./single-order.component.scss']
})
export class SingleOrderComponent implements OnInit {

  loaded = false;
  order: Order;

  constructor(public orderTracker: OrderService, public router: Router, public route: ActivatedRoute) { }

  ngOnInit(): void {

      this.orderTracker.getSingleOrder(this.route.snapshot.params.id).then((res) => {
        this.order = res;
        this.loaded = true;
      }).catch((err) => {

        this.router.navigateByUrl('/');

      });
  }

  vocabForDelivery(isCollection){

    if (isCollection){
      return 'collection';
    }else{
      return 'delivery';
    }

  }

  getOrderStep(){

    if (this.order.status === 'Processing'){
      return 1;
    }else{
      return 2;
    }

  }

  getIcon(){

    if (this.order.status === 'Processing'){
      return 'loading';
    }else{
      return '';
    }

  }

  getStatus(){

    if (this.order.status === 'Processing'){
      return 'wait';
    }else{
      return 'finish';
    }


  }

  vocabForDeliveryDone(isCollection){

    if (isCollection){
      return 'collected';
    }else{
      return 'delivered';
    }

  }

}
