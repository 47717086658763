import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {ApiService} from '../../services/Api.service';
import {CustomerAddress} from '../../models/CustomerAddress';
import {CustomerAddressUpdate} from '../../models/CustomerAddressUpdate';
import {Vendor} from '../../models/Vendor';
import {CheckoutService} from '../../services/Checkout.service';

@Component({
  selector: 'app-checkout-delivery',
  templateUrl: './checkout-delivery.component.html',
  styleUrls: ['./checkout-delivery.component.scss']
})
export class CheckoutDeliveryComponent implements OnInit {

  @Input() deliveryMethods: CustomerAddress[];
  @Input() missingFields: string[];
  @Input() vendor: Vendor;

  @Output() deliveryMethodUpdate = new EventEmitter<CustomerAddressUpdate>();

  loaded = false;
  selectedAddress: CustomerAddressUpdate;

  constructor(public api: ApiService, public checkout: CheckoutService) { }

  ngOnInit(): void {
    if (this.deliveryMethods.length){
      this.selectedAddress = new CustomerAddressUpdate();
      this.selectedAddress.address = this.deliveryMethods[0];
      this.selectedAddress.addressType = 'Saved';
      this.deliveryMethodUpdate.emit(this.selectedAddress);
      this.loaded = true;
    }else{
      this.selectedAddress = new CustomerAddressUpdate();
      this.selectedAddress.address = new CustomerAddress();
      this.selectedAddress.addressType = 'New';
      this.loaded = true;
    }
  }

  clickAdd(){

    this.selectedAddress.address = new CustomerAddress();
    this.selectedAddress.addressType = 'New';

  }

  willDeliver(area: string){

    if (!this.vendor.deliver_exclusions.length){
      return true;
    }else{
      const search = this.vendor.deliver_exclusions.findIndex((areaToCheck) => areaToCheck.area === area);
      return search < 0;
    }

  }

  outputNewDeliveryUpdate(){

    this.deliveryMethodUpdate.emit(this.selectedAddress);

  }

  chooseNewAddress(address){
    this.selectedAddress.address = address;
    this.outputNewDeliveryUpdate();
  }



}
