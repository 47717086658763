import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AuthService} from './Auth.service';
import {ApiService} from './Api.service';
import {Order} from '../models/Order';
import {GiftRequest} from '../models/GiftRequest';

// @ts-ignore
@Injectable({
  providedIn: 'root',
})
// @ts-ignore
export class VoucherCheckoutService {

  order: Order;
  voucherRequest: GiftRequest;

  constructor(
    public router: Router,
    public auth: AuthService,
    public api: ApiService,
    public http: HttpClient
  ) {

  }

  public initializeCheckout(): any{

    this.voucherRequest = new GiftRequest();

  }

  public processSuccessfulPayment(payment: any, cartID: string): Promise<any>{

    return new Promise((resolve, reject) => {
        this.api.apiPost('public/gift-vouchers/cart/' + cartID + '/checkout/process', payment).then((res) => {
          resolve(res);
        }).catch((err) => {
          console.log(err);
        });
    });

  }

  public paymentIntent(cartID: string): Promise<any>{

    return new Promise((resolve, reject) => {
        this.api.apiPost('public/gift-vouchers/cart/' + cartID + '/checkout/pre-payment', this.voucherRequest).then((res) => {
          resolve(res);
        }).catch((err) => {
          console.log(err);
        });
    });

  }


}
