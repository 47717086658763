import { Injectable } from '@angular/core';
import {Search} from '../models/Search';
import {BehaviorSubject, Observable} from 'rxjs';
import algoliasearch from 'algoliasearch';
import * as ElasticAppSearch from '@elastic/app-search-javascript';
import {environment} from '../../environments/environment';
import {SearchResponse} from 'elasticsearch';
import {HttpClient} from '@angular/common/http';
import {AutoCompleteSingleResult} from '../models/Search/AutoCompleteSingleResult';
import {NewSearchCriteria} from '../models/NewSearchCriteria';
import {ApiService} from './Api.service';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root',
})

export class ScrollService {

  scrollToTop = true;

  constructor(public router: Router) {

  }

}
