import { Injectable } from '@angular/core';
import {Search} from '../models/Search';
import {BehaviorSubject, Observable} from 'rxjs';
import algoliasearch from 'algoliasearch';
import * as ElasticAppSearch from '@elastic/app-search-javascript';
import {environment} from '../../environments/environment';
import {SearchResponse} from 'elasticsearch';
import {HttpClient} from '@angular/common/http';
import {AutoCompleteSingleResult} from '../models/Search/AutoCompleteSingleResult';
import {NewSearchCriteria} from '../models/NewSearchCriteria';
import {ApiService} from './Api.service';
import {Router} from '@angular/router';
import {ScrollService} from './ScrollService';

const searchClient = algoliasearch(
  '2V0CSY6NNV',
  '63b1c828601ad6724616e02c44b545cb'
);


const autoCompleteElastic = ElasticAppSearch.createClient({
  endpointBase: 'https://fetch-uat.ent.uksouth.azure.elastic-cloud.com',
  searchKey: environment.elasticKey,
  engineName: environment.elasticAutocompleteEngine
});

const productElastic = ElasticAppSearch.createClient({
  endpointBase: 'https://fetch-uat.ent.uksouth.azure.elastic-cloud.com',
  searchKey: environment.elasticKey,
  engineName: environment.elasticProductEngine
});

const merchantElastic = ElasticAppSearch.createClient({
  endpointBase: 'https://fetch-uat.ent.uksouth.azure.elastic-cloud.com',
  searchKey: environment.elasticKey,
  engineName: environment.elasticMerchantEngine
});

@Injectable({
  providedIn: 'root',
})

export class NewSearchService {

  public showAutocompleteResults: boolean;
  public searchTerm = new BehaviorSubject<string>(null);
  public autocompleteSearchTerm = new BehaviorSubject<string>(null);
  public searchCriteria: NewSearchCriteria = new NewSearchCriteria();
  public endPointBase = 'https://fetch-uat.ent.uksouth.azure.elastic-cloud.com';

  public merchantElastic = merchantElastic;
  public productElastic = productElastic;
  public facetsLoaded = false;
  public facetsLoading = false;
  public categorySections: any;

  public autoCompleteResults: any[];
  public autoCompleteOptions = {
    search_fields: {
      name: {}
    },
    result_fields: {
      id: {
        raw: {}
      },
      name: {
        raw: {}
      },
    }
  };

  public facetOptions = {
    search_fields: {
      name: {}
    }
  };

  constructor(public http: HttpClient, public api: ApiService, public router: Router, public scroll: ScrollService) {
    this.autocompleteSearchTerm.subscribe((term) => {
      if (term){
        this.searchCriteria.term = term;
        autoCompleteElastic.search(term, this.autoCompleteOptions).then((res) => {
          this.autoCompleteResults = res.results;
          this.showAutocompleteResults = true;
        });
      }

    });
  }

  public loadFacets(){
      return this.api.apiGet('public/filters/shopping');
  }

  public clearFilters(type){

    this.searchCriteria.categories = [];
    this.searchCriteria.delivery = 1;
    this.searchCriteria.collection = 1;
    this.searchCriteria.genuine_jersey = 0;
    this.updateSearch(false, type);

  }

  public updateSearch(paging: boolean, page = 'products', scrolling = false){

    if(scrolling){
      this.scroll.scrollToTop = scrolling;
    }else{
      this.scroll.scrollToTop = paging;
    }

    const criteria = JSON.parse(JSON.stringify(this.searchCriteria));

    if (criteria.categories){
      criteria.categories = criteria.categories.join(',');
    }

    if (!paging){
      criteria.page = 1;
    }

    this.router.navigate(['/browse', page], {
      queryParams: criteria,
    }).then((res) => {
      this.scroll.scrollToTop = true;
      console.log(res);
    }).catch((err) => {
      console.log(err);
    });

  }

  public convertToSlug(Text) {
    return Text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }

}
