<div class="cart-header" [ngClass]="{'cart-header-basket' : basketPage || checkoutPage}" *ngIf="cartService.loaded">
  <h4 *ngIf="!basketPage && !checkoutPage">Your Basket</h4>
  <h4 *ngIf="basketPage || checkoutPage">Order Summary</h4>
</div>

<div class="cart-container" *ngIf="cartService.loaded">

  <div *ngIf="!cartService.cart || cartService.cart.vendors && cartService.cart.vendors.length == 0" class="empty-cart">
    <h4 class="empty-cart-message">Add your first item</h4>
  </div>

  <div *ngIf="cartService.cart && cartService.cart.vendors && cartService.cart.vendors.length > 0" class="has-cart">
    <div *ngFor="let basketVendor of cartService.cart.vendors">
      <div class="cart-item-details">
        <div class="row">
          <div class="col-8">
            <h6 class="cart-line" [line-truncation]="1">{{basketVendor.vendor.trading_name}}</h6>
          </div>
          <div class="col-4 text-right">
            <div class="cart-cost font-weight-bold">{{cartService.calculateVendorTotal(basketVendor) | currency : 'GBP'}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="mb-2 mt-2 top-separator" *ngIf="cartService.calculateBasketDiscounts() > 0">
      <div class="row">
        <div class="col-8">
          <div class="cart-line">Discounts</div>
        </div>
        <div class="col-4 text-right">
          <div class="cart-cost font-weight-bold">-{{cartService.calculateBasketDiscounts() | currency: 'GBP'}}</div>
        </div>
      </div>
    </div>

    <div class="mb-2 bottom-separator top-separator" style="margin-top:2px;">
      <div class="row">
        <div class="col-8">
          <div class="cart-line">Delivery</div>
        </div>
        <div class="col-4 text-right">
          <div class="cart-cost font-weight-bold">{{cartService.calculateBasketDelivery() | currency: 'GBP'}}</div>
        </div>
      </div>
    </div>

    <div class="row mt-2 cart-total" [ngClass]="{'cart-total-has-vouchers' : cartService.cart.vouchers.length > 0}">
      <div class="col-8">
        <div >Total</div>
      </div>
      <div class="col-4 text-right">
        <div class="cart-total-cost">{{cartService.calculateBasketTotal() | currency: 'GBP'}}</div>
      </div>
    </div>

    <div class="mt-2 bottom-separator top-separator" *ngIf="cartService.cart.vouchers.length > 0">
      <div class="row" *ngFor="let voucher of cartService.cart.vouchers">
        <div class="col-8">
          <div class="cart-line">Voucher - <em>{{voucher.voucher.code}}</em></div>
        </div>
        <div class="col-4 text-right">
          <div class="cart-cost font-weight-bold">-{{cartService.calculateVoucherDeduction(voucher) | currency: 'GBP'}}</div>
        </div>
      </div>
    </div>

    <div class="row mb-2 mt-2" *ngIf="cartService.cart.vouchers.length > 0">
      <div class="col-8">
        <div class="cart-total">Amount Due</div>
      </div>
      <div class="col-4 text-right">
        <div class="cart-total cart-total-cost">{{cartService.calculateBasketTotalAfterVouchers() | currency: 'GBP'}}</div>
      </div>
    </div>

    <div class="cart-checkout" *ngIf="!basketPage && !checkoutPage">
      <button class="btn btn-block btn-primary" routerLink="/basket">Go to Basket</button>
    </div>

    <div class="cart-checkout" *ngIf="basketPage && !checkoutPage">
      <button class="btn btn-block btn-primary" routerLink="/checkout">Checkout</button>
    </div>

    <div class="cart-voucher" *ngIf="basketPage || checkoutPage">
      <div class="cart-voucher-add" *ngIf="!showVoucherInput" (click)="showVoucherInput = true">
        <div class="cart-voucher-add-icon">
          <i class="fa fa-plus-circle" aria-hidden="true"></i>
        </div>
        <div class="cart-voucher-add-copy">Add a Voucher</div>
      </div>
      <div class="cart-voucher-input" *ngIf="showVoucherInput">
        <input type="text" class="form-control" placeholder="Enter voucher code" [(ngModel)]="voucherCode">
        <button class="btn btn-rounded btn-primary btn-sm" (click)="addVoucher()">Submit</button>
        <span *ngIf="voucherCodeError" class="cart-voucher-input-error">{{voucherCodeError}}</span>
      </div>
    </div>

  </div>

</div>

<div class="cart-container container-danger stop-sale-warning" *ngIf="checkForExpired()">
  <div class="d-flex">
    <i class="fas fa-exclamation-triangle fa-3x text-primary mr-3"></i>
    <div class="text">
      <p class="fw-700 m-0">Some of your items will expire if you don't checkout soon.</p>
      <button class="btn btn-primary mt-3" (click)="showExpireModal = true">What does this mean?</button>
    </div>
  </div>

</div>

<nz-modal (nzOnCancel)="showExpireModal = false" (nzOnOk)="showExpireModal = false" [nzVisible]="showExpireModal" [nzFooter]="modalFooter">

  <h5 class="text-primary">Basket Items that will expire soon?</h5>

  <p>If you are reading this, it means one or more of the items in your basket are due to expire. You can see the time of expiry next to the item in the basket.</p>

  <h5 class="text-primary mt-4">Why are these items expiring?</h5>
  <p *ngIf="cartService.cart">One of the vendors in your basket only allows certain products to be ordered before a specified time for the same day or next day. This is usually because of the time required in order to prepare the product. As a result, when you add a product to the basket, we will alert you of how long you have to complete your order before the item is no longer available.</p>

  <h5 class="text-primary mt-4">What happens after expiry?</h5>
  <p>If you attempt to checkout the order after the expiry, we will remove the unavailable items from your order or prevent you from checking out the order if all items are unavailable.</p>

  <ng-template #modalFooter>
    <button nz-button nzType="primary" (click)="showExpireModal = false">Close</button>
  </ng-template>

</nz-modal>